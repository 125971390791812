
import { Invoice, PrestonUser, UserMetadataAction } from "@prestonly/preston-common";
import InvoiceForm from "../v2/subscription/InvoiceForm.vue";
import Header from "@/components/v2/base/Header.vue";
import { validator, VueWithValidator } from "@/utils/validators";
import Btn from "../v2/base/Btn.vue";
import _ from "lodash";
import { DialogCloseType } from "@/types/dialog";
import { SnackbarType } from "@/types/snackbar";

const emptyInvoice = (): Omit<Invoice, "country"> => ({
  companyName: "",
  nip: "",
  firstname: "",
  lastname: "",
  street: "",
  zip: "",
  city: "",
});

export default VueWithValidator.extend({
  components: { InvoiceForm, Header, Btn },
  name: "UserProfileInvoiceForm",
  data() {
    return {
      valid: false,
      invoice: { ...emptyInvoice(), country: this.$t("countries.poland") as string } as Invoice,
    };
  },
  mixins: [validator],

  created() {
    this.invoice = { ...this.invoice, ...(this.user.userMetadata?.invoiceData || {}) };
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    saveEnabled(): boolean {
      return !_.isEqual(this.invoice, this.user.userMetadata.invoiceData);
    },
  },

  methods: {
    async saveInvoice() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      await this.patchInvoice();
    },
    async clearInvoice() {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "UserProfileInvoiceDialog",
        config: {
          title: this.$t("userProfile.payment.clearInvoiceDialog.header"),
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      this.invoice = { ...this.invoice, ...emptyInvoice() };
      this.resetValidation();
      await this.patchInvoice();
    },
    async patchInvoice() {
      const result = await this.$store.dispatch("user/patchUser", {
        action: UserMetadataAction.INVOICE_DATA,
        payload: {
          invoiceData: this.invoice,
        },
      });
      if (result) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: "Dane do faktury zostały poprawnie zapisane.",
            },
          },
          { root: true }
        );
      }
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { DEFAULT_DIALOG_CONFIG, Dialog, DialogConfig, DialogOutput } from "@/types/dialog";

interface DialogsState {
  dialogs: Record<string, Dialog>;
}

const state = (): DialogsState => ({
  dialogs: {},
});

const mutations = {
  open(state: DialogsState, dialog: Dialog): void {
    state.dialogs[dialog.id] = dialog;
    state.dialogs = { ...state.dialogs };
  },
  close(state: DialogsState, dialog: Dialog): void {
    delete state.dialogs[dialog.id];
    state.dialogs = { ...state.dialogs };
  },
};

const actions = {
  open(
    { commit }: ActionContext<DialogsState, RootState>,
    { componentName, config }: { componentName: string; config: DialogConfig }
  ): Promise<DialogOutput> {
    if (!componentName) {
      throw new Error("Component name has to be defined.");
    }
    const dialog = new Dialog(
      componentName,
      {
        ...DEFAULT_DIALOG_CONFIG,
        ...(config || {}),
      },
      commit
    );
    return dialog.promisify();
  },
};

const getters = {
  getList: (state: DialogsState): Dialog[] => {
    return Object.values(state.dialogs);
  },
};

export const dialogsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

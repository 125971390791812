
import Vue, { PropType } from "vue";
import {
  AttemptResult,
  Exercise,
  LearningExerciseType,
  Lesson,
  PrestonUser,
  UserPreferences,
} from "@prestonly/preston-common";
import AudioPlayer from "@/components/base/AudioPlayer.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import TrainerNavigationButtons from "@/components/trainer/TrainerNavigationButtons.vue";
import TrainerExampleBlocksDisplayer from "@/components/trainer/TrainerExampleBlocksDisplayer.vue";
import TrainerExampleFillTheGapsDisplayer from "@/components/trainer/TrainerExampleFillTheGapsDisplayer.vue";
import TrainerExampleResult from "@/components/trainer/TrainerExampleResult.vue";
import TrainerKeyboard from "@/components/trainer/TrainerKeyboard.vue";
import { AttemptEventPayload } from "@/types/progress";
import { MINIMUM_TRIES_REQUIRED_TO_SKIP } from "@/constants/trainer";
import TileWrapper from "@/components/base/TileWrapper.vue";

export default Vue.extend({
  name: "TrainerListening",
  components: {
    TileWrapper,
    TextareaField,
    TrainerExampleResult,
    TrainerNavigationButtons,
    TrainerExampleBlocksDisplayer,
    TrainerExampleFillTheGapsDisplayer,
    TrainerKeyboard,
    AudioPlayer,
  },

  props: {
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.LISTENING_FULL_SENTENCE,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    lessonId: {
      type: String,
      required: true,
    },
    result: {
      type: Object as PropType<AttemptResult>,
      required: false,
    },
    proceedPossible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    showKeyboard(): boolean {
      if (!this.currentItem.answerLang) {
        return false;
      }
      return [LearningExerciseType.LISTENING_FULL_SENTENCE, LearningExerciseType.LISTENING_FILL_THE_GAP].includes(
        this.exerciseType
      );
    },
    navigationButtonProps(): { attempts: any; result: any; moreMenuVisible: boolean } {
      return {
        result: this.result,
        attempts: this.attempts,
        moreMenuVisible: this.moreMenuVisible,
      };
    },
    navigationBtnListeners(): Record<string, any> {
      return {
        forgetTemporarily: () => this.$emit("forgetTemporarily"),
        forget: () => this.$emit("forget"),
        playRecording: this.playRecording,
        proceed: this.proceed,
        skip: this.skip,
      };
    },
    userPreferences(): UserPreferences {
      return this.$store.getters["user/preferences"] as UserPreferences;
    },
    autoplayRecording(): boolean {
      return this.userPreferences.autoplayAnswer;
    },
  },

  watch: {
    async currentItem() {
      this.attempts = 0;
      this.answer = "";
    },
    async result(val) {
      this.answer = "";
      if (val !== null && this.autoplayRecording) {
        setTimeout(() => {
          ((this.$refs.result as any).$refs.answerAudioButton as any).playRecording();
        }, 300);
      }
    },
  },

  data() {
    return {
      LearningExerciseType,
      answer: "",
      attempts: 0,
    };
  },

  methods: {
    focusAfterGettingBackFromResult() {
      // hack to cleanup textarea after pressing enter on incorrect result
      if (this.answer === "") {
        setTimeout(() => {
          this.answer = "";
        }, 25);
      }
    },
    answerChanged({ answer }: { answer: string }) {
      this.answer = answer;
    },
    onKeyPress(key: string) {
      const input = document.querySelector("#textarea-field textarea");
      const position = (input as HTMLTextAreaElement)?.selectionStart || this.answer.length;
      switch (this.exerciseType) {
        case LearningExerciseType.LISTENING_FULL_SENTENCE:
          this.answer = this.answer.slice(0, position) + key + this.answer.slice(position);
          break;
        case LearningExerciseType.LISTENING_FILL_THE_GAP:
          (this.$refs.exampleFillTheGapsDisplayer as InstanceType<typeof TrainerExampleFillTheGapsDisplayer>).pushKey(
            key
          );
          break;
        default:
          break;
      }
    },
    playRecording(playbackRate: number) {
      if (!this.result) {
        (this.$refs.exampleAudioButton as any).playRecording(playbackRate);
        return;
      }
      ((this.$refs.result as any).$refs.answerAudioButton as any).playRecording(playbackRate);
    },
    tryAgain() {
      this.$emit("tryAgain");
    },
    checkAnswer() {
      this.attempts++;
      this.$emit("checkAttempt", {
        check: true,
        userInput: this.answer,
        preferredAnswer: this.currentItem.answer,
        alternativeAnswers: this.currentItem.alternativeAnswers ?? [],
        lang: this.currentItem.answerLang,
      } as AttemptEventPayload);
    },
    skip() {
      if (this.attempts < MINIMUM_TRIES_REQUIRED_TO_SKIP || (this.result && this.result.isSuccess)) {
        return;
      }
      const payload: Record<string, boolean> = { forceSuccess: true };
      if (!this.userPreferences.randomizeRepetitionOrder) {
        payload.skipped = true;
        payload.forceSuccess = false;
      }
      this.$emit("proceed", payload);
    },
    proceed() {
      if (!this.result) {
        this.checkAnswer();
      }
      if (this.result && this.result.isSuccess) {
        this.$emit("proceed");
        return;
      }
      this.tryAgain();
    },
  },
});

import { v4 as uuidv4 } from "uuid";

import { Commit } from "vuex";

export interface DialogConfig {
  maxWidth: string;
  minContentHeight: string;
  persistent: boolean;
  scrollable: boolean;
  closeBtnText: string;
  submitBtnText: string;
  title: string;
  payload?: Record<string, any>;
}

export enum DialogCloseType {
  SUBMITTED = "SUBMITTED",
  CLOSED = "CLOSED",
}

export declare interface DialogOutput {
  type: DialogCloseType;
  payload?: any;
}

export declare interface DialogI {
  id: string;
  component: string;
  config: DialogConfig;
  commit: Commit;

  promisify(): Promise<any>;

  close(output: DialogOutput): void;
}

export const DEFAULT_DIALOG_CONFIG: DialogConfig = {
  maxWidth: "600px",
  minContentHeight: "",
  persistent: false,
  scrollable: true,
  closeBtnText: "Zamknij",
  submitBtnText: "Potwierdź",
  title: "Tytuł modala",
};

export class Dialog implements DialogI {
  id: string;
  component: string;
  config: DialogConfig;
  commit: Commit;

  protected promise: Promise<any>;
  protected resolve!: (dialogOutput: DialogOutput) => void;
  protected reject!: (dialogOutput: DialogOutput) => void;

  constructor(component: string, config: DialogConfig, commit: Commit) {
    this.id = uuidv4();
    this.component = component;
    this.config = config;
    this.commit = commit;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    commit("open", this);
  }

  public promisify(): Promise<DialogOutput> {
    return this.promise;
  }

  public close(output: DialogOutput): void {
    this.commit("close", this);
    this.resolve(output);
  }
}

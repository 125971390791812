
import Vue from "vue";

import { Popup } from "@prestonly/preston-common";

import BasePopupForm from "@/components/form/BasePopupForm.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminEditPopup",

  components: {
    BasePopupForm,
  },

  computed: {
    popupId(): string {
      return this.$route.params.popupId;
    },
    popup(): Popup {
      return this.$store.getters["popup/getPopupById"](this.popupId);
    },
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminOffersPopups" });
    },
    async saveChanges() {
      const form = this.$refs.popupForm as InstanceType<typeof BasePopupForm>;
      await form.saveChanges();
    },
    async getPopup() {
      await this.$store.dispatch("popup/getSingle", { id: this.popupId, force: true });
    },
  },
  async created() {
    await this.getPopup();
    if (!this.popup) {
      await this.$store.dispatch("snackbar/open", {
        config: {
          type: SnackbarType.ERROR,
          message: "Popup nie istnieje.",
        },
      });
      await this.$router.push({ name: "adminPopups" });
    }
  },
});


import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TagsField",

  data() {
    return {
      freeTextSearch: "",
      loading: false,
    };
  },
  props: {
    existingTags: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
  },
  computed: {
    // selected(): string[] {
    //   return this.existingTags;
    // },
    tags(): string[] {
      return this.$store.getters["tag/getList"];
    },
  },
  watch: {
    freeTextSearch(val) {
      val && this.getTags();
    },
  },
  methods: {
    async getTags() {
      this.loading = true;
      let filters = "";
      if (this.freeTextSearch) {
        filters = `tags:${this.freeTextSearch}`;
      }
      await this.$store.dispatch("tag/getList", {
        filters,
      });
      this.loading = false;
    },
    tagsChanged(input: string[]) {
      this.freeTextSearch = "";
      this.$emit("tagsChanged", input);
    },
  },
});

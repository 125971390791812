import { ActionContext } from "vuex";
import { RootState } from "../index";
import { BaseListFilters, BaseGiftCard, GiftCard, ListMetadata } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { SnackbarType } from "@/types/snackbar";

interface GiftCardsState {
  giftCardListMetadata: ListMetadata;
  giftCardsMap: Record<string, BaseGiftCard>;
  searchResults: Record<string, BaseGiftCard>;
  latestQueryParams: BaseListFilters;
}

const state = (): GiftCardsState => ({
  giftCardListMetadata: { total: 0 },
  giftCardsMap: {},
  searchResults: {},
  latestQueryParams: {},
});

const mutations = {
  setLatestQueryParams(state: GiftCardsState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: GiftCardsState, metadata: ListMetadata): void {
    state.giftCardListMetadata = metadata;
  },
  setList(state: GiftCardsState, giftCards: GiftCard[]): void {
    for (const giftCard of giftCards) {
      const id = giftCard._id as unknown as string;
      state.giftCardsMap[id] = giftCard;
    }
    state.giftCardsMap = { ...state.giftCardsMap };
  },
  searchResults(state: GiftCardsState, giftCards: GiftCard[]): void {
    state.searchResults = {};
    for (const giftCard of giftCards) {
      const id = giftCard._id as unknown as string;
      state.searchResults[id] = giftCard;
    }
    state.searchResults = { ...state.searchResults };
  },
};

const actions = {
  async create(
    { dispatch }: ActionContext<GiftCardsState, RootState>,
    { giftCard }: { giftCard: Partial<BaseGiftCard> }
  ): Promise<void> {
    try {
      await httpClient.api.post("/coupon/gift-cards", giftCard);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Karta podarunkowa została utworzona.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },
  async getList({ commit }: ActionContext<GiftCardsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/coupon/gift-cards", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  getSearchResults: (state: GiftCardsState): BaseGiftCard[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: GiftCardsState): BaseGiftCard[] => {
    return Object.values(state.giftCardsMap);
  },
  getListMetadata: (state: GiftCardsState): ListMetadata => {
    return state.giftCardListMetadata;
  },
  getLatestQueryParams: (state: GiftCardsState): BaseListFilters => {
    return state.latestQueryParams;
  },
};

export const giftCardsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

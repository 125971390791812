
import Vue from "vue";
import { getLangKeys, KeysConfig, langHasKeys } from "@/utils/languageKeyboardBuilder";
export default Vue.extend({
  name: "TrainerKeyboard",
  props: {
    lang: { type: String, required: true },
  },
  data() {
    return {
      showUpper: false,
    };
  },
  computed: {
    hasKeys(): boolean {
      return langHasKeys(this.lang);
    },
    keys(): KeysConfig {
      return getLangKeys(this.lang);
    },
    visibleKeys(): readonly string[] {
      return this.keys[this.showUpper ? "upper" : "lower"];
    },
  },
  methods: {
    onKeyPress(key: string): void {
      this.$emit("onKeyPress", key);
      this.showUpper = false;
    },
    switchUpper(): void {
      this.showUpper = !this.showUpper;
    },
  },
});

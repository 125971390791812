
import Vue from "vue";

export default Vue.extend({
  name: "TextButton",
  props: {
    underlined: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
});

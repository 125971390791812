
import Vue from "vue";
import { isEmpty } from "lodash";
import { TranslateResult } from "vue-i18n";

import {
  CEFRLevel,
  Course,
  LanguageMetadata,
  LevelingTestStatus,
  PrestonUser,
  UserMetadataAction,
} from "@prestonly/preston-common";
import Loader from "@/components/base/Loader.vue";
import LevelingTestQuestion from "@/components/levelingTest/LevelingTestQuestion.vue";
import LevelingTestFeedback from "@/components/levelingTest/LevelingTestFeedback.vue";
import LevelingTestSummary from "@/components/levelingTest/LevelingTestSummary.vue";
import CourseTile from "@/components/base/CourseTile.vue";
import EndListText from "@/components/base/EndListText.vue";
import Header from "@/components/v2/base/Header.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import moment from "moment";

export default Vue.extend({
  name: "LevelingTestQuiz",
  components: {
    Header,
    LevelingTestQuestion,
    LevelingTestFeedback,
    LevelingTestSummary,
    Loader,
    CourseTile,
    TileWrapper,
    EndListText,
  },

  data() {
    return {
      previousLevel: null,
      currentQuestion: 0,
      currentLevel: 0,
      isQuiz: true,
      isFeedback: false,
      hasEntered: true,
      questions: {},
      levels: [] as string[],
      score: 0,
      levelScore: 0,
      progress: 0,
      progressFPS: 100, // frames per seconds
      progressIntervalID: 0,
      questionsCount: 1,
      questionDuration: 30, // in seconds
      timeTotal: 0,
      levelTimeStart: 0,
      loadingDuration: 500, // in miliseconds
    };
  },

  computed: {
    language(): string {
      return (this.$route.params.lang || "").toUpperCase();
    },
    loaderBottomText(): TranslateResult {
      if (!this.isQuiz) {
        return this.$t("levelingTest.calculating");
      }
      return this.$t("levelingTest.generating");
    },
    isLoading(): boolean {
      return !this.questions && !this.isFeedback;
    },

    isQuestion(): boolean {
      return !!this.questions && !this.isFeedback;
    },

    isLastLevel(): boolean {
      return this.currentLevel === this.levels.length - 1;
    },

    isLastQuestionForLevel(): boolean {
      return this.currentQuestion === this.questionsForCurrentLevel.length - 1;
    },

    isLevelCompleted(): boolean {
      return this.levelScore === 7;
    },

    question(): any {
      return this.questionsForCurrentLevel[this.currentQuestion];
    },

    questionsForCurrentLevel(): any {
      if (Object.keys(this.questions).length === 0) {
        return [];
      }
      return this.questions[`${this.levels[this.currentLevel]}`].questions;
    },

    userLevel(): string {
      if (this.score === 0) {
        return "0";
      }

      return this.levels[this.currentLevel];
    },

    userLevelDescription(): TranslateResult {
      if (this.score === 0) {
        return this.$t("levelingTest.summary.noCorrectAnswers");
      }
      if (this.previousLevel) {
        return this.$t(`levelingTest.summary.previousLevelHigher`, {
          previousLevel: this.previousLevel,
          currentLevel: this.userLevel,
        });
      }
      return this.$t(`levelingTest.summary.levelsDescription.${this.userLevel}`);
    },

    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },

    recommendedCourses(): Course[] {
      return this.$store.getters["course/getRecommended"];
    },
  },

  async created() {
    const questions = await this.$store.dispatch("levelingTest/getQuestions", { lang: this.language });
    setTimeout(() => {
      this.questions = questions;
      this.levels = Object.keys(questions).sort();
      this.levelTimeStart = Date.now();
      this.setTimer();
    }, this.loadingDuration);
  },

  methods: {
    answered(isCorrect) {
      clearTimeout(this.progressIntervalID);
      this.hasEntered = false;
      this.progress = 0;

      if (isCorrect) {
        this.score += 1;
        this.levelScore += 1;

        if (this.isLevelCompleted) {
          if (this.isLastLevel) {
            return this.showSummary();
          }

          return this.showFeedback();
        }
      }

      if (this.isLastQuestionForLevel) {
        return this.showSummary();
      }

      return setTimeout(() => this.showNextQuestion(), 50);
    },

    showNextQuestion() {
      this.hasEntered = true;
      this.isFeedback = false;
      this.questionsCount += 1;

      if (this.isLevelCompleted) {
        this.levelScore = 0;
        this.currentQuestion = 0;
      } else {
        this.currentQuestion += 1;
      }

      this.setTimer();
    },

    goToNextLevel() {
      this.levelTimeStart = Date.now();
      this.showNextQuestion();
    },

    showFeedback() {
      this.isFeedback = true;
      this.currentLevel += 1;
      this.timeTotal += this.getLevelTime();
    },

    async showSummary() {
      this.isQuiz = false;
      this.timeTotal += this.getLevelTime();
      if (this.userLevel === "0") {
        return;
      }

      const payload: Record<string, LanguageMetadata> = {
        [this.language]: {
          levelingTestStatus: LevelingTestStatus.FINISHED,
          level: this.userLevel as CEFRLevel,
          levelingTestCompletedAt: moment().format("DD.MM.YYYY"),
        },
      };
      if (!isEmpty(this.user)) {
        const previousUserLevel = this.user?.userMetadata?.[this.language]?.level || "";
        if (previousUserLevel && previousUserLevel > this.userLevel) {
          this.previousLevel = previousUserLevel;
          payload[this.language].level = previousUserLevel;
        }
        await this.$store.dispatch("user/patchUser", {
          action: UserMetadataAction.LEVELING_TEST_RESULT,
          payload,
        });
      }
      localStorage.setItem("levelingTestResult", JSON.stringify(payload));
      await this.$store.dispatch("course/getRecommended", {
        cefrLevel: payload[this.language].level,
      });
    },

    onRecommendedCourseBtnClicked(params: { courseId: string }): void {
      if (!isEmpty(this.user)) {
        this.$router.push({
          name: "courseDetails",
          params,
        });
        return;
      }
      this.$router.push({
        name: "login",
      });
    },

    close() {
      clearTimeout(this.progressIntervalID);
      this.showSummary();
    },

    getLevelTime() {
      return Date.now() - this.levelTimeStart;
    },

    setTimer() {
      this.progressIntervalID = setInterval(() => {
        this.progress += 100 / this.questionDuration / this.progressFPS;

        if (this.progress >= 100) {
          this.answered(false);
        }
      }, 1000 / this.progressFPS) as unknown as number;
    },
  },
});

import { CEFRLevel, LearningExerciseType, LearningMode, Exercise } from "@prestonly/preston-common";
import _ from "lodash";
import store from "@/store";

const availableTypes = {
  [CEFRLevel.A1]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.WRITING_FILL_THE_GAP,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },
  [CEFRLevel.A2]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FILL_THE_GAP,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },

  [CEFRLevel.B1]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.WRITING_FILL_THE_GAP,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },
  [CEFRLevel.B2]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.WRITING_FILL_THE_GAP,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },

  [CEFRLevel.C1]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.WRITING_FILL_THE_GAP,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },
  [CEFRLevel.C2]: {
    [LearningMode.FLASH_CARDS]: [LearningExerciseType.FLASH_CARD],
    [LearningMode.LISTENING]: [
      LearningExerciseType.LISTENING_FULL_SENTENCE,
      LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.LISTENING_FILL_THE_GAP,
    ],
    [LearningMode.WRITING]: [
      LearningExerciseType.WRITING_FULL_SENTENCE,
      LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS,
      LearningExerciseType.WRITING_FILL_THE_GAP,
    ],
    [LearningMode.SPEAKING]: [LearningExerciseType.SPEAKING_FULL_SENTENCE],
  },
};

const isTypeValidForExample = (example: Exercise) => (type: LearningExerciseType) => {
  switch (type) {
    case LearningExerciseType.LISTENING_FILL_THE_GAP_CHOOSE_BLOCKS:
    case LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS:
    case LearningExerciseType.WRITING_FILL_THE_GAP_CHOOSE_BLOCKS:
    case LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS:
      return example.answer.trim().split(" ").length > 1;
    case LearningExerciseType.WRITING_FILL_THE_GAP:
    case LearningExerciseType.LISTENING_FILL_THE_GAP:
      return example.gaps?.length > 0;
    default:
      return true;
  }
};

class ExerciseTypeSelector {
  private memory = [LearningExerciseType.UNKNOWN, LearningExerciseType.UNKNOWN];

  private pushToMemory(type: LearningExerciseType): void {
    this.memory.pop();
    this.memory.unshift(type);
  }

  private isOverused(type: LearningExerciseType): boolean {
    return this.memory[0] === this.memory[1] ? this.memory[0] === type : false;
  }

  private getUserExerciseSettings() {
    const sessionActivities = sessionStorage.getItem("listeningAndWritingActivities");

    if (sessionActivities) {
      return JSON.parse(sessionActivities);
    }

    const {
      userMetadata: { preferences },
    } = store.getters["user/getUser"];
    if (preferences?.listeningAndWritingActivities) {
      return preferences.listeningAndWritingActivities;
    }

    return {
      fullSentence: true,
      gaps: true,
      blocks: true,
    };
  }

  private getAvailableTypes(mode: LearningMode, cefrLevel: CEFRLevel): LearningExerciseType[] {
    let types = availableTypes[cefrLevel][mode];
    if (mode === LearningMode.LISTENING || mode === LearningMode.WRITING) {
      const listeningAndWritingActivities = this.getUserExerciseSettings();
      types = types.filter((type: LearningExerciseType) => {
        if (
          type === LearningExerciseType.LISTENING_FULL_SENTENCE ||
          type === LearningExerciseType.WRITING_FULL_SENTENCE
        ) {
          return listeningAndWritingActivities.fullSentence;
        } else if (
          type === LearningExerciseType.LISTENING_FILL_THE_GAP ||
          type === LearningExerciseType.WRITING_FILL_THE_GAP
        ) {
          return listeningAndWritingActivities.gaps;
        } else if (
          type === LearningExerciseType.LISTENING_FULL_SENTENCE_CHOOSE_BLOCKS ||
          type === LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS
        ) {
          return listeningAndWritingActivities.blocks;
        }
      });
    }
    return types;
  }

  public next({ cefrLevel, mode, exercise }: { cefrLevel: CEFRLevel; mode: LearningMode; exercise: Exercise }) {
    const types: LearningExerciseType[] = this.getAvailableTypes(mode, cefrLevel);

    if (!types) {
      return LearningExerciseType.UNKNOWN;
    }
    if (types.length === 1) {
      return types[0];
    }
    if (!exercise || !exercise.answer) {
      return LearningExerciseType.UNKNOWN;
    }

    const selectedType =
      (_.chain(types)
        .filter(isTypeValidForExample(exercise))
        .filter((type) => !this.isOverused(type))
        .sample()
        .value() as LearningExerciseType) || LearningExerciseType[`${mode}_FULL_SENTENCE`];
    this.pushToMemory(selectedType);
    return selectedType;
  }
}

export const exerciseTypeSelector = new ExerciseTypeSelector();


import Vue from "vue";

import Header from "@/components/v2/base/Header.vue";
import {
  BaseGiftCard,
  BasketProduct,
  BuyerExtended,
  DiscountCondition,
  formatPrice,
  getFinalTotalPrice,
  getTotalPrice,
  GiftCardBasket,
  InvoiceBaseData,
  LangCode,
  VerifiedCoupon,
} from "@prestonly/preston-common";
import BasketForm from "@/components/v2/subscription/BasketForm.vue";
import BasketGiftCard from "@/components/v2/subscription/BasketGiftCard.vue";
import { GiftCardProduct } from "@/types/common";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "SubscribeGiftCard",
  metaInfo() {
    return {
      title: this.$t("metadata.subscribeGiftCard.title").toString(),
      description: this.$t("metadata.subscribeGiftCard.description").toString(),
    };
  },
  components: {
    Header,
    BasketForm,
    BasketGiftCard,
  },
  data() {
    return {
      loading: false,
      products: [] as GiftCardProduct[],
      giftCardFormState: {
        valid: false,
      },
    };
  },
  computed: {
    payBtnDisabled(): boolean {
      return this.products.length === 0 || !this.giftCardFormState.valid || this.loading === true;
    },
    total(): number {
      return this.products.reduce((acc, product) => {
        acc += product.languages.length * getTotalPrice(product.plan.plan);
        return acc;
      }, 0);
    },
    totalDiscounted(): number {
      return this.products.reduce((acc, product) => {
        acc +=
          product.languages.length *
          getFinalTotalPrice(product.plan.plan, this.verifiedCoupon?.discountCondition || ({} as DiscountCondition));
        return acc;
      }, 0);
    },
    formattedTotalPrice(): string {
      if (this.total === 0) {
        return "–";
      }
      return formatPrice(this.total);
    },
    formattedFinalTotalPrice(): string {
      return formatPrice(this.totalDiscounted);
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
  },
  methods: {
    mapProductsToBasketProducts(): BasketProduct[] {
      return Object.values(
        this.products.reduce((acc, product: GiftCardProduct) => {
          const sourceLang = product.plan.plan.sourceLang;
          product.languages.map((lang) => {
            const key = `${product.plan.value}:${sourceLang}:${lang}`;
            if (!acc[key]) {
              acc[key] = {
                productCode: key,
                quantity: 1,
              };
              return acc;
            }
            ++acc[key].quantity;
            return acc;
          });
          return acc;
        }, {} as Record<string, BasketProduct>)
      );
    },
    mapProductsToGiftCards(): BaseGiftCard[] {
      return this.products.map((product) => {
        const sourceLang = product.plan.plan.sourceLang;
        const productCodes = product.languages.map((lang) => {
          return `${product.plan.value}:${sourceLang}:${lang}`;
        });
        return {
          productCodes,
          inscription: product.inscription || "",
          sendAt: product.startDate || "",
          sendToEmailAddress: product.email || "",
        };
      });
    },
    async onFormSubmission({ buyer, invoice }: { buyer: BuyerExtended; invoice: InvoiceBaseData }) {
      if (this.payBtnDisabled) {
        return;
      }
      this.loading = true;
      const basket: GiftCardBasket = {
        products: this.mapProductsToBasketProducts(),
        coupon: this.verifiedCoupon,
        buyer,
        invoice,
        giftCards: this.mapProductsToGiftCards(),
      };
      const orderCreateResponse = await this.$store.dispatch("basket/submitGiftCardOrder", { basket });
      if (orderCreateResponse.status.statusCode === "SUCCESS") {
        window.location.href = orderCreateResponse.redirectUri;
        return;
      }
      this.loading = false;
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: this.$t("basketForm.genericOrderCreateError"),
          },
        },
        { root: true }
      );
    },
  },
});

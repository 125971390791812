
import moment from "moment";
import { PropType } from "vue";
import { validator, VueWithValidator } from "@/utils/validators";
import SelectField from "@/components/form/inputs/SelectField.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import TooltipInForm from "@/components/v2/base/TooltipInForm.vue";
import DatePickerDialog from "@/components/v2/base/DatePickerDialog.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import { LangCode, SubscriptionPlan, VerifiedCoupon } from "@prestonly/preston-common";
import { getAvailableLanguages } from "@/utils/languages";
import { GiftCardProduct } from "@/types/common";

export default VueWithValidator.extend({
  name: "BasketGiftCardForm",

  components: {
    TextareaField,
    TooltipInForm,
    TextField,
    SelectField,
    DatePickerDialog,
  },

  mixins: [validator],

  data() {
    return {
      valid: false,
      plans: [],
      currentProduct: {
        plan: null as GiftCardProduct["plan"] | null,
        languages: [] as LangCode[],
        email: "",
        startDate: "",
        inscription: "",
      } as GiftCardProduct,
    };
  },

  watch: {
    valid(val) {
      this.$emit("validChanged", val);
    },
    currentProduct: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  props: {
    value: {
      type: Object as PropType<GiftCardProduct>,
    },
    withoutEmail: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    nextWeekday(): string {
      // if friday or saturday return monday
      if ([5, 6].includes(moment().day())) {
        return moment().weekday(8).format("YYYY-MM-DD");
      }
      return moment().add(1, "day").format("YYYY-MM-DD");
    },
    availablePlans(): { text: string; value: string }[] {
      return this.plans.map((plan: SubscriptionPlan) => {
        const [productType, months] = plan.productCode.split(":");

        return {
          text: plan.name,
          value: `${productType}:${months}`,
          plan,
        };
      });
    },
    availableLanguages() {
      return getAvailableLanguages();
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
  },
  methods: {
    setLanguages(value: boolean, langCode: LangCode) {
      this.currentProduct.languages = this.currentProduct.languages.filter((lang) => langCode !== lang);
      if (value) {
        this.currentProduct.languages?.push(langCode);
      }
    },
  },
  async created() {
    this.plans = await this.$store.dispatch("prestonState/getPlans");
  },
});

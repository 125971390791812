var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('v-sheet',{staticClass:"font-weight-bold",attrs:{"min-height":"70vh","rounded":"lg"}},[_c('v-list',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users count:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stats.usersCount))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New Users (last 30 days):")]),_c('v-list-item-subtitle',[(_vm.stats.newUsers && Array.isArray(_vm.stats.newUsers) && _vm.stats.newUsers.length > 0)?_c('ul',_vm._l((_vm.newUsers),function(month){return _c('li',{key:month._id},[_c('b',[_vm._v(_vm._s(month._id)+":")]),_vm._v(" "+_vm._s(month.total)+" ")])}),0):_vm._e()])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("New Subscriptions (last 30 days):")]),_c('v-list-item-subtitle',[(
              _vm.stats.newSubscriptions && Array.isArray(_vm.stats.newSubscriptions) && _vm.stats.newSubscriptions.length > 0
            )?_c('ul',_vm._l((_vm.newSubscriptions),function(month){return _c('li',{key:month._id},[_c('b',[_vm._v(_vm._s(month._id)+":")]),_vm._v(" "+_vm._s(month.total)+" ")])}),0):_vm._e()])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Active subscriptions:")]),_c('v-list-item-subtitle',[(
              _vm.stats.activeSubscriptions &&
              Array.isArray(_vm.stats.activeSubscriptions) &&
              _vm.stats.activeSubscriptions.length > 0
            )?_c('ul',_vm._l((_vm.activeSubscriptions),function([lang, subscriptions]){return _c('li',{key:lang},[_c('b',[_vm._v(_vm._s(lang)+":")]),_vm._l((subscriptions),function(subscription){return _c('ul',{key:subscription.duration},[_c('li',[_c('b',[_vm._v(_vm._s(subscription.duration)+" months:")]),_vm._v(" "+_vm._s(subscription.total)+" ")])])})],2)}),0):_vm._e()])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total revenue:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.revenue))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Active trials:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stats.activeTrials[0]?.total || "-"))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ended trials without subscription purchase:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stats.trialsWithoutSubscription[0]?.total || "-"))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users with expired subscription:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stats.usersWithExpiredSubscription[0]?.total || "-"))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Finished exercises (total):")]),_c('v-list-item-subtitle',[(
              _vm.stats.allFinishedExercises &&
              Array.isArray(_vm.stats.allFinishedExercises) &&
              _vm.stats.allFinishedExercises.length > 0
            )?_c('ul',_vm._l((_vm.stats.allFinishedExercises),function(subType){return _c('li',{key:subType._id},[_c('b',[_vm._v(_vm._s(subType._id)+":")]),_vm._v(" "+_vm._s(subType.total)+" ")])}),0):_vm._e()])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Finished exercises (last 30 days):")]),_c('v-list-item-subtitle',[(
              _vm.stats.recentlyFinishedExercises &&
              Array.isArray(_vm.stats.recentlyFinishedExercises) &&
              _vm.stats.recentlyFinishedExercises.length > 0
            )?_c('ul',_vm._l((_vm.stats.recentlyFinishedExercises),function(subType){return _c('li',{key:subType._id},[_c('b',[_vm._v(_vm._s(subType._id)+":")]),_vm._v(" "+_vm._s(subType.total)+" ")])}),0):_vm._e()])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
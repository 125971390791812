
import Vue, { PropType } from "vue";

import { AttemptResult, Exercise, LearningExerciseType, Lesson } from "@prestonly/preston-common";
import { AttemptEventPayload } from "@/types/progress.js";

import AudioButton from "@/components/base/AudioButton.vue";
import AudioRecording from "@/components/base/AudioRecording.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import TrainerSelfevaluationFooter from "@/components/trainer/TrainerSelfevaluationFooter.vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "TrainerSpeaking",
  components: {
    TileWrapper,
    AudioButton,
    AudioRecording,
    TrainerSelfevaluationFooter,
  },

  props: {
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.SPEAKING_FULL_SENTENCE,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    lessonId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      default: "",
    },
    result: {
      type: Object as PropType<AttemptResult>,
      required: false,
    },
    proceedPossible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    btnText(): string | TranslateResult {
      if (!this.result) {
        return this.$t("buttons.withoutNo.checkAnswer");
      }
      if (this.result.isSuccess) {
        return this.$t("buttons.withoutNo.proceed");
      }
      return this.$t("buttons.withoutNo.tryAgainLong");
    },
  },

  watch: {
    async currentItem() {
      this.attempts = 0;
      this.recordingFinished = false;
    },
  },

  data() {
    return {
      LearningExerciseType,
      attempts: 0,
      loading: false,
      recordingFinished: false,
    };
  },

  methods: {
    onRecordingFinished() {
      if (this.attempts === 0) {
        this.$emit("checkAttempt", {
          check: false,
          userInput: "",
          preferredAnswer: "",
          alternativeAnswers: [],
          lang: this.currentItem.answerLang,
        } as AttemptEventPayload);
        this.recordingFinished = true;
      }
      this.attempts++;
    },
    iKnow() {
      (this.$refs.audioRecorder as any).cancelAudioRecording();
      this.$emit("proceed");
    },
    iDontKnow() {
      (this.$refs.audioRecorder as any).cancelAudioRecording();
      this.$emit("proceed", { forceSuccess: true });
    },
    playRecording(playbackRate: number) {
      (this.$refs.exampleAudioButton as any).playRecording(playbackRate);
    },
  },
});

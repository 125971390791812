import {
  Basket,
  BasketProduct,
  BuyerExtended,
  CouponVerification,
  CouponVerificationStatus,
  InvoiceBaseData,
  OrderCreateResponse,
  OrderStatus,
  PaymentStatus,
  VerifiedCoupon,
} from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { ActionContext } from "vuex";
import { RootState } from "@/store/index.js";

const state = (): Basket => ({
  products: [],
  coupon: null,
  buyer: {} as BuyerExtended,
  invoice: {} as InvoiceBaseData,
});

const mutations = {
  removeCoupon(state: Basket) {
    state.coupon = null;
  },
  addCoupon(state: Basket, coupon: VerifiedCoupon) {
    state.coupon = coupon;
  },
  addProduct(state: Basket, product: BasketProduct): void {
    const isSubscriptionPlan = product.productCode.includes("MONTHLY_SUBSCRIPTION");
    if (isSubscriptionPlan) {
      const filtered = state.products.filter((product) => !product.productCode.includes("MONTHLY_SUBSCRIPTION"));
      state.products = filtered.concat(product);
    }
  },
};

const actions = {
  async verifyCoupon({ commit }: ActionContext<Basket, RootState>, code): Promise<CouponVerification> {
    try {
      const { data } = await httpClient.api.post("/coupon/verify", { code });
      if (data.coupon) {
        commit("addCoupon", data.coupon);
      }
      return data;
    } catch (err) {
      console.error(err);
      return { status: CouponVerificationStatus.INVALID, message: "Nieznany błąd" } as CouponVerification;
    }
  },

  async getOrderStatus(_, { extOrderId }: { extOrderId: string }): Promise<OrderStatus> {
    try {
      const { data: orderStatus } = await httpClient.api.get(`/order/${extOrderId}`);
      return orderStatus;
    } catch (err) {
      console.error(err);
      return { status: PaymentStatus.PENDING } as OrderStatus;
    }
  },

  async submitOrder(_, { basket }: { basket: Basket }): Promise<OrderCreateResponse> {
    try {
      const { data: orderCreateResponse } = await httpClient.api.post("/order", basket);
      return orderCreateResponse;
    } catch (err) {
      console.error(err);
      return {} as OrderCreateResponse;
    }
  },

  async submitGiftCardOrder(_, { basket }: { basket: Basket }): Promise<OrderCreateResponse> {
    try {
      const { data: orderCreateResponse } = await httpClient.api.post("/order/gift-card", basket);
      return orderCreateResponse;
    } catch (err) {
      console.error(err);
      return {} as OrderCreateResponse;
    }
  },
};

const getters = {
  getProducts: (state: Basket): BasketProduct[] => {
    return state.products;
  },
  getVerifiedCoupon: (state: Basket): VerifiedCoupon | null => {
    return state.coupon;
  },
};

export const basketStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

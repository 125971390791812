
import { textToSpeech } from "@/utils";
import { LangCode, Recording } from "@prestonly/preston-common";
import Vue, { PropType } from "vue";

const addZero = (num: number) => (num < 10 ? `0${num}` : num.toString());
const formatTime = (seconds: number) => {
  const helperDate = new Date(0);
  helperDate.setSeconds(seconds);
  return `${addZero(helperDate.getMinutes())}:${addZero(helperDate.getSeconds())}`;
};
export default Vue.extend({
  name: "AudioPlayer",

  components: {},

  props: {
    recordingId: {
      type: String as PropType<string | null>,
      default: null,
    },
    size: {
      type: String,
      default: "34px",
    },
    exampleText: {
      type: String as PropType<string>,
      required: false,
    },
    langCode: {
      type: String as PropType<LangCode>,
      required: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "audio/mp3",
    },
  },

  data() {
    return {
      playbackRateAvailable: [1.0, 0.9, 0.8],
      playbackRateIndex: 0,
      content: "",
      isPlaying: false,
      progress: 0,
      length: "",
      timeElapsed: "",
      audioElement: {} as HTMLAudioElement, // gets created during created()
    };
  },

  methods: {
    startFromTime() {
      this.audioElement.currentTime = (this.progress * this.audioElement.duration) / 100;
      if (!this.isPlaying) {
        this.audioElement.play();
      }
    },
    playRecording(playbackRate?: number) {
      if (playbackRate && playbackRate !== 1) {
        this.playbackRateIndex = 2;
        this.audioElement.playbackRate = this.playbackRate;
      }
      this.togglePlay();
    },
    changeSpeed(): void {
      this.playbackRateIndex++;
      this.playbackRateIndex %= this.playbackRateAvailable.length;
      this.audioElement.playbackRate = this.playbackRate;
    },
    togglePlay() {
      if (!this.content) {
        return;
      }
      if (this.isPlaying) {
        this.audioElement.pause();
      } else {
        this.audioElement.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    pause() {
      if (!this.content) {
        return;
      }
      this.isPlaying = false;
      this.audioElement.pause();
    },
    emitDelete() {
      this.$emit("onDelete");
    },
    onAudioLoadedData() {
      // if audio is less that 1 second long we should still display 00:01 to avoid confusion
      this.length = formatTime(Math.max(this.audioElement.duration, 1));
    },
    onAudioTimeupdate() {
      this.progress = (this.audioElement.currentTime / this.audioElement.duration) * 100;
      this.timeElapsed = formatTime(this.audioElement.currentTime);
    },
    onAudioPause() {
      this.isPlaying = false;
    },
    onAudioPlay() {
      this.isPlaying = true;
    },
    onAudioEnded() {
      this.isPlaying = false;
    },
  },
  computed: {
    iconStyle(): Record<string, string> {
      return { height: this.size, width: this.size };
    },
    playbackRate(): number {
      return this.playbackRateAvailable[this.playbackRateIndex];
    },
    playbackRateLabel(): string {
      return `x${this.playbackRate}`;
    },
  },

  mounted() {
    this.audioElement = new Audio();
    this.audioElement.addEventListener("loadeddata", this.onAudioLoadedData);
    this.audioElement.addEventListener("timeupdate", this.onAudioTimeupdate);
    this.audioElement.addEventListener("pause", this.onAudioPause);
    this.audioElement.addEventListener("play", this.onAudioPlay);
    this.audioElement.addEventListener("ended", this.onAudioEnded);

    if (this.src) {
      this.content = this.src;
      this.audioElement.src = this.content;
      this.audioElement.load();
      return;
    }

    if (this.recordingId) {
      this.$store
        .dispatch("exercise/getRecording", {
          recordingId: this.recordingId,
        })
        .then(() => {
          const recording: Recording = this.$store.getters["exercise/getRecordingById"](this.recordingId);
          this.content = recording.content;
          this.audioElement.src = this.content;
          this.audioElement.load();
        });
      return;
    }

    if (this.exampleText && this.langCode) {
      textToSpeech(this.exampleText, this.langCode as LangCode).then((recording) => {
        const blob = new Blob([new Uint8Array(recording.content.split(",").map((charNum) => +charNum))], {
          type: "audio/mp3",
        });
        this.content = URL.createObjectURL(blob);
        this.audioElement.src = this.content;
        this.audioElement.load();
      });
      return;
    }
    console.error("Cannot load audio player, no src, recordingId or exampleText+langCode provided");
  },
  beforeDestroy() {
    this.audioElement.pause();
  },
});

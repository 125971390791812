
import { codeToFlagMixin } from "@/utils/codeToFlagMixin";
import { LangCode } from "@prestonly/preston-common";
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";

export default Vue.extend({
  name: "TrainerLangSwitcher",
  mixins: [codeToFlagMixin],
  components: {},
  props: {
    exampleLang: String as PropType<LangCode>,
    answerLang: String as PropType<LangCode>,
  },
  computed: {},
});

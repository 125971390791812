import moment from "moment";

interface TimerState {
  startedAt: string | null;
  finishedAt: string | null;
  secondsPassed: number;
}

const state = (): TimerState => ({
  startedAt: null,
  finishedAt: null,
  secondsPassed: 0,
});

let intervalID;

function shouldANewSessionBeCreated(startedAt: string | null) {
  if (!startedAt) {
    return false;
  }
  const startOfCurrentDay = moment().startOf("day");
  const startOfSession = moment(startedAt).startOf("day");
  return startOfCurrentDay.isAfter(startOfSession);
}

const mutations = {
  startSession(state: TimerState, { time }: { time: number }): void {
    const localStorageTimerState = localStorage.getItem("timerState");
    if (localStorageTimerState) {
      try {
        const { startedAt, finishedAt, secondsPassed } = JSON.parse(localStorageTimerState);
        state.startedAt = startedAt || null;
        state.finishedAt = finishedAt || null;
        state.secondsPassed = secondsPassed || 0;
      } catch (e) {
        (this as any).commit("timer/resetState");
      }
    }
    const isSessionTimeExceeded = state.secondsPassed >= time;
    if (shouldANewSessionBeCreated(state.startedAt)) {
      (this as any).commit("timer/resetState");
    }

    if (state.finishedAt && isSessionTimeExceeded) {
      return;
    }
    if (state.finishedAt && !isSessionTimeExceeded) {
      state.finishedAt = null;
    }
    if (!state.startedAt) {
      state.startedAt = new Date().toISOString();
    }
    if (!intervalID) {
      intervalID = setInterval(() => {
        ++state.secondsPassed;
        localStorage.setItem("timerState", JSON.stringify(state));
      }, 1000);
    }
  },
  resetState(state: TimerState): void {
    state.startedAt = null;
    state.finishedAt = null;
    state.secondsPassed = 0;
    localStorage.setItem("timerState", JSON.stringify(state));
  },
  stopSession(): void {
    clearInterval(intervalID);
    intervalID = null;
  },
  finishSession(state: TimerState): void {
    if (!state.finishedAt) {
      state.finishedAt = new Date().toISOString();
    }
    localStorage.setItem("timerState", JSON.stringify(state));
    clearInterval(intervalID);
    intervalID = null;
  },
};

const getters = {
  getSecondsPassed: (state: TimerState): number => {
    return state.secondsPassed;
  },
  getFinishedAt: (state: TimerState): null | string => {
    return state.finishedAt;
  },
};

export const timerStore = {
  namespaced: true,
  state,
  mutations,
  getters,
};

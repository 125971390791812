
import moment from "moment";
import Vue, { PropType } from "vue";
import { LearningMode, LessonAvailableToRepeat } from "@prestonly/preston-common";

import TileWrapper from "@/components/base/TileWrapper.vue";
import FinishedModeIcons from "@/components/base/FinishedModeIcons.vue";
import Btn from "@/components/v2/base/Btn.vue";
import Header from "@/components/v2/base/Header.vue";
import TooltipBtn from "@/components/v2/base/TooltipBtn.vue";

export default Vue.extend({
  name: "LessonRepetitionTile",
  components: {
    Btn,
    TooltipBtn,
    Header,
    TileWrapper,
    FinishedModeIcons,
  },
  props: {
    lessonAvailableToRepeat: {
      type: Object as PropType<LessonAvailableToRepeat>,
      required: true,
    },
    finishedModes: {
      type: Array as PropType<LearningMode[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getRelativeTime(date: string) {
      // TODO - do it globally based on user browser preferences or user settings
      moment.locale("pl");
      return moment(date).fromNow();
    },
    repetitionAction() {
      this.$emit("btnClicked", { lessonId: this.lessonAvailableToRepeat.lessonId });
    },
  },
});

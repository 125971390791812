
import { Company, PrestonUser } from "@prestonly/preston-common";
import moment from "moment";
import Vue from "vue";

import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "UserProfileCompanyData",
  components: {
    Header,
  },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    company(): Company {
      return this.$store.getters["company/getCompany"];
    },
  },
  methods: {
    isCompanyAdmin(userId: string): boolean {
      if (!this.company?.adminIds) {
        return false;
      }
      return this.company.adminIds.includes(userId);
    },
    async getCompany() {
      if (!this.user.company?.companyId) {
        return;
      }
      await this.$store.dispatch("company/getCompany", {
        companyId: this.user.company?.companyId || "",
      });
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  async mounted() {
    await this.getCompany();
  },
});


import Vue, { PropType } from "vue";
import { LangCode } from "@prestonly/preston-common";

export default Vue.extend({
  name: "SelectLanguageButton",
  components: {},
  props: {
    langCode: String as PropType<LangCode>,
    access: Object as PropType<{ text: string; count: number } | null>,
    src: String,
    size: {
      type: Number,
      default: 100,
    },
  },
  computed: {},
  methods: {
    emitSetLang() {
      this.$emit("onSetLang");
    },
  },
});

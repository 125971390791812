
import Vue from "vue";
import _ from "lodash";
// import { startTour } from "@/utils/startTour";
import {
  FinishedMode,
  LearningMode,
  RepetitionStartedEvent,
  ProgressEventName,
  LessonAvailableToRepeat,
} from "@prestonly/preston-common";

import EndListText from "@/components/base/EndListText.vue";
import LessonRepetitionTile from "@/components/base/LessonRepetitionTile.vue";
import Header from "@/components/v2/base/Header.vue";
import Alert from "@/components/v2/base/Alert.vue";
import Btn from "@/components/v2/base/Btn.vue";
import SelectField from "@/components/form/inputs/SelectField.vue";
import { mapGetters } from "vuex";
import TextField from "@/components/form/inputs/TextField.vue";

export default Vue.extend({
  name: "Exercises",
  metaInfo() {
    return {
      title: this.$t("metadata.exercises.title").toString(),
    };
  },
  components: { TextField, Btn, Alert, LessonRepetitionTile, Header, EndListText, SelectField },
  data() {
    return {
      search: "",
      courseId: "",
      loading: true,
      allLessonsAvailableToRepeatMap: {},
      lessonsAvailableToRepeatMap: {},
      finishedModesMap: {},
    };
  },
  computed: {
    ...mapGetters({
      courses: "course/getList",
    }),
    coursesForAvailableLessons() {
      const lessonIds = Object.values(this.allLessonsAvailableToRepeatMap).map((lesson: any) => lesson.lessonId);

      return this.courses
        .filter((course) => course.lessons.some((lesson) => lessonIds.includes(lesson.lessonId)))
        .map((course) => ({ value: course._id, text: course.name }));
    },
    areLessonsAvailable(): boolean {
      return Object.values(this.lessonsAvailableToRepeatMap).length > 0;
    },
    newLessons(): LessonAvailableToRepeat[] {
      return this.filteredLessons
        .filter((lesson) => lesson.isNew)
        .sort((a, b) => (a.lastRepetition < b.lastRepetition ? 1 : a.lastRepetition > b.lastRepetition ? -1 : 0));
    },
    sortedLessons(): LessonAvailableToRepeat[] {
      return this.filteredLessons
        .filter((lesson) => !lesson.isNew)
        .sort((a, b) => (a.lastRepetition < b.lastRepetition ? 1 : a.lastRepetition > b.lastRepetition ? -1 : 0));
    },

    filteredLessons(): LessonAvailableToRepeat[] {
      debugger;
      let lessons: LessonAvailableToRepeat[] = Object.values(this.allLessonsAvailableToRepeatMap);
      if (this.search) {
        lessons = lessons.filter((lesson) => {
          const stringified = JSON.stringify(lesson).toLowerCase();
          return stringified.includes(this.search);
        });
      }

      if (this.courseId) {
        const course = this.courses.filter((course) => course._id === this.courseId);
        const lessonIds = course[0].lessons.map((lesson) => lesson.lessonId);
        lessons = lessons.filter((lesson: any) => lessonIds.includes(lesson.lessonId));
      }

      return lessons;
    },
  },
  methods: {
    resetFilters() {
      this.search = "";
      this.courseId = "";
    },
    async onBtnClicked({ lessonId }: { lessonId: string }): Promise<void> {
      const lessonRepetition = this.lessonsAvailableToRepeatMap[lessonId];
      if (!lessonRepetition || (lessonRepetition && lessonRepetition.isNew)) {
        const event: RepetitionStartedEvent = {
          lessonId,
          createdAt: new Date().toISOString(),
          name: ProgressEventName.REPETITION_STARTED,
          payload: {},
        };
        await this.$store.dispatch("progress/saveProgress", event);
      }
      await this.$router.push({
        name: "repetitionModeSelector",
        params: { lessonId },
      });
    },
    getFinishedModesForLesson(lessonId: string): LearningMode[] {
      const modes = this.finishedModesMap[lessonId] || {};
      if (!_.isEmpty({})) {
        return [];
      }
      return Object.values<FinishedMode>(modes)
        .filter((mode) => !!mode.finishedAt)
        .map(({ mode }) => mode) as LearningMode[];
    },
    async getLessonAvailableToRepeat() {
      this.lessonsAvailableToRepeatMap = await this.$store.dispatch("progress/getLessonsAvailableToRepeat", {
        langCode: this.$store.getters["prestonState/getLearnLanguage"],
      });
      this.allLessonsAvailableToRepeatMap = this.lessonsAvailableToRepeatMap;
    },
    async getFinishedModes() {
      this.finishedModesMap = await this.$store.dispatch("progress/getFinishedModes");
    },
    async getCourses(): Promise<void> {
      await this.$store.dispatch("course/getAvailable", {
        langCode: this.$store.getters["prestonState/getLearnLanguage"],
      });
    },
  },
  async created() {
    this.loading = true;
    await this.getLessonAvailableToRepeat();
    await this.getFinishedModes();
    await this.getCourses();
    this.loading = false;
  },
});

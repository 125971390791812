import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { DEFAULT_SNACKBAR_CONFIG, Snackbar, SnackbarConfig } from "@/types/snackbar";

interface SnackbarState {
  snackbars: Record<string, Snackbar>;
}

const state = (): SnackbarState => ({
  snackbars: {},
});

const mutations = {
  open(state: SnackbarState, snackbar: Snackbar): void {
    state.snackbars[snackbar.id] = snackbar;
    state.snackbars = { ...state.snackbars };
  },
  close(state: SnackbarState, snackbar: Snackbar): void {
    delete state.snackbars[snackbar.id];
    state.snackbars = { ...state.snackbars };
  },
};

const actions = {
  open({ commit }: ActionContext<SnackbarState, RootState>, { config }: { config: SnackbarConfig }): Snackbar {
    return new Snackbar({ ...DEFAULT_SNACKBAR_CONFIG, ...(config || {}) }, commit);
  },
  close({ commit }: ActionContext<SnackbarState, RootState>, snackbar: Snackbar): void {
    commit("close", snackbar);
  },
};

const getters = {
  getList: (state: SnackbarState): Snackbar[] => {
    return Object.values(state.snackbars);
  },
};

export const snackbarsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

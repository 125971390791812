
import Vue from "vue";
import TagSearchTooltip from "@/components/TagSearchTooltip.vue";
import TextField from "@/components/form/inputs/TextField.vue";

export default Vue.extend({
  name: "SearchTextField",
  components: {
    TextField,
    TagSearchTooltip,
  },
});


import Vue from "vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import Header from "@/components/v2/base/Header.vue";
import Btn from "@/components/v2/base/Btn.vue";
import { OnboardingStatus, PrestonUser } from "@prestonly/preston-common";

export default Vue.extend({
  name: "LevelingTestIntroduction",
  components: {
    TileWrapper,
    Header,
    Btn,
  },
  computed: {
    language(): string {
      return (this.$route.params.lang || "").toUpperCase();
    },
    isUserOnboarded(): boolean {
      return [OnboardingStatus.SKIPPED, OnboardingStatus.COMPLETED].includes(
        this.user?.userMetadata?.onboardingStatus || OnboardingStatus.PENDING
      );
    },
    user(): PrestonUser | null {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    skipLevelingTestDuringOnboarding() {
      this.$router.push({ name: "root" });
    },
  },
});

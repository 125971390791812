
import moment from "moment";
import Vue, { VueConstructor } from "vue";

import { codeToFlagMixin } from "@/utils/codeToFlagMixin";
import { startTrialMixin } from "@/utils/startTrialMixin";
import { LangCode, PrestonUser } from "@prestonly/preston-common";

import Header from "@/components/v2/base/Header.vue";
import TextBtn from "@/components/v2/base/TextBtn.vue";
import Btn from "@/components/v2/base/Btn.vue";
import Card from "@/components/v2/base/Card.vue";
import SimpleAlert from "@/components/v2/base/SimpleAlert.vue";
import BasketCouponForm from "@/components/v2/subscription/BasketCouponForm.vue";
import { getAvailableLanguages, LangConfig } from "@/utils/languages";

export default (Vue as VueConstructor<Vue & InstanceType<typeof startTrialMixin>>).extend({
  name: "BasketSummary",

  mixins: [codeToFlagMixin, startTrialMixin],

  data() {
    return {
      changeLanguage: false,
    };
  },

  components: {
    BasketCouponForm,
    SimpleAlert,
    TextBtn,
    Btn,
    Card,
    Header,
  },

  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    months: {
      type: Number,
      default: 0,
    },
    selectedPlan: {
      type: Object,
      default: () => ({}),
    },
    planName: {
      type: String,
      default: "",
    },
    formattedTotalPrice: {
      type: String,
      default: "",
    },
    formattedFinalTotalPrice: {
      type: String,
      default: "",
    },
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },

    isTrialAvailable(): number {
      return this.$store.getters["user/isTrialAvailable"];
    },

    trialDaysLeft(): number {
      return this.$store.getters["user/getTrialDaysLeft"];
    },

    trialTextContent(): string {
      if (!this.trialDaysLeft || this.trialDaysLeft <= -1) {
        return "";
      }
      return this.trialDaysLeft === 0 ? "header.statusBar.trialLastDay" : "header.statusBar.trialOverSoon";
    },

    currentSubscriptionDaysLeft(): number {
      return this.$store.getters["user/getSubscriptionDaysLeft"](this.langCode);
    },

    langCode(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },

    currentSubscriptionFinishAt(): string {
      if (this.currentSubscriptionDaysLeft === -1) {
        return "";
      }
      return moment().add(this.currentSubscriptionDaysLeft, "days").format("DD.MM.YYYY");
    },
    endOfSubscriptionAfterPayment(): string {
      const start =
        this.currentSubscriptionDaysLeft > 0 ? moment(this.currentSubscriptionFinishAt, "DD.MM.YYYY") : moment();
      return start.add(this.months, "months").format("DD.MM.YYYY");
    },
    remainingLanguages(): LangConfig[] {
      return getAvailableLanguages().filter((langConfig) => {
        return langConfig.langCode !== this.langCode;
      });
    },
  },
  methods: {
    async selectLanguage(langCode: LangCode) {
      this.changeLanguage = false;
      await this.$store.dispatch("prestonState/setLearnLanguage", langCode);
    },
  },
});


import { LangCode, Recording } from "@prestonly/preston-common";
import { textToSpeech } from "@/utils";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AudioButton",
  props: {
    margin: {
      type: Boolean,
      default: true,
    },
    marginLeft: {
      type: Boolean,
      default: false,
    },
    showSlower: {
      type: Boolean,
      default: false,
    },
    recordingId: {
      type: String as PropType<string | null>,
      default: null,
    },
    exampleText: {
      type: String as PropType<string | null>,
      default: null,
    },
    langCode: {
      type: String as PropType<LangCode | null>,
      default: null,
    },
    content: {
      type: String as PropType<string | null>,
      default: null,
    },
    size: {
      type: String,
      default: "34px",
    },
  },
  computed: {
    iconStyle(): Record<string, string> {
      return { height: this.size, width: this.size };
    },
  },
  data() {
    return {
      audioButtonId: Date.now(),
      isRecordingPlayed: false,
      audioElem: {} as HTMLAudioElement,
      playbackRate: 1.0,
      isLoading: false,
      clickCount: 0,
      clickTimer: null as any,
      delay: 350,
    };
  },
  methods: {
    play(content: string) {
      const event = new CustomEvent("stopPlayingAudio", { detail: this.audioButtonId });
      document.dispatchEvent(event);
      this.isRecordingPlayed = true;
      this.audioElem.src = content;
      this.audioElem.load();
      this.audioElem.playbackRate = this.playbackRate;
      this.audioElem.play();
    },
    clickHandler() {
      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.clickTimer = 0;
          this.playRecording();
        }, this.delay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.clickTimer = 0;
        this.playRecording(0.79);
      }
    },

    async playRecording(playbackRate = 1) {
      if (playbackRate && typeof playbackRate === "number") {
        this.playbackRate = playbackRate;
      }

      // play user-recorded recording or newly updated audio in admin panel
      if (this.content) {
        await this.play(this.content);
        return;
      }

      if (this.recordingId) {
        this.isLoading = true;
        await this.$store.dispatch("exercise/getRecording", {
          recordingId: this.recordingId,
        });
        const recording: Recording = this.$store.getters["exercise/getRecordingById"](this.recordingId);
        this.isLoading = false;
        return this.play(recording.content);
      }

      // get recording from text-to-speech api
      if (this.exampleText && this.langCode) {
        this.isLoading = true;
        const recording = await textToSpeech(this.exampleText, this.langCode as LangCode);
        const blob = new Blob([new Uint8Array(recording.content.split(",").map((charNum) => +charNum))], {
          type: "audio/mp3",
        });
        this.isLoading = false;
        return this.play(URL.createObjectURL(blob));
      }
    },
  },
  created() {
    this.audioElem = new Audio();
    document.addEventListener("stopPlayingAudio", (event) => {
      this.audioElem.pause();
      this.isRecordingPlayed = false;
      if ((event as CustomEvent).detail !== this.audioButtonId) {
        this.playbackRate = 1.0;
      }
    });
    this.audioElem.addEventListener("ended", () => {
      this.playbackRate = 1.0;
      this.isRecordingPlayed = false;
    });
  },
  beforeDestroy() {
    this.audioElem.pause();
  },
});

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { httpClient } from "@/utils";
import { RecognizePayload } from "@prestonly/preston-common";

const actions = {
  async recognize(_: ActionContext<unknown, RootState>, payload: RecognizePayload): Promise<string | undefined> {
    try {
      const { data } = await httpClient.api.post(`/speech-to-text/recognize`, payload);
      return data;
    } catch (err) {
      console.error(err);
    }
  },
  async recognizeV2(
    _: ActionContext<unknown, RootState>,
    payload: RecognizePayload & { source: any; prompt: string }
  ): Promise<string | undefined> {
    try {
      const formData = new FormData();
      const fileName = `test.wav`;
      const file = new File([payload.source], fileName);
      formData.append("file", file, fileName);
      formData.append("type", payload.type);
      formData.append("langCode", payload.langCode);
      formData.append("prompt", payload.prompt);

      const { data } = await httpClient.api.post(`/speech-to-text/recognize-v2`, formData, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  },
};

export const speechToTextStore = {
  namespaced: true,
  actions,
};

import { ActionContext } from "vuex";
import { RootState } from "../index";
import { httpClient } from "@/utils";

interface TagsState {
  tags: string[];
}

const state = (): TagsState => ({
  tags: [],
});

const mutations = {
  setList(state: TagsState, tags: string[]): void {
    state.tags = ([] as string[]).concat(tags);
  },
};

const actions = {
  async getList({ commit }: ActionContext<TagsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/tag", {
        params,
      });
      commit("setList", data);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  getList: (state: TagsState): string[] => {
    return state.tags;
  },
};

export const tagsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

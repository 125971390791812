
import Vue, { PropType } from "vue";

import { AttemptResult, Exercise, LearningExerciseType, Lesson, UserPreferences } from "@prestonly/preston-common";
import { AttemptEventPayload } from "@/types/progress";
import { SnackbarType } from "@/types/snackbar";

import AudioButton from "@/components/base/AudioButton.vue";
import AudioRecordingV2 from "@/components/base/AudioRecordingV2.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import TrainerExampleResult from "@/components/trainer/TrainerExampleResult.vue";
import TrainerNavigationButtons from "@/components/trainer/TrainerNavigationButtons.vue";
import { MINIMUM_TRIES_REQUIRED_TO_SKIP } from "@/constants/trainer";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "TrainerSpeaking",
  components: {
    TileWrapper,
    AudioButton,
    AudioRecordingV2,
    TrainerExampleResult,
    TrainerNavigationButtons,
  },

  props: {
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.SPEAKING_FULL_SENTENCE,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    lessonId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      default: "",
    },
    result: {
      type: Object as PropType<AttemptResult>,
      required: false,
    },
    proceedPossible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    proceedVisible(): boolean {
      return !!this.result;
    },
    navigationButtonProps(): { attempts: any; result: any; moreMenuVisible: boolean; proceedVisible: boolean } {
      return {
        proceedVisible: this.proceedVisible,
        result: this.result,
        attempts: this.attempts,
        moreMenuVisible: this.moreMenuVisible,
      };
    },
    btnText(): string | TranslateResult {
      if (!this.result) {
        return this.$t("buttons.withoutNo.checkAnswer");
      }
      if (this.result.isSuccess) {
        return this.$t("buttons.withoutNo.proceed");
      }
      return this.$t("buttons.withoutNo.tryAgainLong");
    },
    navigationBtnListeners(): Record<string, any> {
      return {
        forgetTemporarily: () => this.$emit("forgetTemporarily"),
        forget: () => this.$emit("forget"),
        playRecording: this.playRecording,
        proceed: this.proceed,
        skip: this.skip,
      };
    },
    userPreferences(): UserPreferences {
      return this.$store.getters["user/preferences"] as UserPreferences;
    },
    autoplayRecording(): boolean {
      return this.userPreferences.autoplayAnswer;
    },
  },

  watch: {
    async currentItem() {
      this.attempts = 0;
      this.recordingFinished = false;
    },
    async result(val) {
      if (val !== null && this.autoplayRecording) {
        setTimeout(() => {
          (this.$refs.result as InstanceType<typeof TrainerExampleResult>)?.audioButton?.playRecording();
        }, 300);
      }
    },
  },

  data() {
    return {
      textHidden: false,
      LearningExerciseType,
      attempts: 0,
      loading: false,
      recordingFinished: false,
    };
  },

  methods: {
    async onRecordingFinished({ status, text, reason }: { status: number; text: string; reason?: string }) {
      if (status === 0) {
        const message = reason ? this.$t(`recordingErrorsV2.${reason}`) : "";
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              title: "",
              type: SnackbarType.WARN,
              message: message || this.$t("snackbar.speaking.error"),
            },
          },
          { root: true }
        );
        this.recordingFinished = true;
        return;
      }

      this.attempts++;
      this.$emit("checkAttempt", {
        check: true,
        userInput: text,
        preferredAnswer: this.currentItem.answer,
        alternativeAnswers: this.currentItem.alternativeAnswers ?? [],
        lang: this.currentItem.answerLang,
        simplifiedCheck: true,
      } as AttemptEventPayload);
      this.recordingFinished = true;
    },

    tryAgain() {
      (this.$refs.audioRecorder as any).cancelAudioRecording();
      this.$emit("tryAgain");
    },
    skip() {
      if (this.attempts < MINIMUM_TRIES_REQUIRED_TO_SKIP || (this.result && this.result.isSuccess)) {
        return;
      }
      const payload: Record<string, boolean> = { forceSuccess: true };
      if (!this.userPreferences.randomizeRepetitionOrder) {
        payload.skipped = true;
        payload.forceSuccess = false;
      }
      (this.$refs.audioRecorder as any).cancelAudioRecording();
      this.$emit("proceed", payload);
    },
    proceed() {
      if (this.result && this.result.isSuccess) {
        (this.$refs.audioRecorder as any).cancelAudioRecording();
        this.$emit("proceed");
        return;
      }
      this.tryAgain();
    },
    playRecording(playbackRate: number) {
      if (this.result) {
        (this.$refs.result as InstanceType<typeof TrainerExampleResult>)?.audioButton?.playRecording(playbackRate);
        return;
      }
      (this.$refs.exampleAudioButton as any).playRecording(playbackRate);
    },
  },
});


import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { AttemptResult } from "@prestonly/preston-common";

import { MINIMUM_TRIES_REQUIRED_TO_SKIP } from "@/constants/trainer";
import TrainerFooterMoreMenu from "@/components/trainer/TrainerFooterMoreMenu.vue";
import MobileFooterButtons, { MobileFooterBtnConfig } from "@/components/base/MobileFooterButtons.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TooltipBtn from "@/components/v2/base/TooltipBtn.vue";

export default Vue.extend({
  name: "TrainerNavigationButtons",

  components: {
    MobileFooterButtons,
    TrainerFooterMoreMenu,
    TooltipBtn,
    Btn,
  },

  props: {
    proceedVisible: {
      type: Boolean,
      default: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
    attempts: {
      type: Number,
      required: true,
    },
    result: {
      type: Object as PropType<AttemptResult>,
      required: false,
    },
  },

  data() {
    return {
      clickCount: 0,
      clickTimer: null as any,
      delay: 350,
    };
  },

  computed: {
    buttons(): MobileFooterBtnConfig[] {
      const buttons = [
        {
          icon: "skip_next",
          text: this.$t("buttons.withoutNo.skip"),
          action: this.skip,
          disabled: this.skipDisabled,
          disabledTooltipContent: this.$t("tooltips.attemptRequired").toString(),
          btnColor: "secondary",
        },
        {
          icon: "play_arrow",
          text: this.$t("buttons.withoutNo.play"),
          action: this.clickHandler,
          btnColor: "secondary",
        },
      ];
      if (!this.proceedVisible) {
        return buttons;
      }
      return [
        ...buttons,
        {
          icon: this.mobileBtnIcon,
          text: this.btnText,
          action: this.proceed,
        },
      ];
    },
    skipDisabled(): boolean {
      return this.attempts < MINIMUM_TRIES_REQUIRED_TO_SKIP || (this.result && this.result.isSuccess);
    },
    btnText(): TranslateResult {
      if (!this.result) {
        return this.$t("buttons.withoutNo.check");
      }
      if (this.result.isSuccess) {
        return this.$t("buttons.withoutNo.proceed");
      }
      return this.$t("buttons.withoutNo.tryAgain");
    },
    mobileBtnIcon(): string {
      if (!this.result) {
        return "navigate_next";
      }
      if (this.result.isSuccess) {
        return "navigate_next";
      }
      return "replay";
    },
  },

  methods: {
    clickHandler() {
      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.clickTimer = 0;
          this.playRecording();
        }, this.delay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.clickTimer = 0;
        this.playRecording(0.79);
      }
    },
    skip() {
      this.$emit("skip");
    },
    playRecording(playbackRate = 1) {
      this.$emit("playRecording", playbackRate);
    },
    proceed() {
      this.$emit("proceed");
    },
    keyDownListener(evt) {
      if (evt.key === "Enter") {
        this.proceed();
      }
    },
  },
  created() {
    document.addEventListener("keydown", this.keyDownListener);
  },
  destroyed() {
    document.removeEventListener("keydown", this.keyDownListener);
  },
});

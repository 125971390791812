
import Vue from "vue";

export default Vue.extend({
  name: "ReadMore",
  props: {
    content: {
      type: String,
      required: true,
      default: "",
    },
    previewLength: {
      type: Number,
      default: 350,
    },
    readMoreText: {
      type: String,
      default: function () {
        return this.$t("readMore");
      },
    },
    readLessText: {
      type: String,
      default: function () {
        return this.$t("readLess");
      },
    },
  },
  computed: {
    finalContent() {
      if (this.fullContentActive || (this.content as string).length <= this.previewLength) {
        return this.content;
      }
      return `${this.content.slice(0, this.previewLength).trim()}...`;
    },
  },
  data() {
    return {
      fullContentActive: false,
    };
  },
});

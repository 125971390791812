<template>
  <div class="card feedback">
    <div class="card__header">
      <div>
        <svg width="150" height="150" class="chart">
          <circle ref="pie" r="75" cx="75" cy="75" class="pie" />
          <circle r="30" cx="75" cy="75" class="mask" />
        </svg>
      </div>
      <p class="card__title">Świetnie Ci idzie!<br />Przechodzisz na wyższy poziom.</p>
    </div>

    <div class="card__content text-center">
      <Btn block @click="gotoNextLevel">Gotów? Start</Btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  components: {
    Btn,
  },
  props: {
    currentLevel: {
      required: true,
      type: Number,
    },
    levelsTotal: {
      required: true,
      type: Number,
    },
  },

  created() {
    setTimeout(() => this.fillCompleted(), 400);
  },

  methods: {
    gotoNextLevel() {
      this.$emit("nextLevel");
    },

    fillCompleted() {
      return (this.$refs.pie.style.strokeDasharray = `${(472 / this.levelsTotal) * this.currentLevel} 472`);
    },
  },
});
</script>

import { ActionContext } from "vuex";
import { RootState } from "../index";
import {
  BaseListFilters,
  Exercise,
  Lesson,
  ListMetadata,
  Recording,
  RecordingType,
  LearningMode,
  ReportBugPayload,
} from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { SnackbarType } from "@/types/snackbar";

interface ExercisesState {
  exerciseListMetadata: ListMetadata;
  exercisesMap: Record<string, Exercise>;
  searchResults: Record<string, Exercise>;
  recordingsMap: Record<string, Recording>;
  latestQueryParams: BaseListFilters;
}

const state = (): ExercisesState => ({
  exerciseListMetadata: { total: 0 },
  exercisesMap: {},
  searchResults: {},
  recordingsMap: {},
  latestQueryParams: {},
});

const mutations = {
  delete(state: ExercisesState, exerciseId: string): void {
    delete state.exercisesMap[exerciseId];
    delete state.searchResults[exerciseId];
    state.exercisesMap = { ...state.exercisesMap };
    state.searchResults = { ...state.searchResults };
  },
  unsetRecordingList(state: ExercisesState, recordingId: string) {
    delete state.recordingsMap[recordingId];
    state.recordingsMap = { ...state.recordingsMap };
  },
  setRecordingList(state: ExercisesState, recordings: Recording[]): void {
    for (const recording of recordings) {
      const id = recording._id as unknown as string;
      state.recordingsMap[id] = recording;
    }
    state.recordingsMap = { ...state.recordingsMap };
  },
  setLatestQueryParams(state: ExercisesState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: ExercisesState, metadata: ListMetadata): void {
    state.exerciseListMetadata = metadata;
  },
  setList(state: ExercisesState, exercises: Exercise[]): void {
    for (const exercise of exercises) {
      const id = exercise._id as unknown as string;
      state.exercisesMap[id] = exercise;
    }
    state.exercisesMap = { ...state.exercisesMap };
  },
  searchResults(state: ExercisesState, exercises: Exercise[]): void {
    state.searchResults = {};
    for (const exercise of exercises) {
      const id = exercise._id as unknown as string;
      state.searchResults[id] = exercise;
    }
    state.searchResults = { ...state.searchResults };
  },
};

const actions = {
  async getList({ commit }: ActionContext<ExercisesState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/exercise", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getCourseExercises({ commit }: ActionContext<ExercisesState, RootState>, courseId: string): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/course/${courseId}/exercises`);
      commit("setList", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getLessonExercises(
    { commit }: ActionContext<ExercisesState, RootState>,
    { lessonId, activeOnly }: { lessonId: string; activeOnly: boolean }
  ): Promise<Exercise[]> {
    try {
      const { data } = await httpClient.api.get(`/lesson/${lessonId}/exercises`, {
        params: {
          activeOnly,
        },
      });
      commit("setList", data.data);
      commit("setListMetadata", data.metadata[0]);
      return data.data;
    } catch (err) {
      console.error(err);
      return [] as Exercise[];
    }
  },

  async update(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { exercise, exerciseId }: { exercise: Partial<Exercise>; exerciseId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.put(`/exercise/${exerciseId}`, exercise);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane ćwiczenia zostały poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async create(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { exercise }: { exercise: Partial<Exercise> }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/exercise`, exercise);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie utworzone.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },

  async getSingle(
    { commit, state }: ActionContext<ExercisesState, RootState>,
    { id, force }: { id: string; force: boolean }
  ): Promise<void> {
    try {
      if (state.exercisesMap[id] && !force) {
        return;
      }
      const { data } = await httpClient.api.get("/exercise", {
        params: { filters: `_id:${id}`, options: [`populateHints:1`, `includeReportedBugs:1`] },
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async delete(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { exerciseId }: { exerciseId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/exercise/${exerciseId}`);
      commit("delete", exerciseId);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie usunięte.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd usuwania ćwiczenia.",
          },
        },
        { root: true }
      );
    }
  },

  async copy(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { exerciseId }: { exerciseId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/exercise/${exerciseId}/copy`);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie skopiowane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async removeRecording(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { recordingId, exerciseId }: { recordingId: string; exerciseId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/exercise/${exerciseId}/recording/${recordingId}`);
      commit("unsetRecordingList", recordingId);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Nagranie zostało poprawnie usunięte.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async saveRecording(
    { commit, dispatch }: ActionContext<ExercisesState, RootState>,
    { recording, exerciseId, type }: { recording: Recording; exerciseId: string; type: RecordingType }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/exercise/${exerciseId}/recording/${type}`, recording);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Nagranie zostało poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async getRecording(
    { commit, state }: ActionContext<ExercisesState, RootState>,
    { recordingId }: { recordingId: string }
  ): Promise<Recording> {
    if (state.recordingsMap[recordingId]) {
      return state.recordingsMap[recordingId];
    }
    try {
      const { data } = await httpClient.api.get(`/recording/${recordingId}`);
      commit("setRecordingList", [].concat(data));
      return data;
    } catch (err) {
      console.error(err);
      return {} as Recording;
    }
  },

  async reportBug(
    { dispatch }: ActionContext<ExercisesState, RootState>,
    {
      exerciseId,
      comment,
      suggestedAnswer,
      mode,
    }: {
      exerciseId: string;
      comment: ReportBugPayload["comment"];
      suggestedAnswer: ReportBugPayload["suggestedAnswer"];
      mode: ReportBugPayload["mode"];
    }
  ): Promise<void> {
    const payload: ReportBugPayload = { comment, suggestedAnswer, mode };
    try {
      await httpClient.api.post(`/exercise/${exerciseId}/report`, payload);

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dziękujemy za zgłoszenie błędu.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Coś poszło nie tak. Spróbuj ponownie później.",
          },
        },
        { root: true }
      );
    }
  },
};

const getters = {
  getSearchResults: (state: ExercisesState): Exercise[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: ExercisesState): Exercise[] => {
    return Object.values(state.exercisesMap);
  },
  getListMetadata: (state: ExercisesState): ListMetadata => {
    return state.exerciseListMetadata;
  },
  getLatestQueryParams: (state: ExercisesState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getMap: (state: ExercisesState): Record<string, Exercise> => {
    return state.exercisesMap;
  },
  getById: (state: ExercisesState): ((exerciseId: string) => Exercise) => {
    return (exerciseId: string) => {
      return state.exercisesMap[exerciseId];
    };
  },
  getLessonExercises: (state: ExercisesState): ((lesson: Lesson) => Exercise[]) => {
    return (lesson: Lesson) => {
      const lessonExerciseIds = (lesson?.items || []).map((item) => item.itemId);
      return Object.values(state.exercisesMap).filter((exercise) => {
        return exercise._id ? lessonExerciseIds.includes(exercise._id.toString()) : false;
      });
    };
  },

  getRecordingById: (state: ExercisesState): ((recordingId: string) => Recording) => {
    return (recordingId: string) => {
      return state.recordingsMap[recordingId];
    };
  },
};

export const exercisesStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

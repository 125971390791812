
import Vue from "vue";

import { Hint } from "@prestonly/preston-common";

import BaseHintForm from "@/components/form/BaseHintForm.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminEditHint",

  components: {
    BaseHintForm,
  },

  data() {
    return { content: "" };
  },

  computed: {
    hintId(): string {
      return this.$route.params.hintId;
    },
    hint(): Hint {
      return this.$store.getters["hint/getHintById"](this.hintId);
    },
  },

  methods: {
    onContentChange(content) {
      this.content = content;
    },
    backToList() {
      this.$router.push({ name: "adminHints" });
    },
    async saveChanges() {
      const form = this.$refs.hintForm as any;
      await form.saveChanges();
    },
    async getHint() {
      await this.$store.dispatch("hint/getSingle", { id: this.hintId, force: true });
    },
  },
  async created() {
    await this.getHint();
    if (!this.hint) {
      await this.$store.dispatch("snackbar/open", {
        config: {
          type: SnackbarType.ERROR,
          message: "Wskazówka nie istnieje.",
        },
      });
      await this.$router.push({ name: "adminHints" });
    }
  },
});


import Vue, { PropType } from "vue";
import { AttemptResult } from "@prestonly/preston-common";

interface ChipProps {
  label: boolean;
  textColor: string;
  small: boolean;
  xSmall: boolean;
}

export default Vue.extend({
  name: "TrainerLabels",
  props: {
    result: {
      type: Object as PropType<AttemptResult | null>,
      required: false,
    },
    mode: {
      type: String,
      default: "",
    },
  },
  computed: {
    chipProps(): ChipProps {
      return {
        label: true,
        textColor: "white",
        small: this.$vuetify.breakpoint.smAndUp,
        xSmall: true,
      };
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { httpClient } from "@/utils";
import {
  ListMetadata,
  MediaFile,
  MediaFileAddPayload,
  MediaFileDeletePayload,
  MediaFileRenamePayload,
} from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";

interface MediaState {
  files: MediaFile[];
  filesListMetadata: ListMetadata;
}

const state = (): MediaState => ({
  files: [],
  filesListMetadata: {
    total: 0,
  },
});

const mutations = {
  setList(state: MediaState, data: MediaFile[]): void {
    state.files = data;
  },
  setListMetadata(state: MediaState, metadata: ListMetadata): void {
    state.filesListMetadata = metadata;
  },
  deleteFile(state: MediaState, key: string): void {
    state.files = state.files.filter((file) => file.key !== key);
  },
  renameFile(state: MediaState, payload: { key: string; newName: string }): void {
    const file = state.files.find((file) => file.key === payload.key);
    if (file) {
      file.name = payload.newName;
    }
  },
};

const actions = {
  async getList({ commit }: ActionContext<MediaState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/media", {
        params,
      });
      commit("setList", data.data);
      commit("setListMetadata", data.metadata);
    } catch (err) {
      console.error(err);
    }
  },

  async addFile(
    { dispatch }: ActionContext<MediaState, RootState>,
    { file, directory }: { file: File; directory?: string }
  ): Promise<void> {
    const fileBase64 = await fileToBase64(file);
    const payload: MediaFileAddPayload = {
      content: fileBase64,
      name: file.name,
      directory,
    };

    try {
      await httpClient.api.post(`/media`, payload);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Plik został wgrany poprawnie.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Wystąpił błąd podczas wgrywania pliku.",
          },
        },
        { root: true }
      );
    }
  },

  async deleteFile(
    { commit, dispatch }: ActionContext<MediaState, RootState>,
    { key }: { key: string }
  ): Promise<void> {
    try {
      const payload: MediaFileDeletePayload = {
        key,
      };
      await httpClient.api.delete(`/media`, {
        data: payload,
      });
      commit("deleteFile", key);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Plik został pomyślnie usunięty.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd podczas usuwania pliku.",
          },
        },
        { root: true }
      );
    }
  },

  async renameFile(
    { commit, dispatch }: ActionContext<MediaState, RootState>,
    { key, newName }: { key: string; newName: string }
  ): Promise<void> {
    try {
      const payload: MediaFileRenamePayload = {
        key,
        newName,
      };
      await httpClient.api.patch(`/media`, payload);
      commit("renameFile", { key, newName });
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Nazwa pliku zostałą pomyślnie zmieniona.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd podczas zmiany nazwy pliku.",
          },
        },
        { root: true }
      );
    }
  },
};

const getters = {
  getList: (state: MediaState): MediaFile[] => {
    return state.files;
  },
  getListMetadata: (state: MediaState): ListMetadata => {
    return state.filesListMetadata;
  },
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const mediaStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


export default {
  name: "TagChips",
  props: {
    tags: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};

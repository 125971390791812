
import Vue, { PropType } from "vue";
import { Course, Lesson } from "@prestonly/preston-common";
import TileWrapper from "@/components/base/TileWrapper.vue";
import TileHeaderProgress from "@/components/base/TileHeaderProgress.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TextBtn from "@/components/v2/base/TextBtn.vue";
import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "LessonTile",
  components: {
    Btn,
    TextBtn,
    Header,
    TileWrapper,
    TileHeaderProgress,
  },
  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    course: {
      type: Object as PropType<Course>,
      required: true,
    },
    userLesson: {
      type: Object,
      required: true,
    },
  },

  methods: {
    lessonAction() {
      this.$emit("btnClicked", { courseId: this.course._id, lessonId: this.lesson._id });
    },
  },
});

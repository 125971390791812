
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

import HintCard from "@/components/HintCard.vue";
import TagChips from "@/components/base/TagChips.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";
import { DialogCloseType } from "@/types/dialog";
import { LangCode } from "@prestonly/preston-common";
import LangSelector from "@/components/form/inputs/LangSelector.vue";

export default Vue.extend({
  name: "AdminHints",

  components: {
    HintCard,
    SearchTextField,
    TagChips,
    LangSelector,
  },

  data: function () {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Treść",
          align: "start",
          sortable: false,
          value: "content",
        },
        {
          text: "Pełnoekranowa",
          align: "center",
          sortable: false,
          value: "isFullScreen",
        },
        {
          text: "Tagi",
          align: "center",
          sortable: false,
          value: "tags",
        },
        {
          text: "Przypisane do",
          align: "center",
          sortable: false,
          value: "exercises",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      search: "",
      loading: false,
      sourceLang: LangCode.PL,
      targetLang: LangCode.ENG,
    };
  },

  computed: {
    ...mapGetters({
      hints: "hint/getSearchResults",
      metadata: "hint/getListMetadata",
      queryParams: "hint/getLatestQueryParams",
    }),
  },

  methods: {
    async onSourceLangChange() {
      await this.$store.dispatch("prestonState/setInterfaceLanguage", this.sourceLang);
      await this.getHints();
    },
    async onTargetLangChange() {
      await this.$store.dispatch("prestonState/setLearnLanguage", this.targetLang);
      await this.getHints();
    },
    async removeHint(hintId: string) {
      this.loading = true;
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń wskazówkę",
        },
      });
      if (type === DialogCloseType.CLOSED) {
        this.loading = false;
        return;
      }

      await this.$store.dispatch("hint/delete", { hintId });
      this.loading = false;
    },
    debouncedGetHints: _.debounce(async function (this: any): Promise<void> {
      await this.getHints();
    }, 600),
    async getHints() {
      this.loading = true;
      const filters: string[] = [];
      if (this.search) {
        filters.push(`$search:${this.search}`);
      }
      if (this.sourceLang) {
        filters.push(`sourceLang:${this.sourceLang}`);
      }
      if (this.targetLang) {
        filters.push(`targetLang:${this.targetLang}`);
      }

      await this.$store.dispatch("hint/getList", {
        filters: filters.join(";"),
        limit: this.options.itemsPerPage,
        page: this.options.page,
        withExercises: 1,
      });
      this.loading = false;
    },
  },
  created() {
    const { filters, limit, page } = this.queryParams || {};
    if (limit) {
      this.options.itemsPerPage = limit;
    }
    if (page) {
      this.options.page = page;
    }
    if (!filters) {
      return;
    }
    const filtersArr = filters.split(";");
    filtersArr.map((filter) => {
      if (filter.includes("sourceLang")) {
        this.sourceLang = filter.replace("sourceLang:", "");
      }
      if (filter.includes("targetLang")) {
        this.targetLang = filter.replace("targetLang:", "");
      }
      if (filter.includes("$search")) {
        this.search = filter.replace("$search:", "");
      }
    });
  },
});

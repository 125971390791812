import _ from "lodash";

function checkIfShuffledCorrectly(shuffled: string[], example: string) {
  if (shuffled.join(" ") === example) {
    return exerciseBlocksBuilder({ example });
  }
  return shuffled;
}

function _joinPieces(baseChunks: string[], length = 10, joiner = " ") {
  if (baseChunks.length >= length) {
    const arr: string[] = [];
    let i = 0;
    while (baseChunks.length > 0) {
      const splice = baseChunks.splice(0, i % 2 === 0 ? 1 : 2);
      arr.push(splice.join(joiner));
      i++;
    }
    baseChunks = arr;
  }
  return baseChunks;
}
export function exerciseBlocksBuilder({ example }: { example: string }): string[] {
  example = example.trim();
  let baseChunks: string[] = example.split(" ");
  baseChunks = _joinPieces(baseChunks);
  if (baseChunks.length === 1) {
    baseChunks = baseChunks[0].split("");
  }
  const shuffled = _.shuffle(baseChunks);
  return checkIfShuffledCorrectly(shuffled, example);
}

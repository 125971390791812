
import Vue from "vue";
import { camelCase, isEmpty } from "lodash";

import { capitalizeFirstLetter, exerciseTypeSelector, getLearningModeFromRoute } from "@/utils";
import {
  AttemptResult,
  Exercise,
  Hint,
  LangCode,
  LearningExerciseType,
  LearningMode,
  Lesson,
  LessonItemType,
  ProgressEventName,
  RepetitionExampleAttemptEvent,
  RepetitionExampleDisplayedEvent,
  RepetitionExampleSuccessEvent,
  RepetitionSessionFinishedEvent,
  ResultCategory,
  UserPreferences,
  Word,
} from "@prestonly/preston-common";
import { AttemptEventPayload } from "@/types/progress";

import PageTitle from "@/components/base/PageTitle.vue";
import TrainerSubheader from "@/components/trainer/TrainerSubheader.vue";
import TrainerRepetitionFlashCards from "@/components/trainer/repetition/TrainerRepetitionFlashCards.vue";
import TrainerRepetitionListening from "@/components/trainer/TrainerListening.vue";
import TrainerRepetitionSpeaking from "@/components/trainer/TrainerSpeaking.vue";
import TrainerRepetitionSpeakingV2 from "@/components/trainer/TrainerSpeakingV2.vue";
import TrainerRepetitionWriting from "@/components/trainer/TrainerWriting.vue";
import MobileTitleBar from "@/components/base/MobileTitleBar.vue";
import HintCard from "@/components/HintCard.vue";
import LessonAlert from "@/components/base/LessonAlert.vue";
import WordsAlert from "@/components/base/WordsAlert.vue";
import Header from "@/components/v2/base/Header.vue";
import TrainerTaskDescriptionAlert from "@/components/trainer/TrainerTaskDescriptionAlert.vue";
import TrainerLangSwitcher from "@/components/trainer/TrainerLangSwitcher.vue";
import HintsAlert from "@/components/base/HintsAlert.vue";
import TrainerLabels from "@/components/trainer/TrainerLabels.vue";
import TrainerHeaderButtons from "@/views/app/TrainerHeaderButtons.vue";
import UserNotesNavigationDrawer from "@/components/userNote/UserNotesNavigationDrawer.vue";

interface TrainerData {
  remainingItems: number;
  currentItem: Exercise | Hint;
  result: AttemptResult | null;
  defaultResult: AttemptResult;
  proceedPossible: boolean;
  lessonFinished: boolean;
  isLangSwapped: boolean;
}

const isCurrentItemExercise = (currentItem: Exercise | Hint): currentItem is Exercise => {
  return currentItem && "example" in currentItem;
};
const isCurrentItemHint = (currentItem: Exercise | Hint): currentItem is Hint => {
  return currentItem && "content" in currentItem;
};

export default Vue.extend({
  name: "RepetitionTrainer",
  metaInfo() {
    return {
      title: this.$t("metadata.repetitionTrainer.title").toString(),
    };
  },
  components: {
    UserNotesNavigationDrawer,
    TrainerLabels,
    TrainerRepetitionFlashCards,
    TrainerRepetitionListening,
    TrainerRepetitionWriting,
    TrainerRepetitionSpeaking,
    TrainerRepetitionSpeakingV2,
    TrainerSubheader,
    PageTitle,
    HintCard,
    MobileTitleBar,
    WordsAlert,
    LessonAlert,
    Header,
    TrainerTaskDescriptionAlert,
    HintsAlert,
    TrainerLangSwitcher,
    TrainerHeaderButtons,
  },
  data(): TrainerData {
    return {
      defaultResult: {
        isSuccess: false,
        isPreferred: false,
        answer: { text: "" },
        alternativeAnswers: [],
        userInput: "",
        htmlDiff: "",
        resultCategory: ResultCategory.ERROR,
      },
      isLangSwapped: false,
      remainingItems: 0,
      currentItem: {} as Exercise,
      result: null,
      proceedPossible: true,
      lessonFinished: false,
    };
  },
  computed: {
    showExercise(): boolean {
      return !this.lessonFinished;
    },
    showWords(): boolean {
      if (!this.currentItem || isEmpty(this.currentItem)) {
        return false;
      }
      const item = this.currentItem as Exercise;
      return !this.lessonFinished && item.words && item.words.length > 0;
    },
    showHints(): boolean {
      if (!this.currentItem || isEmpty(this.currentItem)) {
        return false;
      }
      const item = this.currentItem as Exercise;
      return !this.lessonFinished && item.hints && item.hints.length > 0;
    },
    words(): Word[] {
      return this.$store.getters["word/getWordsForLesson"](this.lessonId);
    },
    hints(): Hint[] {
      return this.$store.getters["hint/getLessonHints"](this.lessonId);
    },
    lesson(): Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
    mode(): LearningMode {
      return getLearningModeFromRoute(this.$route);
    },
    exerciseType(): LearningExerciseType {
      return exerciseTypeSelector.next({
        cefrLevel: this.lesson.level,
        mode: this.mode,
        exercise: this.currentItem as Exercise,
      });
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    itemComponent(): string {
      const mode = capitalizeFirstLetter(camelCase(this.mode));
      if (mode === "Speaking") {
        return `TrainerRepetition${mode}V2`;
      }
      return `TrainerRepetition${mode}`;
    },
    currentItemType(): LessonItemType | string {
      return this.lesson.items.find((item) => item.itemId === this.currentItem._id?.toString())?.type || "";
    },
    showLangSwitcher(): boolean {
      return isCurrentItemExercise(this.currentItem) && [LearningMode.FLASH_CARDS].includes(this.mode);
    },
  },
  methods: {
    getHintById(id: string) {
      return this.$store.getters["hint/getHintById"](id);
    },
    async forgetTemporarily() {
      await this.proceed({ forceSuccess: false, skipped: true });
    },
    forget() {
      // TODO - implement
      console.log("## Repetitions trainer: forget");
    },
    setCurrentItem(item: Hint | Exercise) {
      this.currentItem = item;
      if (this.isLangSwapped) {
        this.swapLang(false);
      }
    },
    setRemainingItems(remainingItems?: number): void {
      if (remainingItems) {
        this.remainingItems = remainingItems;
        return;
      }
    },
    baseEventData() {
      return {
        lessonId: this.lessonId,
        createdAt: new Date().toISOString(),
        payload: {
          exerciseId: this.currentItem._id || "",
          learningExerciseType: this.exerciseType,
          learningMode: this.mode,
        },
      };
    },
    async sendExampleDisplayedEvent() {
      const event: RepetitionExampleDisplayedEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.REPETITION_EXAMPLE_DISPLAYED,
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendExampleAttemptEvent({ userInput, preferredAnswer }: AttemptEventPayload) {
      const event: RepetitionExampleAttemptEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.REPETITION_EXAMPLE_ATTEMPT,
        payload: {
          ...this.baseEventData().payload,
          userInput,
          expectedResult: preferredAnswer,
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendExampleSuccessEvent(successForced = false) {
      const baseData = this.baseEventData();
      const event: RepetitionExampleSuccessEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.REPETITION_EXAMPLE_SUCCESS,
        payload: {
          ...baseData.payload,
          successForced,
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendRepetitionSessionFinished() {
      const event: RepetitionSessionFinishedEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.REPETITION_SESSION_FINISHED,
        payload: {
          learningMode: this.mode,
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    tryAgain() {
      this.result = null;
    },
    async checkAttempt(attemptEventPayload: AttemptEventPayload): Promise<void> {
      await this.sendExampleAttemptEvent(attemptEventPayload);
      let result = { ...this.defaultResult };
      if (attemptEventPayload.check) {
        result = await this.$store.dispatch("attempt/check", attemptEventPayload);
      }
      this.result = result;
    },
    swapLang(changeFlag = true) {
      if (isCurrentItemHint(this.currentItem)) {
        return;
      }
      if (changeFlag) {
        this.isLangSwapped = !this.isLangSwapped;
      }
      const { answerLang, answer, answerRecording, exampleLang, example, exampleRecording } = this.currentItem;
      this.currentItem = {
        ...this.currentItem,
        answerLang: exampleLang,
        answer: example,
        answerRecording: exampleRecording,
        exampleLang: answerLang,
        example: answer,
        exampleRecording: answerRecording,
      };
    },
    async proceed({ forceSuccess, skipped } = { forceSuccess: false, skipped: false }): Promise<void> {
      if (
        skipped ||
        forceSuccess ||
        (this.result && this.result.isSuccess) ||
        (this.currentItem._id && [LearningMode.FLASH_CARDS, LearningMode.SPEAKING].includes(this.mode))
      ) {
        await this.sendExampleSuccessEvent(forceSuccess);

        if (this.remainingItems === 1 && !forceSuccess) {
          await this.sendRepetitionSessionFinished();
          const {
            payload: { action },
          } = await this.$store.dispatch("dialog/open", {
            componentName: "RepetitionSessionFinishedDialog",
            config: {
              title: this.$t("dialogs.repetitionSessionFinished.title"),
              payload: { content: this.$t("dialogs.repetitionSessionFinished.content") },
            },
          });
          if (action === "changeMode") {
            await this.$router.push({ name: "repetitionModeSelector", params: { lessonId: this.lessonId } });
            return;
          }
          if (action === "finish") {
            await this.$router.push({ name: "exercises" });
            return;
          }
        }
      }
      const userPreferences: UserPreferences = this.$store.getters["user/preferences"];
      const { item, remainingItems } = await this.$store.dispatch("progress/getNextRepetitionItem", {
        lessonId: this.lessonId,
        mode: this.mode,
        randomOrder: userPreferences.randomizeRepetitionOrder,
      });
      if (!item) {
        this.lessonFinished = true;
        return;
      }
      this.result = null;
      this.setCurrentItem(item);
      this.setRemainingItems(remainingItems);
      await this.sendExampleDisplayedEvent();
    },
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("userNote/getListWithExercises"),
      this.$store.dispatch("lesson/getSingle", { id: this.lessonId }),
      this.$store.dispatch("word/getWordsForLesson", { lessonId: this.lessonId }),
      this.$store.dispatch("hint/getLessonHints", { lessonId: this.lessonId }),
    ]);
    await this.proceed();
  },
});

import moment from "moment";
import { ActionContext } from "vuex";

import { httpClient } from "@/utils";
import { RootState } from "@/store";
import {
  PrestonUser,
  UserMetadataAction,
  LangCode,
  UserPreferences,
  OnboardingSurveyResult,
  GiftCardVerification,
  GiftCardVerificationStatus,
} from "@prestonly/preston-common";

interface UserState {
  user: PrestonUser;
}

const defaultPreferences = (): UserPreferences => ({
  preferredLessonTime: 12,
  autoplayAnswer: true,
  lessonSoundsAllowed: true,
  appNotificationsAllowed: true,
  randomizeRepetitionOrder: true,
  listeningAndWritingActivities: {
    fullSentence: true,
    gaps: true,
    blocks: true,
  },
  speakingActivities: {
    hideTranslation: true,
  },
});

export enum SubscriptionState {
  AllNormal,
  NoSubAvailableTrial,
  NoSubNoTrial,
  TrialOverSoon,
  TrialLastDay,
  SubOverSoon,
  SubLastDay,
}

const state = (): UserState => ({
  user: {} as PrestonUser,
});

const mutations = {
  setUser(state: UserState, { user }: { user: PrestonUser }): void {
    state.user = user;
  },
};

const actions = {
  async getUser({ commit }: ActionContext<UserState, RootState>): Promise<void> {
    try {
      const { data: user } = await httpClient.api.get("/user/me");
      commit("setUser", { user });
    } catch (err) {
      console.error(err);
    }
  },

  async patchUser(
    { dispatch }: ActionContext<UserState, RootState>,
    { action, payload }: { action: UserMetadataAction; payload: Record<string, any> }
  ): Promise<boolean> {
    try {
      await httpClient.api.patch(`/user/metadata`, { action, payload });
      await dispatch("getUser");
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async patchOnboardingSurvey(_, payload: Partial<OnboardingSurveyResult>) {
    try {
      await httpClient.api.patch(`/user/onboarding-survey`, payload);
    } catch (err) {
      console.error(err);
    }
  },

  async startTrial({ dispatch }: ActionContext<UserState, RootState>): Promise<void> {
    try {
      await httpClient.api.post("/user/start-trial");
      dispatch("getUser");
    } catch (err) {
      console.error(err);
    }
  },

  async resetPassword({ dispatch }: ActionContext<UserState, RootState>): Promise<void> {
    try {
      await httpClient.api.post("/user/reset-password");
      dispatch("getUser");
    } catch (err) {
      console.error(err);
    }
  },

  async deleteAccount(): Promise<boolean> {
    try {
      await httpClient.api.post("/user/delete-account");
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async redeemGiftCard(
    { dispatch }: ActionContext<UserState, RootState>,
    giftCardCode: string
  ): Promise<GiftCardVerification> {
    try {
      const { data } = await httpClient.api.post("/coupon/redeem-gift-card", {
        giftCardCode,
      });
      if (data.status === GiftCardVerificationStatus.VERIFIED) {
        dispatch("getUser");
      }
      return data;
    } catch (err) {
      console.error(err);
      return {
        status: GiftCardVerificationStatus.INVALID,
        message: "Nieznany błąd",
      } as GiftCardVerification;
    }
  },
};

const getters = {
  getUser: (state: UserState): PrestonUser => {
    return state.user;
  },
  getTrialDaysLeft: (state: UserState): number => {
    const trialExpiresAt = state.user?.appMetadata?.trialExpiresAt || null;
    return trialExpiresAt ? moment(trialExpiresAt).endOf("day").diff(moment().endOf("day"), "days") : -1;
  },
  getSubscriptionDaysLeft: (state: UserState): ((currentLangCode: LangCode) => number) => {
    return (currentLangCode) => {
      const langAccessArray = state.user?.appMetadata?.languages || [];
      const expiresAt =
        langAccessArray.find((langAccess) => langAccess.targetLang === currentLangCode)?.expiresAt || null;
      return expiresAt ? moment(expiresAt).endOf("day").diff(moment().endOf("day"), "days") : -1;
    };
  },
  isTrialAvailable: (_, getters): boolean => {
    const trialDaysLeft = getters.getTrialDaysLeft;
    return trialDaysLeft === -1;
  },
  preferences: (state: UserState): UserPreferences => {
    return {
      ...defaultPreferences(),
      ...(state.user.userMetadata?.preferences || {}),
    };
  },
};

export const usersStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

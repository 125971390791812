import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { httpClient } from "@/utils";
import {
  ProgressMap,
  Exercise,
  Hint,
  ProgressEvent,
  CourseProgress,
  LessonAvailableToRepeatMap,
  RepetitionProgressMap,
  LearningMode,
} from "@prestonly/preston-common";

const actions = {
  async getNextLessonItem(
    _: ActionContext<unknown, RootState>,
    { lessonId, courseId }: { lessonId: string; courseId: string }
  ): Promise<Exercise | Hint | undefined> {
    try {
      const { data } = await httpClient.api.get(`/progress/${courseId}/${lessonId}/next`);
      return data;
    } catch (err) {
      console.error(err);
      return {} as Exercise;
    }
  },

  async getNextRepetitionItem(
    _: ActionContext<unknown, RootState>,
    {
      lessonId,
      mode,
      randomOrder,
    }: { lessonId: string; mode: Omit<LearningMode, LearningMode.CONTENT_PREVIEW>; randomOrder: boolean }
  ): Promise<Exercise | Hint | undefined> {
    try {
      const { data } = await httpClient.api.get(`/progress/${lessonId}/${mode}/repeat/next`, {
        params: { randomOrder: randomOrder ? "1" : "0" },
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  },

  async getProgress(): Promise<ProgressMap | undefined> {
    try {
      const { data: progress } = await httpClient.api.get(`/progress`);
      return progress;
    } catch (err) {
      console.error(err);
    }
  },

  async getCourseProgress(_: ActionContext<unknown, RootState>, courseId: string): Promise<CourseProgress | undefined> {
    try {
      const { data: progress } = await httpClient.api.get(`/progress/${courseId}`);
      return progress;
    } catch (err) {
      console.error(err);
    }
  },

  async isCourseFinished(
    _: ActionContext<unknown, RootState>,
    { courseId }: { courseId: string }
  ): Promise<CourseProgress | undefined> {
    try {
      const {
        data: { isCourseFinished },
      } = await httpClient.api.get(`/progress/${courseId}/isFinished`);
      return isCourseFinished;
    } catch (err) {
      console.error(err);
    }
  },

  async getLessonsAvailableToRepeat(
    _: ActionContext<unknown, RootState>
  ): Promise<LessonAvailableToRepeatMap | undefined> {
    try {
      const { data: progress } = await httpClient.api.get(`/progress/repetition/available-lessons`);
      return progress;
    } catch (err) {
      console.error(err);
    }
  },

  async getRepetitionProgress(
    _: ActionContext<unknown, RootState>,
    { lessonId }: { lessonId: string }
  ): Promise<RepetitionProgressMap | undefined> {
    try {
      const { data: progress } = await httpClient.api.get(`/progress/repetition/${lessonId}`);
      return progress;
    } catch (err) {
      console.error(err);
    }
  },

  async getFinishedModes(_: ActionContext<unknown, RootState>): Promise<RepetitionProgressMap | undefined> {
    try {
      const { data: finishedModesMap } = await httpClient.api.get(`/progress/repetition/finished-modes`);
      return finishedModesMap;
    } catch (err) {
      console.error(err);
    }
  },

  async saveProgress(_: ActionContext<unknown, RootState>, payload: ProgressEvent): Promise<void> {
    try {
      await httpClient.api.post(`/progress`, payload);
    } catch (err) {
      console.error(err);
    }
  },

  async getNextLessonRecommendation(
    _: ActionContext<unknown, RootState>,
    payload: { courseId?: string }
  ): Promise<void> {
    try {
      const { data: recommendation } = await httpClient.api.get(`/progress/next-lesson`, { params: payload });
      return recommendation;
    } catch (err) {
      console.error(err);
    }
  },

  async getAllProgress(): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/progress/all-progress`);
      return data;
    } catch (err) {
      console.error(err);
    }
  },

  async deleteLessonsProgress(_: ActionContext<unknown, RootState>, lessonIds: string[]): Promise<void> {
    try {
      await httpClient.api.delete(`/progress/lessons`, { data: { lessonIds } });
    } catch (err) {
      console.error(err);
    }
  },
};

export const progressStore = {
  namespaced: true,
  actions,
};

import { ActionContext } from "vuex";
import { RootState } from "../index";
import { httpClient } from "@/utils";
import { Order } from "@prestonly/preston-common";

interface OrdersState {
  ordersMap: Record<string, Order>;
}

const state = (): OrdersState => ({
  ordersMap: {},
});

const mutations = {
  setMap(state: OrdersState, orders: Order[]): void {
    state.ordersMap = orders.reduce((acc, order) => {
      acc[order._id] = order;
      return acc;
    }, {});
  },
  updateInvoiceRequested(state: OrdersState, orderId: string): void {
    const order = state.ordersMap[orderId];
    order.invoiceRequested = true;
    state.ordersMap = { ...state.ordersMap };
  },
};

const actions = {
  async getList({ commit }: ActionContext<OrdersState, RootState>): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/order");
      commit("setMap", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async requestInvoice(
    { commit }: ActionContext<OrdersState, RootState>,
    orderId: Record<string, any> = {}
  ): Promise<boolean> {
    try {
      await httpClient.api.post(`/invoice/request-invoice/${orderId}`);
      commit("updateInvoiceRequested", orderId);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
};

const getters = {
  getList: (state: OrdersState): Order[] => {
    return Object.values(state.ordersMap);
  },
};

export const ordersStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


import Vue, { PropType } from "vue";
import { camelCase } from "lodash";
import { TranslateResult } from "vue-i18n";

import { LearningExerciseType } from "@prestonly/preston-common";
import SimpleAlert from "@/components/v2/base/SimpleAlert.vue";

export default Vue.extend({
  name: "TrainerTaskDescriptionAlert",
  components: { SimpleAlert },
  props: {
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.FLASH_CARD,
    },
  },
  computed: {
    showAlert(): boolean {
      return !localStorage.getItem(`contentOfTheExerciseClosed.${this.camelCaseType}`);
    },
    camelCaseType(): string {
      return `${camelCase(this.exerciseType)}`;
    },
  },
  methods: {
    onAlertDismiss(): void {
      localStorage.setItem(`contentOfTheExerciseClosed.${this.camelCaseType}`, "1");
    },
    getContentOfTheExercise(): string | TranslateResult {
      if (this.exerciseType === LearningExerciseType.UNKNOWN) {
        return "";
      }
      return this.$t(`contentOfTheExercises.${this.camelCaseType}`);
    },
  },
});


import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import CompanyAdminUsersAccesses from "@/components/companyAdmin/CompanyAdminUsersAccesses.vue";

import { Company, CompanyAccess, CompanyUser, InvitationStatus, PrestonUser } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";
import { getLanguageAttributeByLangCode } from "@/utils/languages";
import { formatDate, formatDatetime } from "@/utils/dateFormatter";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "CompanyAdminUsers",

  components: {
    CompanyAdminUsersAccesses,
    Header,
  },

  data: function () {
    return {
      selected: [],
      options: {
        page: 1,
        itemsPerPage: 50,
      },
      headers: [
        {
          text: "E-mail użytkownika",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Dostępy",
          align: "start",
          sortable: false,
          value: "accessesUsed",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      loading: false,
    };
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    company(): Company {
      return this.$store.getters["company/getCompany"];
    },
    companyUsers(): CompanyUser[] {
      return this.$store.getters["company/getCompanyUsers"];
    },
    invitedCompanyUsers(): CompanyUser[] {
      return this.companyUsers.filter((cu) => cu.invitationStatus === InvitationStatus.PENDING);
    },
    companyAccess(): CompanyAccess {
      return this.$store.getters["company/getCompanyAccess"];
    },
  },

  methods: {
    formatDatetime,
    getLanguageAttributeByLangCode,
    formatDate,
    isCompanyAdmin(userId: string): boolean {
      return this.company.adminIds.includes(userId);
    },
    async removeUser(userEmailToDetach: string) {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń użytkownika z organizacji",
          payload: {
            content: this.$t("company.adminInterface.removeUserDialogContent"),
          },
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      const { detached } = await this.$store.dispatch("company/detachUserFromCompany", {
        userEmailToDetach,
        companyId: this.user.company?.companyId,
      });
      if (detached) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("company.adminInterface.userDetached"),
            },
          },
          { root: true }
        );
        return;
      }
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            closeTimeout: 20000,
            type: SnackbarType.ERROR,
            message: this.$t("company.adminInterface.userNotDetached"),
          },
        },
        { root: true }
      );
    },
    async getCompanyAccess() {
      this.loading = true;
      await this.$store.dispatch("company/getCompanyAccess", {
        companyId: this.user.company?.companyId || "",
      });
      this.loading = false;
    },
    async getCompanyUsers() {
      this.loading = true;
      await this.$store.dispatch("company/getCompanyUsers", {
        companyId: this.user.company?.companyId || "",
      });
      this.loading = false;
    },
    async getCompany() {
      this.loading = true;
      await this.$store.dispatch("company/getCompany", {
        companyId: this.user.company?.companyId || "",
      });
      this.loading = false;
    },
  },
  async mounted() {
    await Promise.all([this.getCompany(), this.getCompanyUsers(), this.getCompanyAccess()]);
  },
});


import _ from "lodash";
import Vue, { PropType } from "vue";
import {
  Exercise,
  Hint,
  LangCode,
  parseTags,
  Recording,
  RecordingType,
  ReportedBug,
  Word,
} from "@prestonly/preston-common";

import TagsField from "@/components/form/inputs/TagsField.vue";
import SwitchField from "@/components/form/inputs/SwitchField.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import TagSearchTooltip from "@/components/TagSearchTooltip.vue";
import HintCard from "@/components/HintCard.vue";
import AlternativeAnswersTable from "@/components/base/AlternativeAnswersTable.vue";
import LangSelector from "@/components/form/inputs/LangSelector.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import ReportedBugsTable from "@/components/base/ReportedBugsTable.vue";

import { validator } from "@/utils/validators";
import { stripHtml } from "@/utils/stripHtml";
import { DialogCloseType } from "@/types/dialog";
import { capitalizeFirstLetter } from "@/utils/index";

declare interface ExerciseData {
  dto: Exercise;
  [key: string]: any;
}

interface SelectedTextFragment {
  offset: number;
  length: number;
}

export default Vue.extend({
  name: "BaseExerciseForm",

  components: {
    TagsField,
    SwitchField,
    TextField,
    TextareaField,
    AudioButton,
    TagSearchTooltip,
    HintCard,
    AlternativeAnswersTable,
    LangSelector,
    ReportedBugsTable,
  },

  data(): ExerciseData {
    return {
      valid: false,
      dialog: false,

      words: [],
      wordsInputLoading: false,
      wordsInputSearch: "",

      hintsInputLoading: false,
      hintsInputSearch: "",

      alternativeAnswer: "",

      selectedText: null,
      highlighedGapRange: null,

      gapsCleared: false,

      // if we want to add multiple gaps
      // per example in the future this variable
      // will controll which one is visible/editable
      // for now it's just always 0
      selectedGap: 0,

      dto: {
        tags: [],
        needAttention: false,
        hints: [],
        words: [],
        answerLang: LangCode.ENG,
        answer: "",
        answerRecording: null,
        active: true,
        alternativeAnswers: [],
        exampleLang: LangCode.PL,
        example: "",
        exampleRecording: null,
        gaps: [],
      },
    };
  },
  mixins: [validator],

  props: {
    exercise: { type: Object as PropType<Partial<Exercise>>, required: false },
  },

  watch: {
    hintsInputSearch(val) {
      val && this.queryHints();
    },
    wordsInputSearch(val) {
      val && this.debouncedQueryWords();
    },
    exercise(exercise: Exercise) {
      if (exercise) {
        this.dto.tags = exercise.tags || [];
        this.dto.needAttention = exercise.needAttention;
        this.dto.hints = exercise.hints || [];
        this.dto.words = exercise.words || [];
        this.dto.answerLang = exercise.answerLang;
        this.dto.answer = exercise.answer;
        this.dto.answerRecording = exercise.answerRecording;
        this.dto.active = "active" in exercise ? exercise.active : false;
        this.dto.alternativeAnswers = exercise.alternativeAnswers || [];
        this.dto.exampleLang = exercise.exampleLang;
        this.dto.example = exercise.example;
        this.dto.exampleRecording = exercise.exampleRecording;
        this.dto.gaps = exercise.gaps || [];
        this.words = this.dto.words;

        // reportedBugs can be undefined so we need to use Vue.set to make it reactive
        // since Vue cannot detect property addition or deletion
        Vue.set(this.dto, "reportedBugs", exercise.reportedBugs);

        const bookTags = parseTags(this.dto.tags);
        if (bookTags && bookTags.bookName) {
          const chapterTag = bookTags.tag.split("_").slice(0, 2).join("_");
          this.hintsInputSearch = chapterTag;
          this.wordsInputSearch = chapterTag;
        }
      }
    },
  },

  computed: {
    exerciseId(): string {
      return this.$route.params.exerciseId || "";
    },
    hintsList(): Hint[] {
      return this.$store.getters["hint/getSearchResults"];
    },
    wordsList(): Word[] {
      return this.$store.getters["word/getSearchResults"];
    },
    answerWithGaps(): string {
      if (!this.dto.gaps.length) {
        return this.dto.answer;
      }
      const answerWithGaps = this.dto.gaps[this.selectedGap].ranges.reduce((answer, range) => {
        if (!this.isRangeValid(range)) {
          return answer;
        }
        return answer.slice(0, range.start) + "_".repeat(range.end - range.start) + answer.slice(range.end);
      }, this.dto.answer);
      if (this.highlighedGapRange === null) {
        return answerWithGaps;
      }
      const highlightedRange = this.dto.gaps[this.selectedGap].ranges[this.highlighedGapRange];

      const { start, end } = highlightedRange;
      const highlightClasses = this.isRangeValid(highlightedRange) ? "gap-highlighted" : "gap-highlighted error";
      return (
        answerWithGaps.slice(0, start) +
        `<span class="${highlightClasses}">${"_".repeat(end - start)}</span>` +
        answerWithGaps.slice(end)
      );
    },
    hasReportedBugs(): boolean {
      return Boolean(this.dto.reportedBugs && this.dto.reportedBugs.length > 0);
    },
  },

  methods: {
    onWordsAutocompleteFocus(): void {
      this.words = this.dto.words;
    },
    onWordsAutocompleteChange(words: Word[]): void {
      if (words.length > 0) {
        const uniqMap = (this.dto.words as unknown as Word[]).concat(words).reduce((acc, word) => {
          if (!acc[word._id]) {
            acc[word._id] = word;
          }
          return acc;
        }, {});
        this.dto.words = Object.values(uniqMap) as unknown as string[];
      }
    },
    getBookTag(tags: string[]) {
      const bookTags = parseTags(tags);
      return bookTags.tag;
    },
    highlightWordInAnswer() {
      let sentence = this.dto.answer;
      if (!sentence || this.dto.words.length === 0) {
        return sentence;
      }
      for (const word of this.dto.words as unknown as { answer: string }[]) {
        sentence = sentence
          .replaceAll(word.answer, `<b>${word.answer}</b>`)
          .replaceAll(capitalizeFirstLetter(word.answer), `<b>${capitalizeFirstLetter(word.answer)}</b>`);
      }
      return `${sentence} (${this.dto.example})`;
    },
    getTextSelected(): SelectedTextFragment | null {
      const selection = window.getSelection();
      if (!selection) {
        return null;
      }
      const range = selection.getRangeAt(0);
      let offset = range.startOffset;
      let length = range.toString().length;
      while (this.dto.answer[offset] === " ") {
        offset++;
        length--;
      }
      while (length >= 0 && this.dto.answer[offset + length - 1] === " ") {
        length--;
      }
      if (length <= 0) {
        return null;
      }
      return {
        offset,
        length,
      };
    },
    onTextSelected() {
      this.selectedText = this.getTextSelected();
    },
    addRange() {
      if (!this.selectedText) {
        return;
      }
      const gap = this.dto.gaps[this.selectedGap] || { ranges: [] };

      const ranges = [
        ...gap.ranges,
        {
          start: this.selectedText.offset,
          end: this.selectedText.offset + this.selectedText.length,
        },
      ];

      gap.ranges = this.calculateRanges(ranges);
      this.dto.gaps = [gap];
      this.selectedText = null;
    },
    calculateRanges(ranges: Exercise["gaps"][0]["ranges"]) {
      const rangesSorted = _.orderBy(ranges, ["start", "end"]);
      // connect overlapping ranges
      return rangesSorted.reduce((ranges, nextRange) => {
        const previousRange = ranges.pop();
        if (!previousRange) {
          return [nextRange];
        }
        if (previousRange.end + 1 >= nextRange.start) {
          return [...ranges, { start: previousRange.start, end: nextRange.end }];
        }
        return [...ranges, previousRange, nextRange];
      }, [] as Exercise["gaps"][0]["ranges"]);
    },
    onRangeUpdated() {
      const gap = this.dto.gaps[this.selectedGap];
      gap.ranges = gap.ranges.map(({ start, end }) => {
        return {
          start: Number(start),
          end: Number(end),
        };
      });
      gap.ranges = this.calculateRanges(gap.ranges);
    },
    isRangeValid(range) {
      return (
        range.start < range.end &&
        range.end <= this.dto.answer.length &&
        range.start >= 0 &&
        this.dto.answer[range.start] !== " " &&
        this.dto.answer[range.end - 1] !== " "
      );
    },
    removeGap(index: number) {
      this.highlighedGapRange = null;
      const ranges = this.dto.gaps[this.selectedGap].ranges.filter((_, idx) => idx !== index);
      if (ranges.length === 0) {
        this.dto.gaps = [];
        return;
      }
      this.dto.gaps[this.selectedGap].ranges = ranges;
    },
    removeWord(wordId: string) {
      this.dto.words = this.dto.words.filter((word) => (word as unknown as Word)._id !== wordId);
    },
    highlightGap(index: number | null) {
      this.highlighedGapRange = index;
    },
    clearGaps() {
      this.gapsCleared = true;
    },
    tagsChanged(tags: string[]) {
      this.dto.tags = tags;
    },
    removeHint(hintId: string) {
      this.dto.hints = this.dto.hints.filter((hint) => (hint as unknown as Hint)._id?.toString() !== hintId);
    },
    getItemText(item: Hint) {
      const bookContent = parseTags(item.tags);
      const content = stripHtml(item.content);
      return `
      ${bookContent.tag.toUpperCase()} -
      ${content.substring(0, 25)}...`;
    },
    debouncedQueryWords: _.debounce(async function (this: any): Promise<void> {
      await this.queryWords();
    }, 500),
    async queryWords() {
      this.wordsInputLoading = true;
      const filters: string[] = [`answerLang:${this.dto.answerLang}`];
      if (this.wordsInputSearch) {
        filters.push(`$search:${this.wordsInputSearch}`);
      }

      await this.$store.dispatch("word/getList", {
        filters: filters.join(";"),
      });
      this.wordsInputLoading = false;
    },
    async queryHints() {
      this.hintsInputLoading = true;
      const filters: string[] = [`sourceLang:${this.dto.exampleLang}`, `targetLang:${this.dto.answerLang}`];
      if (this.hintsInputSearch) {
        filters.push(`$search:${this.hintsInputSearch}`);
      }
      await this.$store.dispatch("hint/getList", {
        filters: filters.join(";"),
      });
      this.hintsInputLoading = false;
    },

    fileToBase64(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    },
    async removeRecording(recordingType: string) {
      if (this.exerciseId) {
        const { type } = await this.$store.dispatch("dialog/open", {
          componentName: "DeleteConfirmationDialog",
          config: {
            title: "Usuń nagranie",
          },
        });
        if (type === DialogCloseType.CLOSED) {
          return;
        }

        await this.saveChanges();
        await this.$store.dispatch("exercise/removeRecording", {
          exerciseId: this.exerciseId,
          recordingId: this.dto[`${recordingType}Recording`],
        });
        this.dto[`${recordingType}Recording`] = null;
      }
    },
    async addRecording(addedFile: File, type: RecordingType) {
      if (this.exerciseId) {
        const [tagWithBookorder] =
          this.dto.tags.filter((tag: string) => {
            return tag.includes("bookorder");
          }) || [];
        const lang = this.dto[`${type}Lang`];
        const name = [addedFile.name, lang, tagWithBookorder].filter(Boolean).join("_");
        const recording: Recording = {
          lang,
          content: "",
          name,
        };
        recording.content = await this.fileToBase64(addedFile);
        await this.saveChanges();
        await this.$store.dispatch("exercise/saveRecording", {
          exerciseId: this.exerciseId,
          recording,
          type,
        });
      }
    },
    getCurrentState() {
      return Object.entries(this.dto).reduce((acc, [key, value]) => {
        if (key === "hints" || key === "words") {
          acc[key] = (value as unknown as any).map((v) => v._id);
          return acc;
        }
        acc[key] = value;
        return acc;
      }, {});
    },
    onBugApprove(bugToDelete: ReportedBug) {
      // bugToDelete is a reference to the object in dto.reportedBugs
      // so no need to look for it in the array
      bugToDelete.status = "APPROVED";
      this.dto.alternativeAnswers.push({
        text: bugToDelete.suggestedAnswer,
        approved: false,
        comment: "",
        hidden: true,
      });
    },
    onBugDelete(bugToDelete: ReportedBug) {
      if (!this.dto.reportedBugs) {
        return;
      }
      this.dto.reportedBugs = this.dto.reportedBugs.filter((bug) => bug !== bugToDelete);
    },
    async saveChanges() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      const exercise = this.getCurrentState();
      if (this.exerciseId) {
        await this.$store.dispatch("exercise/update", {
          exercise,
          exerciseId: this.exerciseId,
        });
        return;
      }
      return this.$store.dispatch("exercise/create", {
        exercise,
      });
    },
  },
});

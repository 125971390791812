
import Vue from "vue";
import { camelCase, isEmpty } from "lodash";

import { capitalizeFirstLetter, exerciseTypeSelector, getLearningModeFromRoute } from "@/utils";
import {
  AttemptResult,
  Course,
  ExampleAttemptEvent,
  ExampleDisplayedEvent,
  ExampleSuccessEvent,
  Exercise,
  Hint,
  LangCode,
  LearningExerciseType,
  LearningMode,
  Lesson,
  LessonFinishedEvent,
  LessonItemType,
  ProgressEventName,
  ResultCategory,
  Word,
} from "@prestonly/preston-common";
import { AttemptEventPayload } from "@/types/progress";
import { DialogCloseType } from "@/types/dialog";
// import { startTour } from "@/utils/startTour";

import TrainerListening from "@/components/trainer/TrainerListening.vue";
import TrainerWriting from "@/components/trainer/TrainerWriting.vue";
import TrainerSpeaking from "@/components/trainer/TrainerSpeaking.vue";
import TrainerSpeakingV2 from "@/components/trainer/TrainerSpeakingV2.vue";
import TrainerSubheader from "@/components/trainer/TrainerSubheader.vue";
import TrainerContentPreview from "@/components/trainer/TrainerContentPreview.vue";
import TrainerTaskDescriptionAlert from "@/components/trainer/TrainerTaskDescriptionAlert.vue";
import MobileTitleBar from "@/components/base/MobileTitleBar.vue";
import HintCard from "@/components/HintCard.vue";
import WordsAlert from "@/components/base/WordsAlert.vue";
import LessonAlert from "@/components/base/LessonAlert.vue";
import Header from "@/components/v2/base/Header.vue";
import Btn from "@/components/v2/base/Btn.vue";
import HintsAlert from "@/components/base/HintsAlert.vue";
import TrainerLangSwitcher from "@/components/trainer/TrainerLangSwitcher.vue";
import TrainerLabels from "@/components/trainer/TrainerLabels.vue";
import BugReportBtn from "@/components/trainer/BugReportBtn.vue";
import FavouriteSentenceBtn from "@/components/trainer/FavouriteSentenceBtn.vue";
import TrainerHeaderButtons from "@/views/app/TrainerHeaderButtons.vue";
import UserNotesList from "@/components/userNote/UserNotesList.vue";
import UserNotesNavigationDrawer from "@/components/userNote/UserNotesNavigationDrawer.vue";

interface TrainerData {
  loading: boolean;
  isFullscreenHintVisible: boolean;
  remainingItems: number;
  currentIndex: number;
  currentItem: Exercise | Hint;
  result: AttemptResult | null;
  defaultResult: AttemptResult;
  proceedPossible: boolean;
  lessonFinished: boolean;
  isLangSwapped: boolean;
}
const isCurrentItemExercise = (currentItem: Exercise | Hint): currentItem is Exercise => {
  return currentItem && "example" in currentItem;
};
const isCurrentItemHint = (currentItem: Exercise | Hint): currentItem is Hint => {
  return currentItem && "content" in currentItem;
};

export default Vue.extend({
  name: "Trainer",
  metaInfo() {
    return {
      title: this.$t("metadata.trainer.title").toString(),
    };
  },
  components: {
    UserNotesNavigationDrawer,
    UserNotesList,
    TrainerHeaderButtons,
    TrainerLabels,
    TrainerContentPreview,
    TrainerListening,
    TrainerWriting,
    TrainerSpeaking,
    TrainerSpeakingV2,
    TrainerSubheader,
    TrainerTaskDescriptionAlert,
    Btn,
    Header,
    MobileTitleBar,
    HintCard,
    WordsAlert,
    HintsAlert,
    LessonAlert,
    TrainerLangSwitcher,
    BugReportBtn,
    FavouriteSentenceBtn,
  },
  data(): TrainerData {
    return {
      loading: true,
      isFullscreenHintVisible: false,
      defaultResult: {
        isSuccess: false,
        isPreferred: false,
        answer: { text: "" },
        alternativeAnswers: [],
        userInput: "",
        htmlDiff: "",
        resultCategory: ResultCategory.ERROR,
      },
      remainingItems: 0,
      currentIndex: 0,
      currentItem: {} as Exercise,
      result: null,
      proceedPossible: true,
      lessonFinished: false,
      isLangSwapped: false,
    };
  },
  computed: {
    showExercise(): boolean {
      return !this.lessonFinished && !this.isFullscreenHintVisible && !this.loading;
    },
    showWords(): boolean {
      if (!this.currentItem || isEmpty(this.currentItem)) {
        return false;
      }
      const item = this.currentItem as Exercise;
      return !this.lessonFinished && item.words && item.words.length > 0;
    },
    showHints(): boolean {
      if (this.lessonFinished || !this.currentItem || isEmpty(this.currentItem)) {
        return false;
      }
      const item = this.currentItem as Exercise;
      return !this.lessonFinished && item.hints && item.hints.length > 0;
    },
    words(): Word[] {
      return this.$store.getters["word/getWordsForLesson"](this.lessonId);
    },
    hints(): Hint[] {
      return this.$store.getters["hint/getLessonHints"](this.lessonId);
    },
    course(): Course {
      return this.$store.getters["course/getCourseById"](this.courseId);
    },
    courseId(): string {
      return this.$route.params.courseId;
    },
    lesson(): Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
    mode(): LearningMode {
      return getLearningModeFromRoute(this.$route);
    },
    exerciseType(): LearningExerciseType {
      return exerciseTypeSelector.next({
        cefrLevel: this.lesson.level,
        mode: this.mode,
        exercise: this.currentItem as Exercise,
      });
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    itemComponent(): string {
      const mode = capitalizeFirstLetter(camelCase(this.mode));
      if (mode === "Speaking") {
        return `Trainer${mode}V2`;
      }
      return `Trainer${mode}`;
    },

    currentItemType(): LessonItemType | string {
      return this.lesson.items.find((item) => item.itemId === this.currentItem?._id?.toString())?.type || "EXERCISE";
    },
    showLangSwitcher(): boolean {
      return isCurrentItemExercise(this.currentItem) && [LearningMode.CONTENT_PREVIEW].includes(this.mode);
    },
  },
  methods: {
    setCurrentItem(item: Hint | Exercise) {
      this.currentItem = item;
      if (this.isLangSwapped) {
        this.swapLang(false);
      }
    },
    setCurrentIndex(index?: number): void {
      if (index) {
        this.currentIndex = index;
        return;
      }
      if (!this.currentItem?._id) {
        this.currentIndex = 0;
      }
      const indexFound = this.lesson.items.findIndex((item) => item.itemId === this.currentItem._id?.toString());
      this.currentIndex = indexFound === -1 ? 1 : indexFound + 1;
    },
    baseEventData() {
      return {
        lessonId: this.lessonId,
        courseId: this.courseId,
        createdAt: new Date().toISOString(),
        payload: {
          exerciseOrder: this.currentIndex || 0,
          exerciseId: this.currentItem._id || "",
          learningExerciseType: this.exerciseType,
          learningMode: this.mode,
        },
      };
    },
    async sendExampleDisplayedEvent() {
      const event: ExampleDisplayedEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.LESSON_EXAMPLE_DISPLAYED,
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendExampleAttemptEvent({ userInput, preferredAnswer }: AttemptEventPayload) {
      const event: ExampleAttemptEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.LESSON_EXAMPLE_ATTEMPT,
        payload: {
          ...this.baseEventData().payload,
          userInput,
          expectedResult: preferredAnswer,
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendExampleSuccessEvent(successForced = false) {
      const baseData = this.baseEventData();
      const event: ExampleSuccessEvent = {
        ...this.baseEventData(),
        name: ProgressEventName.LESSON_EXAMPLE_SUCCESS,
        payload: {
          ...baseData.payload,
          successForced,
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    async sendLessonFinishedEvent() {
      const event: LessonFinishedEvent = {
        lessonId: this.lessonId,
        courseId: this.courseId,
        createdAt: new Date().toISOString(),
        name: ProgressEventName.LESSON_FINISHED,
        payload: {},
      };
      await this.$store.dispatch("progress/saveProgress", event);
    },
    tryAgain() {
      this.result = null;
    },
    async checkAttempt(attemptEventPayload: AttemptEventPayload): Promise<void> {
      await this.sendExampleAttemptEvent(attemptEventPayload);
      let result = { ...this.defaultResult };
      if (attemptEventPayload.check) {
        result = await this.$store.dispatch("attempt/check", attemptEventPayload);
      }
      this.result = result;
    },
    isLastItemInLesson() {
      return this.remainingItems === 1;
    },
    async isCourseFinished() {
      return this.$store.dispatch("progress/isCourseFinished", {
        courseId: this.courseId,
      });
    },
    async finishCourse() {
      const {
        type,
        payload: { action },
      } = await this.$store.dispatch("dialog/open", {
        componentName: "CourseFinishedDialog",
        config: {
          title: this.$t("dialogs.courseFinished.title"),
          payload: { content: this.$t("dialogs.courseFinished.content") },
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      if (action === "goToRepetition") {
        await this.$router.push({ name: "exercises" });
        return;
      }
      if (action === "backToCourses") {
        await this.$router.push({ name: "courses" });
      }
    },
    async finishLesson() {
      const {
        type,
        payload: { action },
      } = await this.$store.dispatch("dialog/open", {
        componentName: "LessonFinishedDialog",
        config: {
          title: this.$t("dialogs.lessonFinished.title"),
          payload: { content: this.$t("dialogs.lessonFinished.content") },
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      if (action === "goToRepetition") {
        await this.$router.push({ name: "exercises" });
        return;
      }
      if (action === "backToLesson") {
        await this.$router.push({
          name: "courseDetails",
          params: { courseId: this.courseId },
        });
      }
    },
    async displayNextItem() {
      const { item, remainingItems } = await this.$store.dispatch("progress/getNextLessonItem", {
        lessonId: this.lessonId,
        courseId: this.courseId,
      });
      if (!item) {
        this.lessonFinished = true;
        return;
      }
      this.result = null;
      this.remainingItems = remainingItems;
      this.setCurrentItem(item);
      this.setCurrentIndex();
      await this.sendExampleDisplayedEvent();
      this.showFullscreenHint();
      this.startTour();
    },
    async proceed({ forceSuccess } = { forceSuccess: false }): Promise<void> {
      if (
        forceSuccess ||
        (this.result && this.result.isSuccess) ||
        (this.currentItem._id && this.mode === LearningMode.SPEAKING)
      ) {
        await this.sendExampleSuccessEvent(forceSuccess);
      }
      await this.isCourseFinished();
      if (this.isLastItemInLesson()) {
        await this.sendLessonFinishedEvent();
        const courseFinished = await this.isCourseFinished();
        if (courseFinished) {
          await this.finishCourse();
          return;
        }
        await this.finishLesson();
        return;
      }
      await this.displayNextItem();
    },
    showFullscreenHint() {
      if (!this.currentItem) {
        this.isFullscreenHintVisible = false;
        return;
      }
      this.isFullscreenHintVisible = this.hints.some(
        (hint) => hint.isFullScreen && (this.currentItem as Exercise).hints.includes(hint._id)
      );
    },
    closeFullscreenHint() {
      this.isFullscreenHintVisible = false;
      this.startTour();
    },
    startTour() {
      if (!this.isFullscreenHintVisible) {
        // this.$nextTick(() => {
        //   startTour({ selectedTour: this.$route.meta?.["tour"], onDemand: true });
        // });
      }
    },
    swapLang(changeFlag = true) {
      if (isCurrentItemHint(this.currentItem)) {
        return;
      }
      if (changeFlag) {
        this.isLangSwapped = !this.isLangSwapped;
      }
      const { answerLang, answer, answerRecording, exampleLang, example, exampleRecording } = this.currentItem;
      this.currentItem = {
        ...this.currentItem,
        answerLang: exampleLang,
        answer: example,
        answerRecording: exampleRecording,
        exampleLang: answerLang,
        example: answer,
        exampleRecording: answerRecording,
      };
    },
  },
  async created() {
    this.loading = true;
    this.proceedPossible = false;
    await Promise.all([
      this.$store.dispatch("userNote/getListWithExercises"),
      this.$store.dispatch("course/getSingleAvailable", this.courseId),
      this.$store.dispatch("lesson/getCourseAvailableLessons", this.courseId),
      this.$store.dispatch("word/getWordsForLesson", { lessonId: this.lessonId }),
      this.$store.dispatch("hint/getLessonHints", { lessonId: this.lessonId }),
    ]);
    if (this.mode !== LearningMode.CONTENT_PREVIEW) {
      await this.proceed();
    }
    this.loading = false;
  },
});

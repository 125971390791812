
import Vue from "vue";
import { UserNote } from "@prestonly/preston-common";

import { formatDatetime } from "@/utils/dateFormatter";

import Card from "@/components/v2/base/Card.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import FavouriteSentenceBtn from "@/components/trainer/FavouriteSentenceBtn.vue";
import CheckboxField from "@/components/form/inputs/CheckboxField.vue";
import Alert from "@/components/v2/base/Alert.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import EndListText from "@/components/base/EndListText.vue";
import AddNoteBtn from "@/components/trainer/AddNoteBtn.vue";
import { SnackbarType } from "@/types/snackbar";
import SelectField from "@/components/form/inputs/SelectField.vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  name: "UserNotesList",
  components: {
    SelectField,
    AddNoteBtn,
    EndListText,
    TextField,
    CheckboxField,
    FavouriteSentenceBtn,
    AudioButton,
    Card,
    Alert,
    Btn,
  },

  data(): any {
    return {
      sortOptions: [
        { text: this.$t("sortOptions.dateAsc"), value: "updatedAt:asc" },
        { text: this.$t("sortOptions.dateDesc"), value: "updatedAt:desc" },
      ],
      selectedSort: 1,
      internalLoading: false,
      showFavouritesOnly: false,
      editedText: "",
      editionMode: "",
      selectedNoteId: "",
      search: "",
    };
  },

  computed: {
    lessonId(): string {
      return this.$store.getters["userNote/getLessonId"];
    },
    showLoader(): boolean {
      return this.internalLoading;
    },
    userNotes(): UserNote[] {
      return this.$store.getters["userNote/getUserNotes"];
    },
    lessonsWithNotes(): { text: string; value: string }[] {
      return this.$store.getters["userNote/getLessonsWithNotes"].map((l) => {
        return {
          text: l.name,
          value: l.lessonId,
        };
      });
    },
    filteredNotes(): UserNote[] {
      let filtered = this.userNotes;
      if (this.showFavouritesOnly) {
        filtered = filtered.filter((note) => note.isFavourite);
      }

      if (this.search) {
        filtered = filtered.filter((note) => {
          const stringified = JSON.stringify(note).toLowerCase();
          return stringified.includes(this.search);
        });
      }

      (filtered as any[]).sort((a, b) => {
        const aCreatedAt = new Date(a.createdAt) as any;
        const bCreatedAt = new Date(b.createdAt) as any;
        return bCreatedAt - aCreatedAt;
      });

      return filtered;
    },
  },

  props: {
    showImage: {
      type: Boolean,
      default: true,
    },
    inDrawer: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapMutations({
      setLessonId: "userNote/setLessonId",
    }),
    formatDatetime,
    resetFilters() {
      this.search = "";
      this.setLessonId("");
      this.showFavouritesOnly = false;
    },
    async removeUserNote() {
      this.internalLoading = true;
      await this.$store.dispatch("userNote/removeNote", this.selectedNoteId);
      this.selectedNoteId = "";
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            closeTimeout: 3000,
            type: SnackbarType.SUCCESS,
            title: this.$t("notesPage.noteRemovedCorrectly"),
            message: "",
          },
        },
        { root: true }
      );

      if (this.userNotes.length === 0 && this.lessonId) {
        this.setLessonId("");
      }
      await this.refreshData();
      this.$nextTick(() => {
        this.internalLoading = false;
      });
    },
    async getUserNotes() {
      this.internalLoading = true;
      await this.$store.dispatch("userNote/getListWithExercises");
      this.internalLoading = false;
    },
    async getLessonsWithNotes() {
      if (this.inDrawer) {
        return;
      }
      this.internalLoading = true;
      await this.$store.dispatch("userNote/getLessonsWithNotes");
      this.internalLoading = false;
    },
    async refreshData() {
      await Promise.all([this.getUserNotes(), this.getLessonsWithNotes()]);
    },
  },

  async created() {
    await this.refreshData();
  },
});

import { ActionContext } from "vuex";
import { RootState } from "../index";
import { BaseListFilters, Hint, ListMetadata } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { SnackbarType } from "@/types/snackbar";

interface HintsState {
  lessonHintsMap: Record<string, Hint[]>;
  hintsMap: Record<string, Hint>;
  searchResults: Record<string, Hint>;
  hintListMetadata: ListMetadata;
  latestQueryParams: BaseListFilters;
}

const state = (): HintsState => ({
  hintListMetadata: {
    total: 0,
  },
  hintsMap: {},
  lessonHintsMap: {},
  searchResults: {},
  latestQueryParams: {},
});

const mutations = {
  delete(state: HintsState, hintId: string): void {
    delete state.hintsMap[hintId];
    delete state.searchResults[hintId];
    state.hintsMap = { ...state.hintsMap };
    state.searchResults = { ...state.searchResults };
  },
  setListMetadata(state: HintsState, metadata: ListMetadata): void {
    state.hintListMetadata = metadata;
  },
  setLessonHints(state: HintsState, { lessonId, hints }: { lessonId: string; hints: Hint[] }): void {
    state.lessonHintsMap[lessonId] = hints;
    state.lessonHintsMap = { ...state.lessonHintsMap };
  },
  setList(state: HintsState, hints: Hint[]): void {
    for (const hint of hints) {
      const id = hint._id as unknown as string;
      state.hintsMap[id] = hint;
    }
    state.hintsMap = { ...state.hintsMap };
  },
  setLatestQueryParams(state: HintsState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  searchResults(state: HintsState, hints: Hint[]): void {
    state.searchResults = {};
    for (const hint of hints) {
      const id = hint._id as unknown as string;
      state.searchResults[id] = hint;
    }
    state.searchResults = { ...state.searchResults };
  },
};

const actions = {
  async getList({ commit }: ActionContext<HintsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/hint", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getLessonHints(
    { commit, state }: ActionContext<HintsState, RootState>,
    { lessonId }: { lessonId: string }
  ): Promise<void> {
    try {
      if (state.lessonHintsMap[lessonId]) {
        return;
      }
      const { data } = await httpClient.api.get(`/lesson/${lessonId}/hints`, {
        params: {
          activeOnly: 1,
        },
      });
      commit("setList", data.data);
      commit("setLessonHints", { lessonId, hints: data.data });
    } catch (err) {
      console.error(err);
    }
  },

  async getSingle(
    { commit, state }: ActionContext<HintsState, RootState>,
    { id, force }: { id: string; force: boolean }
  ): Promise<void> {
    try {
      if (state.hintsMap[id] && !force) {
        return;
      }
      const { data } = await httpClient.api.get("/hint", {
        params: { filters: `_id:${id}` },
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async update(
    { commit, dispatch }: ActionContext<HintsState, RootState>,
    { hint, hintId }: { hint: Partial<Hint>; hintId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.put(`/hint/${hintId}`, hint);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane wskazówki zostały poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async create(
    { commit, dispatch }: ActionContext<HintsState, RootState>,
    { hint }: { hint: Partial<Hint> }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/hint`, hint);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Wskazówka została poprawnie utworzona.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },

  async delete(
    { commit, dispatch }: ActionContext<HintsState, RootState>,
    { hintId }: { hintId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/hint/${hintId}`);
      commit("delete", hintId);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Wskazówka została poprawnie usunięta.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd usuwania wskazówki.",
          },
        },
        { root: true }
      );
    }
  },
};

const getters = {
  getSearchResults: (state: HintsState): Hint[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: HintsState): Hint[] => {
    return Object.values(state.hintsMap);
  },
  getListMetadata: (state: HintsState): ListMetadata => {
    return state.hintListMetadata;
  },
  getLatestQueryParams: (state: HintsState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getMap: (state: HintsState): Record<string, Hint> => {
    return state.hintsMap;
  },
  getLessonHints: (state: HintsState): ((lessonId: string) => Hint[]) => {
    return (lessonId: string) => {
      return state.lessonHintsMap[lessonId];
    };
  },
  getHintById: (state: HintsState): ((hintId: string) => Hint) => {
    return (hintId: string) => {
      return state.hintsMap[hintId];
    };
  },
};

export const hintsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

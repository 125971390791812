
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import SimpleAlert from "@/components/v2/base/SimpleAlert.vue";
import { DialogCloseType } from "@/types/dialog";
import { SnackbarType } from "@/types/snackbar";
import UserProfileInviteFriend from "./UserProfileInviteFriend.vue";

export default Vue.extend({
  name: "UserProfileActions",
  components: { SimpleAlert, Header, UserProfileInviteFriend },
  data: () => ({
    progress: [],
  }),
  methods: {
    async deleteAccount() {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "UserProfileActionsDialog",
        config: {
          title: this.$t("userProfile.actions.deleteAccountDialog.header"),
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        let sec = 5;
        const isDeleted = await this.$store.dispatch("user/deleteAccount");
        const config = {
          type: SnackbarType.SUCCESS,
          message: this.$t("snackbar.deleteAccount.success", { sec }),
        };
        if (isDeleted) {
          const snackbar = await this.$store.dispatch("snackbar/open", { config }, { root: true });
          setInterval(() => {
            snackbar.config.message = this.$t("snackbar.deleteAccount.success", { sec: --sec });
            if (sec === 1) {
              window.location.href = "https://prestonly.com";
            }
          }, 1000);
          return;
        }
        config.message = this.$t("snackbar.deleteAccount.error");
        config.type = SnackbarType.ERROR;
        await this.$store.dispatch("snackbar/open", { config }, { root: true });
      }
    },
    async deleteProgress() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "UserProfileDeleteProgressDialog",
        config: {
          title: this.$t("userProfile.actions.deleteProgress.dialog.title"),
          payload: {
            progress: this.progress,
          },
        },
      });
      if (type !== DialogCloseType.SUBMITTED) return;
      const { type: confirmType } = await this.$store.dispatch("dialog/open", {
        componentName: "UserProfileDeleteProgressConfirmDialog",
        config: {
          title: this.$t("userProfile.actions.deleteProgress.confirm.title"),
          payload: {
            progress: this.progress,
          },
        },
      });
      if (confirmType !== DialogCloseType.SUBMITTED) return;
      try {
        await this.$store.dispatch("progress/deleteLessonsProgress", payload.selectedLessons);
        this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("snackbar.deleteProgress.success"),
            },
          },
          { root: true }
        );
        this.fetchProgress();
      } catch {
        this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.ERROR,
              message: this.$t("snackbar.deleteProgress.error"),
            },
          },
          { root: true }
        );
      }
    },
    async fetchProgress() {
      this.progress = await this.$store.dispatch("progress/getAllProgress");
    },
  },
  created() {
    this.fetchProgress();
  },
});

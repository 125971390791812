
import Vue, { PropType } from "vue";
import { Exercise } from "@prestonly/preston-common";
import { exerciseBlocksBuilder } from "@/utils";
import draggable from "vuedraggable";

interface MoveHistoryElement {
  from: "top" | "bottom";
  to: "top" | "bottom";
  fromIdx: number;
  toIdx: number;
}

interface BlockDisplayerData {
  blocks: string[];
  pickedBlocks: string[];
  draggingFromTop: boolean;
  draggingFromBottom: boolean;
  moveHistory: MoveHistoryElement[];
}

export default Vue.extend({
  name: "TrainerExampleBlocksDisplayer",

  components: {
    draggable,
  },
  props: {
    exercise: {
      type: Object as PropType<Exercise>,
      required: true,
    },
  },

  data(): BlockDisplayerData {
    return {
      blocks: [],
      pickedBlocks: [],
      draggingFromTop: false,
      draggingFromBottom: false,
      moveHistory: [],
    };
  },

  watch: {
    async exercise() {
      this.tryAgain();
    },
  },

  computed: {
    userAnswer(): string {
      if (this.exercise.answer.split(" ").length === 1) {
        return this.pickedBlocks.join("");
      }
      return this.pickedBlocks.join(" ");
    },
    clearBtnEnabled(): boolean {
      return this.userAnswer !== "";
    },
    undoBtnEnabled(): boolean {
      return this.moveHistory.length > 0;
    },
  },

  methods: {
    tryAgain() {
      this.blocks = this.getBlocks();
      this.pickedBlocks = [];
      this.draggingFromTop = false;
      this.draggingFromBottom = false;
      this.moveHistory = [];
      this.$emit("answerChanged", { answer: "" });
    },
    addToHistory(dragEvent) {
      const topList = (this.$refs.topBlocks as InstanceType<typeof draggable>).$el;
      const bottomList = (this.$refs.bottomBlocks as InstanceType<typeof draggable>).$el;
      this.moveHistory.push({
        from: dragEvent.from === topList ? "top" : "bottom",
        to: dragEvent.to === topList ? "top" : "bottom",
        fromIdx: dragEvent.oldIndex,
        toIdx: dragEvent.newIndex,
      });

      this.draggingFromTop = false;
      this.$emit("answerChanged", { answer: this.userAnswer });
    },
    undo() {
      const lastMove = this.moveHistory.pop();
      if (!lastMove) {
        return;
      }
      const fromArray = lastMove.from === "top" ? this.blocks : this.pickedBlocks;
      const toArray = lastMove.to === "top" ? this.blocks : this.pickedBlocks;

      const [block] = toArray.splice(lastMove.toIdx, 1);
      fromArray.splice(lastMove.fromIdx, 0, block);

      this.$emit("answerChanged", { answer: this.userAnswer });
    },
    pickBlock(pickedBlock: string) {
      this.pickedBlocks.push(pickedBlock);
      const blockIndex = this.blocks.findIndex((block) => block === pickedBlock);
      if (blockIndex !== -1) {
        this.blocks.splice(blockIndex, 1);
      }
      this.moveHistory.push({
        from: "top",
        to: "bottom",
        fromIdx: blockIndex,
        toIdx: this.pickedBlocks.length - 1,
      });
      this.$emit("answerChanged", { answer: this.userAnswer });
    },
    releaseBlock(releasedBlock: string) {
      this.blocks.push(releasedBlock);
      const blockIndex = this.pickedBlocks.findIndex((block) => block === releasedBlock);
      if (blockIndex !== -1) {
        this.pickedBlocks.splice(blockIndex, 1);
      }
      this.moveHistory.push({
        from: "top",
        to: "bottom",
        fromIdx: blockIndex,
        toIdx: this.blocks.length - 1,
      });
      this.$emit("answerChanged", { answer: this.userAnswer });
    },
    getBlocks() {
      if (!this.exercise.answer) {
        return [];
      }
      return exerciseBlocksBuilder({ example: this.exercise.answer });
    },
    clear() {
      this.blocks = this.getBlocks();
      this.pickedBlocks = [];
      this.moveHistory = [];
      this.$emit("answerChanged", { answer: this.userAnswer });
    },
  },
  mounted() {
    this.tryAgain();
  },
});

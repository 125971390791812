
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "TooltipBtn",
  components: {
    Btn,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String as PropType<TranslateResult>,
      default: "",
    },
    tooltipText: {
      type: String as PropType<TranslateResult>,
      default: "",
    },
  },
});


import { LangCode, LanguageMetadata, PrestonUser } from "@prestonly/preston-common";
import moment from "moment";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type TestMetadata = LanguageMetadata & { langCode: LangCode };

export default Vue.extend({
  name: "UserProfileAdditionalInfoTest",
  components: {},
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    finishedTests(): TestMetadata[] {
      return Object.keys(this.user?.userMetadata || {})
        .filter((key) => key in LangCode)
        .map((key) => ({ ...this.user?.userMetadata?.[key], langCode: key }));
    },
    anyTestCompleted(): boolean {
      return this.finishedTests.length > 0;
    },
  },
  methods: {
    listItemContent(metadata: TestMetadata): TranslateResult {
      const today = moment().startOf("day");
      const testCompletedDate = moment(metadata.levelingTestCompletedAt, "DD.MM.YYYY").startOf("day");

      const daysDiff = today.diff(testCompletedDate, "days");
      const monthsDiff = today.diff(testCompletedDate, "months");

      if (daysDiff === 0) {
        return this.$tc("userProfile.additionalInfo.test.testDateToday", daysDiff, {
          lang: this.$t(`languagesMap.${metadata.langCode}.nominative`),
          level: metadata.level,
        });
      }

      return daysDiff < 31
        ? this.$tc("userProfile.additionalInfo.test.testDateDays", daysDiff, {
            lang: this.$t(`languagesMap.${metadata.langCode}.nominative`),
            level: metadata.level,
            days: daysDiff,
          })
        : this.$tc("userProfile.additionalInfo.test.testDateMonths", monthsDiff, {
            lang: this.$t(`languagesMap.${metadata.langCode}.nominative`),
            level: metadata.level,
            months: monthsDiff,
          });
    },
    async goToLevelingTest(lang: LangCode) {
      await this.$store.dispatch("prestonState/setLearnLanguage", lang).then(() => {
        this.$router.push({ name: "levelingTest", params: { lang } });
      });
    },
  },
});


import Vue from "vue";
import { mapGetters } from "vuex";

import { Course, LangCode } from "@prestonly/preston-common";

import TagChips from "@/components/base/TagChips.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";
import { DialogCloseType } from "@/types/dialog";
import LangSelector from "@/components/form/inputs/LangSelector.vue";

export default Vue.extend({
  name: "AdminCourses",

  components: {
    SearchTextField,
    TagChips,
    LangSelector,
  },
  data: function () {
    return {
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Nazwa",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Poziomy",
          align: "start",
          sortable: false,
          value: "levels",
        },
        {
          text: "Lekcje",
          align: "start",
          sortable: false,
          value: "lessons",
        },
        {
          text: "Aktwyny",
          align: "start",
          sortable: false,
          value: "active",
        },
        {
          text: "Wymaga wyraźnego dostępu",
          align: "start",
          sortable: false,
          value: "explicitAccessRequired",
        },
        {
          text: "Tagi",
          align: "center",
          sortable: false,
          value: "tags",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      search: "",
      loading: false,
      sourceLang: LangCode.PL,
      targetLang: LangCode.ENG,
    };
  },

  computed: {
    ...mapGetters({
      courses: "course/getSearchResults",
      metadata: "course/getListMetadata",
      queryParams: "course/getLatestQueryParams",
    }),
  },

  methods: {
    async onSourceLangChange() {
      await this.$store.dispatch("prestonState/setInterfaceLanguage", this.sourceLang);
      await this.getCourses();
    },
    async onTargetLangChange() {
      await this.$store.dispatch("prestonState/setLearnLanguage", this.targetLang);
      await this.getCourses();
    },
    async addCourse() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "CreateCourseDialog",
        config: {
          title: "Utwórz nowy kurs",
          maxWidth: "1200px",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("course/create", payload);
        await this.getCourses();
      }
    },
    async switchActive(course: Course) {
      await this.$store.dispatch("course/update", {
        course: { active: course.active },
        courseId: course._id,
      });
    },
    async switchAccessRequired(course: Course) {
      await this.$store.dispatch("course/update", {
        course: { explicitAccessRequired: course.explicitAccessRequired },
        courseId: course._id,
      });
    },
    async getCourses(): Promise<void> {
      this.loading = true;
      const filters: string[] = [];
      if (this.search) {
        filters.push(`$search:${this.search}`);
      }
      if (this.targetLang) {
        filters.push(`targetLang:${this.targetLang}`);
      }
      if (this.sourceLang) {
        filters.push(`sourceLang:${this.sourceLang}`);
      }
      await this.$store.dispatch("course/getList", {
        filters: filters.join(";"),
        limit: this.options.itemsPerPage,
        page: this.options.page,
      });
      this.loading = false;
    },
  },
  created() {
    const { filters, limit, page } = this.queryParams || {};
    if (limit) {
      this.options.itemsPerPage = limit;
    }
    if (page) {
      this.options.page = page;
    }
    if (filters) {
      const filtersArr = filters.split(";");
      filtersArr.map((filter) => {
        if (filter.includes("targetLang")) {
          this.targetLang = filter.replace("targetLang:", "");
        }
        if (filter.includes("sourceLang")) {
          this.sourceLang = filter.replace("sourceLang:", "");
        }
        if (filter.includes("$search")) {
          this.search = filter.replace("$search:", "");
        }
      });
    }
  },
});


import Vue from "vue";

import BaseWordForm from "@/components/form/BaseWordForm.vue";

export default Vue.extend({
  name: "AdminAddWord",

  components: {
    BaseWordForm,
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminWords" });
    },
    async saveChanges() {
      const form = this.$refs.wordForm as any;
      const newWord = await form.saveChanges();
      if (newWord) {
        await this.$router.push({ name: "adminEditWord", params: { wordId: newWord._id } });
      }
    },
  },
});

import Vue from "vue";
import store from "@/store";
import createAuth0Client, {
  Auth0Client,
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-spa-js";

import { getBrowserLocales, getEnv, httpClient, sleep } from "@/utils";
import { FALLBACK_LANG } from "@/config/globals";
import { USER_ROLE } from "@prestonly/preston-common";

interface Auth0Data {
  loading: boolean;
  isAuthenticated: boolean;
  user: User | undefined;
  auth0Client: Auth0Client;
  popupOpen: boolean;
  error: any;
  authInterceptor: any;
}

interface Auth0Wrapper extends Auth0Data {
  handleRedirectCallback(): () => void;
  loginWithRedirect(o?: RedirectLoginOptions): (o?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently(
    o?: GetTokenSilentlyOptions
  ): (o?: GetTokenSilentlyOptions) => Promise<GetTokenSilentlyVerboseResponse>;
  logout(o?: LogoutOptions): (o?: LogoutOptions) => Promise<void>;
  isAdmin(): () => boolean;
  isCompanyAdmin(): () => boolean;
  $watch: any;
}

const DEFAULT_REDIRECT_CALLBACK = (appState: any) =>
  window.history.replaceState({ appState }, document.title, window.location.pathname);

let instance;

export const getInstance = (): ReturnType<typeof useAuth0> => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}): Auth0Wrapper => {
  if (instance) return instance;
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data(): Auth0Data {
      return {
        loading: true,
        isAuthenticated: false,
        auth0Client: {} as Auth0Client,
        user: {},
        popupOpen: false,
        error: null,
        authInterceptor: null,
      };
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        const browserLocales = getBrowserLocales({ languageCodeOnly: true });
        const ui_locales = browserLocales && browserLocales.length > 0 ? browserLocales[0] : [FALLBACK_LANG];
        o = { ...o, ui_locales };

        const params = new URL(window.location.href).searchParams;
        const screenHint = params.get("screenHint");
        if (screenHint) {
          o.screen_hint = ["signup", "login"].includes(screenHint) ? screenHint : "";
        }
        const loginHint = params.get("loginHint");
        if (loginHint) {
          o.login_hint = loginHint;
        }
        return this.auth0Client.loginWithRedirect(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return new Promise((resolve, reject) => {
          this.auth0Client.logout(o).then(resolve, reject);
        });
      },
      getNamespacedClaim(claim: string) {
        if (!this.user) {
          return null;
        }
        return this.user[`${getEnv("VUE_APP_AUTH0_NAMESPACE")}/${claim}`];
      },
      isAdmin() {
        const roles = this.getNamespacedClaim("roles");
        if (!roles || roles.length === 0) {
          return false;
        }
        return roles.includes(USER_ROLE.ADMIN);
      },
      isCompanyAdmin() {
        const roles = this.getNamespacedClaim("roles");
        if (!roles || roles.length === 0) {
          return false;
        }
        return roles.includes(USER_ROLE.COMPANY_ADMIN);
      },
      isSocial(user: { sub: string }) {
        return user && user.sub && user.sub.includes("google-oauth2");
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        cacheLocation: "localstorage",
        domain: options.domain,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      });

      // Create a new instance of the SDK client using members of the given options object
      if (window && window.ReactNativeWebView && window.reactNativeCredentials) {
        const accessToken = window.reactNativeCredentials.accessToken;
        const idToken = window.reactNativeCredentials.idToken;
        const scope = window.reactNativeCredentials.scope;
        const tokenType = window.reactNativeCredentials.tokenType;
        const expiresAt = window.reactNativeCredentials.expiresAt;

        const data = {
          access_token: accessToken,
          audience: options.audience,
          client_id: options.clientId,
          decodedToken: {
            claims: {
              exp: expiresAt,
            },
            user: {},
          },
          expires_in: 86400,
          id_token: idToken,
          oauthTokenScope: scope,
          scope: scope,
          token_type: tokenType,
          expiresAt: expiresAt,
        };

        await this.auth0Client.cacheManager.set(data);
      }

      try {
        // store companyAccessCode in local storage
        if (window.location.search.includes("cac=")) {
          const params = new URL(window.location.href).searchParams;
          const cac = params.get("cac");
          localStorage.setItem("prestonState:cac", cac || "");
        }

        // If the user is returning to the app after authentication..
        if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();
          const user = await this.auth0Client.getUser();
          let loginMethod;
          if (user) {
            loginMethod = this.isSocial(user) ? "google" : "email_password";
          }

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "login",
            params: {
              method: loginMethod,
            },
          });

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        if (this.isAuthenticated) {
          let token;
          if ((this.getNamespacedClaim("roles") || []).length === 0) {
            await sleep(4500); // TODO - add comment why it is like that
            if (window && window.ReactNativeWebView && window.reactNativeCredentials) {
              token = await this.getTokenSilently({});
            } else {
              token = await this.getTokenSilently({ ignoreCache: true });
            }
          } else {
            token = await this.getTokenSilently({});
          }
          httpClient.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          this.authInterceptor = httpClient.api.interceptors.response.use(
            (response) => response,
            (error) => {
              if (error.response && error.response.status === 401) {
                this.isAuthenticated = false;
                delete httpClient.api.defaults.headers.common["Authorization"];
                httpClient.api.interceptors.request.eject(this.authInterceptor);
                // this.$auth.loginWithRedirect(); // TODO - check ???????????
                return;
              }
              return Promise.reject(error);
            }
          );
          await store.dispatch("user/getUser");
        } else {
          delete httpClient.api.defaults.headers.common["Authorization"];
          httpClient.api.interceptors.request.eject(this.authInterceptor);
        }
        this.loading = false;
      }
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options): void {
    Vue.prototype.$auth = useAuth0(options);
  },
};


import Vue from "vue";
import Alert from "@/components/v2/base/Alert.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "UnverifiedEmail",
  components: { Btn, Alert },
  methods: {
    async redirectToLogin() {
      await this.$auth.logout({
        returnTo: window.location.origin,
      });
      await this.$router.push({ name: "courses" });
    },
    async redirectToMainPage() {
      await this.$auth.logout({
        returnTo: "https://prestonly.com/",
      });
    },
  },
});


import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { LearningMode } from "@prestonly/preston-common";

export default Vue.extend({
  name: "Header",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    subtitle: {
      type: String as PropType<TranslateResult>,
      required: false,
    },
    sub: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: true,
    },
    headerType: {
      type: String,
      default: "h1",
    },
    mode: {
      type: String,
      default: "",
    },
    margin: {
      type: String,
      default: "mb-4",
    },
  },
  computed: {
    modeIcon() {
      if (this.mode === LearningMode.CONTENT_PREVIEW) {
        return require("@/assets/flash-cards.svg");
      }
      if (this.mode === LearningMode.FLASH_CARDS) {
        return require("@/assets/flash-cards.svg");
      }
      if (this.mode === LearningMode.LISTENING) {
        return require("@/assets/listening.svg");
      }
      if (this.mode === LearningMode.SPEAKING) {
        return require("@/assets/talk.svg");
      }
      if (this.mode === LearningMode.WRITING) {
        return require("@/assets/writing.svg");
      }
      return "";
    },
    backgroundImage() {
      if (this.sub) {
        return "";
      }
      return `background-image: url("${require(`@/assets/quotation-mark.svg`)}");`;
    },
  },
});

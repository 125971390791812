import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { getEnv } from "@/utils";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: getEnv("VUE_APP_I18N_LOCALE") || "pl",
  fallbackLocale: getEnv("VUE_APP_I18N_FALLBACK_LOCALE") || "pl",
  messages: loadLocaleMessages(),
  warnHtmlInMessage: "warn",
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    pl: function (choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here
      if (choice < 20) {
        if (choice === 0) {
          return 0;
        }
        if (choice === 1) {
          return 1;
        }
        if (choice <= 4) {
          return 2;
        }
        return 0;
      }

      if ([2, 3, 4].includes(choice % 10)) {
        return 2;
      }
      return 0;
    },
  },
});

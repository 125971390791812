import { LearningMode } from "@prestonly/preston-common";
import { upperCase } from "lodash";
import Router, { Route } from "vue-router";

export function getLearningModeFromRoute(route: Route): LearningMode {
  const mode = route.path.split("/").pop();
  return (upperCase(mode).replace(" ", "_") as LearningMode) || "";
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function ensure<T>(argument: T | undefined | null, message = "This value suppose to be there."): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function removeQueryParams(param: string, $router: Router) {
  if (!param) {
    return;
  }
  const location = JSON.parse(JSON.stringify(window.location));
  if (!(location.search || "").includes(param)) {
    return;
  }
  const params = new URLSearchParams(location.search);
  params.delete(param);
  const query = {};
  for (const [key, value] of params.entries()) {
    query[key] = value;
  }
  $router.replace({ path: location.pathname, query });
}

export { getEnv } from "./getEnv";
export { getBrowserLocales } from "./getBrowserLocales";
export { exerciseTypeSelector } from "./exerciseTypeSelector";
export { exerciseBlocksBuilder } from "./exerciseBlocksBuilder";
export { httpClient } from "./httpClient";
export { textToSpeech } from "./textToSpeech";


import Vue, { PropType } from "vue";
import { LearningMode, Lesson, RepetitionModeSelectedEvent, ProgressEventName } from "@prestonly/preston-common";
import { upperCase } from "lodash";
import { RepetitionCard } from "@/types/repetitionCard";

export default Vue.extend({
  name: "RepetitionSelector",
  props: {
    card: {
      type: Object as PropType<RepetitionCard>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      repetitionProgressMap: {},
    };
  },
  computed: {
    lesson(): () => Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
  },
  methods: {
    async selectMode(mode) {
      const event: RepetitionModeSelectedEvent = {
        lessonId: this.lessonId,
        createdAt: new Date().toISOString(),
        name: ProgressEventName.REPETITION_MODE_SELECTED,
        payload: {
          learningMode: LearningMode[upperCase(mode).replace(" ", "_")],
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
      await this.$router.push({ name: `repetition${mode}` });
    },
  },
});


import Vue, { PropType } from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";

import { Hint, LangCode, parseTags } from "@prestonly/preston-common";

import LangSelector from "@/components/form/inputs/LangSelector.vue";
import TagsField from "@/components/form/inputs/TagsField.vue";

import { validator, VueWithValidator } from "@/utils/validators";
import { stripHtml } from "@/utils/stripHtml";
import SwitchField from "./inputs/SwitchField.vue";

declare interface HintData {
  dto: Hint;
  [key: string]: any;
}

export default VueWithValidator.extend({
  name: "BaseHintForm",

  components: {
    TagsField,
    ckeditor: CKEditor.component,
    LangSelector,
    SwitchField,
  },

  data(): HintData {
    return {
      valid: false,

      editor: ClassicEditor,
      editorConfig: {
        plugins: [Essentials, Paragraph, Bold, Italic, Strikethrough, Underline, List, Table, TableToolbar],
        toolbar: {
          items: [
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },

      dto: {
        content: "",
        rawContent: "",
        sourceLang: LangCode.PL,
        targetLang: LangCode.ENG,
        isFullScreen: false,
        tags: [],
      },
    };
  },
  mixins: [validator],

  props: {
    hint: { type: Object as PropType<Partial<Hint>>, required: false },
  },
  watch: {
    "dto.content"(content: string) {
      this.$emit("contentChanged", content);
    },
    dto(dto) {
      const isSame = this.editor.getHTML() === dto.content;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(dto.content, false);
    },
    hint(hint: Hint) {
      if (hint) {
        this.dto.tags = hint.tags || [];
        this.dto.content = hint.content || "";
        this.dto.rawContent = hint.rawContent || "";
        this.dto.isFullScreen = hint.isFullScreen || false;
        this.dto.sourceLang = hint.sourceLang || LangCode.PL;
        this.dto.targetLang = hint.targetLang || LangCode.ENG;
      }
    },
  },

  computed: {
    hintId(): string {
      return this.$route.params.hintId || "";
    },
  },
  methods: {
    tagsChanged(tags: string[]) {
      this.dto.tags = tags;
    },
    getItemText(item: Hint) {
      const bookContent = parseTags(item.tags);
      const content = stripHtml(item.content);
      return `
      ${bookContent.tag.toUpperCase()} -
      ${content.substring(0, 25)}...`;
    },

    async saveChanges() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      const hint: Hint = {
        content: this.dto.content,
        sourceLang: this.dto.sourceLang,
        targetLang: this.dto.targetLang,
        isFullScreen: this.dto.isFullScreen,
        rawContent: this.dto.rawContent,
        tags: this.dto.tags,
      };
      if (this.hintId) {
        await this.$store.dispatch("hint/update", {
          hint,
          hintId: this.hintId,
        });
        return;
      }
      return this.$store.dispatch("hint/create", {
        hint,
      });
    },
  },
});

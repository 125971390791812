
import Vue from "vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "LevelingTestAnswer",
  components: {
    Btn,
  },
  props: {
    answer: {
      required: true,
      type: String,
    },
    correct: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isClicked: false,
    };
  },

  computed: {
    isCorrect(): boolean {
      return this.isClicked && this.correct;
    },

    isWrong(): boolean {
      return this.isClicked && !this.correct;
    },
  },

  methods: {
    press() {
      this.isClicked = true;
      setTimeout(() => {
        this.$emit("pressed", this.correct);
        this.isClicked = false;
      }, 350);
    },
  },
});


import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "LessonModeSelectorTile",

  props: {
    mode: {
      type: String,
      required: true,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    modeName: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },

  methods: {
    selectMode() {
      this.$emit("selectMode", this.mode);
    },
  },
});

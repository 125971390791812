
import Vue from "vue";

export default Vue.extend({
  name: "ProgressBar",

  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
});

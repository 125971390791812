export interface KeysConfig {
  upper: readonly string[];
  lower: readonly string[];
}

const KEYS_BY_LANG: Record<string, KeysConfig> = {
  de: {
    upper: ["Ä", "Ö", "Ü", "ß"],
    lower: ["ä", "ö", "ü", "ß"],
  },
  es: {
    upper: ["Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü", "¡", "¿"],
    lower: ["á", "é", "í", "ñ", "ó", "ú", "ü", "¡", "¿"],
  },
  pl: {
    upper: ["Ą", "Ć", "Ę", "Ł", "Ń", "Ó", "Ś", "Ź", "Ż"],
    lower: ["ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż"],
  },
  fr: {
    upper: ["À", "Â", "Ç", "É", "È", "Ê", "Ë", "Î", "Ï", "Ô", "Û", "Ù", "Ü", "Ÿ", "Æ", "Œ"],
    lower: ["à", "â", "ç", "é", "è", "ê", "ë", "î", "ï", "ô", "û", "ù", "ü", "ÿ", "æ", "œ"],
  },
  it: {
    upper: ["À", "È", "É", "Ì", "Ò", "Ù"],
    lower: ["à", "è", "é", "ì", "ò", "ù"],
  },
} as const;

export const langHasKeys = (lang: string): boolean => {
  return lang.toLowerCase() in KEYS_BY_LANG;
};

export const getLangKeys = (lang: string): KeysConfig => {
  return (
    KEYS_BY_LANG[lang.toLowerCase()] || {
      upper: [],
      lower: [],
    }
  );
};


import Vue, { PropType } from "vue";
import { Exercise, LearningExerciseType, Lesson, Word } from "@prestonly/preston-common";
import { TranslateResult } from "vue-i18n";
import { camelCase } from "lodash";

export default Vue.extend({
  name: "TrainerSubheader",
  props: {
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.FLASH_CARD,
    },
    currentItemIndex: {
      type: Number,
      required: true,
    },
    remainingItems: {
      type: Number,
      required: true,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
      default: () => ({} as Exercise),
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    words: {
      type: Array as PropType<Word[]>,
    },
  },
  computed: {
    iconSize(): number {
      return (this.$vuetify as any).breakpoint.smAndDown ? 30 : 48;
    },
    showAlert(): boolean {
      return !localStorage.getItem(`contentOfTheExerciseClosed.${camelCase(this.exerciseType)}`);
    },
  },
  methods: {
    onAlertDismiss(): void {
      localStorage.setItem(`contentOfTheExerciseClosed.${camelCase(this.exerciseType)}`, "1");
    },
    getContentOfTheExercise(): string | TranslateResult {
      if (this.exerciseType === LearningExerciseType.UNKNOWN) {
        return "";
      }
      return this.$t(`contentOfTheExercises.${camelCase(this.exerciseType)}`);
    },
    displayHintsList() {
      this.$store.dispatch("dialog/open", {
        componentName: "DisplayHintsListDialog",
        config: {
          title: this.$t("dialogs.displayHintsList.title"),
          payload: {
            lessonId: this.lesson._id,
          },
        },
      });
    },
    displayGlossary() {
      this.$store.dispatch("dialog/open", {
        componentName: "LessonGlossaryDialog",
        config: {
          title: this.$t("dialogs.displayLessonGlossary.title"),
          payload: {
            lessonId: this.lesson._id,
          },
        },
      });
    },
  },
});

import { LangCode } from "@prestonly/preston-common";

const map = {
  [LangCode.ENG]: "united-kingdom.png",
  [LangCode.ES]: "spain.png",
  [LangCode.DE]: "germany.png",
  [LangCode.PL]: "poland.png",
  [LangCode.FR]: "france.png",
  [LangCode.IT]: "italy.png",
} as const;

export const codeToFlagMixin = {
  methods: {
    getFlagPath(langCode: LangCode): string {
      return map[langCode] || "";
    },
  },
};

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        accent: "#F0AF9B",
        primary: "#F79E1B",
        secondary: "#000A46",
      },
    },
  },
});

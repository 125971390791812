var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{style:(_vm.backgroundImage)},[_c('Header',{attrs:{"title":_vm.$t('subscriptionGiftCard.formTitle'),"sub":"","headerType":"h2"}}),_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.$t('subscriptionGiftCard.formHint'))}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.errorMsg.length > 0)?_c('SimpleAlert',{staticClass:"rounded-lg",attrs:{"color":"error","elevation":"1"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e(),_c('BasketGiftCardForm',{ref:"form",on:{"validChanged":function($event){_vm.valid = $event}},model:{value:(_vm.currentProduct),callback:function ($$v) {_vm.currentProduct=$$v},expression:"currentProduct"}}),(_vm.currentProduct.languages.length > 0 && _vm.currentProduct.plan)?_c('BasketGiftCardSummaryCard',{attrs:{"showRemoveBtn":false,"product":_vm.currentProduct,"formattedTotalProductPrice":_vm.formatPrice(_vm.currentProduct.languages.length * _vm.getTotalPrice(_vm.currentProduct.plan.plan)),"formattedFinalTotalPrice":_vm.formatPrice(
            _vm.currentProduct.languages.length *
              _vm.getFinalTotalPrice(_vm.currentProduct.plan.plan, _vm.verifiedCoupon?.discountCondition)
          )}}):_vm._e(),_c('Btn',{staticClass:"mt-3",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly},on:{"click":_vm.addProduct}},[_vm._v(" "+_vm._s(_vm.$t("subscriptionGiftCard.form.addProductBtn"))+" ")])],1)],1),(_vm.products.length > 0)?_c('Header',{staticClass:"pt-4",attrs:{"title":_vm.$t('subscriptionGiftCard.summaryTitle'),"sub":"","headerType":"h2"}}):_vm._e(),(_vm.products.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.products),function(product,index){return _c('BasketGiftCardSummaryCard',{key:index,attrs:{"product":product,"formattedTotalProductPrice":_vm.formatPrice(product.languages.length * _vm.getTotalPrice(product.plan.plan)),"formattedFinalTotalPrice":_vm.formatPrice(
            product.languages.length * _vm.getFinalTotalPrice(product.plan.plan, _vm.verifiedCoupon?.discountCondition)
          )},on:{"removeProduct":function($event){return _vm.removeProduct(index)}}})}),_c('div',{staticClass:"flex justify-end pt-3 font-weight-bold"},[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.$t("subscriptionGiftCard.form.totalCapitalized")))]),_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              _vm.formattedTotalPrice === _vm.formattedFinalTotalPrice
                ? 'subscriptionGiftCard.totalPrice'
                : 'subscriptionGiftCard.discountedTotalPrice',
              {
                totalPrice: _vm.formattedTotalPrice,
                finalTotalPrice: _vm.formattedFinalTotalPrice,
              }
            )
          )}})])],2),_c('v-col',{attrs:{"cols":"12"}},[_c('BasketCouponForm',{attrs:{"disallowedCouponTypes":[_vm.DiscountConditionType.ADDITIONAL_MONTHS]}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
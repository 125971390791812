<template>
  <div class="question card">
    <div class="card__header pt-0">
      <Header :title="`Pytanie ${number}`" sub headerType="h3" center :divider="false" />
      <div class="question__timer mt-2">
        <span
          class="question__timer--progress"
          :class="{ 'question__timer--warning': progress > 80 }"
          :style="timerWidth"
        ></span>
      </div>
      <h4 class="card__title" v-html="question.title"></h4>
    </div>

    <div class="mx-4">
      <v-divider />
    </div>

    <div class="card__content">
      <quiz-answer
        v-for="{ answer, correct, id } in shuffledAnswers"
        :key="id"
        :answer="answer"
        :correct="correct"
        @pressed="pressed"
      ></quiz-answer>
    </div>

    <div class="card__close">
      <span class="card__close__link" @click="close()"> {{ $t("levelingTest.quiz.finishTest") }} </span>
    </div>
  </div>
</template>

<script>
import QuizAnswer from "./LevelingTestAnswer.vue";
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "LevelingTestQuestion",

  components: { QuizAnswer, Header },

  props: {
    number: {
      required: true,
      type: Number,
    },
    question: {
      required: true,
      type: Object,
    },
    progress: {
      required: true,
      type: Number,
    },
  },

  computed: {
    shuffledAnswers() {
      return this.question.answers.slice(0).sort(() => 0.5 - Math.random());
    },

    timerWidth() {
      return `width:${this.progress}%`;
    },
  },

  methods: {
    pressed(isCorrect) {
      this.$el.style.opacity = 0;
      this.$emit("answered", isCorrect);
    },

    close() {
      this.$emit("close");
    },
  },
});
</script>


import Vue from "vue";
import { UserNote } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "FavouriteSentenceBtn",

  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    exerciseId: {
      type: String,
    },
    answerLang: {
      type: String,
    },
  },

  computed: {
    existingNote(): UserNote | null {
      return this.$store.getters["userNote/getNoteToExerciseIdMap"][this.exerciseId];
    },
    isFavourite(): boolean {
      if (!this.existingNote) {
        return false;
      }
      return this.existingNote.isFavourite;
    },
  },

  methods: {
    async saveToFavourites() {
      try {
        if (!this.existingNote) {
          await this.$store.dispatch("userNote/createNote", {
            targetLang: this.answerLang,
            exerciseId: this.exerciseId,
            isFavourite: true,
          });
        } else {
          await this.$store.dispatch("userNote/patchNote", {
            userNoteId: this.existingNote._id,
            payload: {
              isFavourite: true,
            },
          });
        }
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              closeTimeout: 3000,
              type: SnackbarType.SUCCESS,
              title: this.$t("notesPage.noteAddedToFavourites"),
              message: "",
            },
          },
          { root: true }
        );
        this.$emit("addedToFavourites");
      } catch (e) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              closeTimeout: 3000,
              type: SnackbarType.ERROR,
              message: this.$t("somethingWentWrongPleaseTryAgainContactUs"),
            },
          },
          { root: true }
        );
      }
    },
    async removeFromFavourites() {
      await this.$store.dispatch("userNote/patchNote", {
        userNoteId: this.existingNote?._id,
        payload: {
          isFavourite: false,
        },
      });

      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            closeTimeout: 3000,
            type: SnackbarType.SUCCESS,
            title: this.$t("notesPage.noteRemovedToFavourites"),
            message: "",
          },
        },
        { root: true }
      );
      this.$emit("removedFromFavourites");
    },
  },
});

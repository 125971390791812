
import _ from "lodash";
import Vue, { PropType } from "vue";

import { AttemptResult, Exercise, ResultCategory } from "@prestonly/preston-common";
import { RESULT_CATEGORY_SNACKBAR_TIMEOUT } from "@/constants/trainer";

import AudioButton from "@/components/base/AudioButton.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import TrainerAlternativeAnswers from "@/components/trainer/TrainerAlternativeAnswers.vue";

export default Vue.extend({
  name: "TrainerExampleResult",

  components: {
    AudioButton,
    TileWrapper,
    TrainerAlternativeAnswers,
  },

  data: () => ({
    snackbarVisible: false,
    snackbarTimeout: RESULT_CATEGORY_SNACKBAR_TIMEOUT,
  }),

  computed: {
    success(): boolean {
      return this.result && this.result.isSuccess;
    },
    audioButton(): InstanceType<typeof AudioButton> | null {
      return (
        (this.$refs.answerAudioButton as InstanceType<typeof AudioButton>) ||
        (this.$refs.alternativeAnswers as InstanceType<typeof TrainerAlternativeAnswers>)?.audioButton ||
        null
      );
    },
    snackbarContent(): string {
      let key: string;
      switch (this.result.resultCategory) {
        case ResultCategory.CORRECT:
          key = "snackbar.trainer.correct";
          break;
        case ResultCategory.MINOR_ERROR:
          key = "snackbar.trainer.minorError";
          break;
        case ResultCategory.ERROR:
          key = "snackbar.trainer.error";
          break;
        default:
          return "";
      }
      const options = this.$t(key).toString();
      return _.sample(options.split("|")) ?? "";
    },
    snackbarColor(): string {
      switch (this.result.resultCategory) {
        case ResultCategory.CORRECT:
          return "#D6ECE3";
        case ResultCategory.MINOR_ERROR:
          return "#f79e1b";
        case ResultCategory.ERROR:
          return "#FFD8DA";
        default:
          return "info";
      }
    },
    snackbarTextColor(): string {
      switch (this.result.resultCategory) {
        case ResultCategory.CORRECT:
          return "success";
        case ResultCategory.MINOR_ERROR:
          return "white";
        case ResultCategory.ERROR:
          return "error";
        default:
          return "info";
      }
    },
  },

  props: {
    result: {
      type: Object as PropType<AttemptResult>,
      required: true,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    userRecording: {
      type: String,
      required: false,
    },
    showSnackbar: {
      type: Boolean,
      default: true,
    },
    showAlternativeAnswers: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    if (this.result.userInput) {
      this.snackbarVisible = true;
    }
  },
});

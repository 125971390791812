
import type { PropType } from "vue";
import Vue from "vue";

import { VueWithValidator } from "@/utils/validators";
import Btn from "@/components/v2/base/Btn.vue";
import Header from "@/components/v2/base/Header.vue";
import Card from "@/components/v2/base/Card.vue";
import SimpleAlert from "@/components/v2/base/SimpleAlert.vue";
import BasketGiftCardSummaryCard from "@/components/v2/subscription/BasketGiftCardSummaryCard.vue";
import BasketGiftCardForm from "@/components/v2/subscription/BasketGiftCardForm.vue";

import {
  DiscountConditionType,
  formatPrice,
  getFinalTotalPrice,
  getTotalPrice,
  LangCode,
  VerifiedCoupon,
} from "@prestonly/preston-common";
import { GiftCardProduct } from "@/types/common";
import BasketCouponForm from "@/components/v2/subscription/BasketCouponForm.vue";

export default Vue.extend({
  name: "BasketGiftCard",

  components: {
    BasketCouponForm,
    BasketGiftCardSummaryCard,
    Header,
    Card,
    Btn,
    SimpleAlert,
    BasketGiftCardForm,
  },

  data() {
    return {
      valid: false,
      errorMsg: [] as string[],
      default: {
        plan: null as GiftCardProduct["plan"] | null,
        languages: [] as LangCode[],
        email: "",
        startDate: "",
        inscription: "",
      } as GiftCardProduct,
      currentProduct: {
        plan: null as GiftCardProduct["plan"] | null,
        languages: [] as LangCode[],
        email: "",
        startDate: "",
        inscription: "",
      } as GiftCardProduct,
      form: null,
    };
  },

  watch: {
    valid(val) {
      this.$emit("giftCardFormStateChange", { valid: val });
    },
  },

  props: {
    products: {
      type: Array as PropType<GiftCardProduct[]>,
    },
    formattedTotalPrice: {
      type: String,
      default: "",
    },
    formattedFinalTotalPrice: {
      type: String,
      default: "",
    },
  },

  computed: {
    DiscountConditionType() {
      return DiscountConditionType;
    },
    backgroundImage() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "";
      }
      return `background-image: url("${require(`@/assets/voucher-form-gwiazdki-2.png`)}");
        background-position: top right;`;
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
  },
  methods: {
    formatPrice,
    getTotalPrice,
    getFinalTotalPrice,
    removeProduct(index: number) {
      this.products.splice(index, 1);
    },
    async addProduct() {
      const form = this.$refs.form as InstanceType<typeof VueWithValidator>;
      await form.validate();
      if (!this.valid) {
        return;
      }
      this.products.push(JSON.parse(JSON.stringify(this.currentProduct)));
      this.currentProduct = JSON.parse(JSON.stringify(this.default));
      form.reset();
    },
  },
});


import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

import TagChips from "@/components/base/TagChips.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";
import { LangCode } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";
import LangSelector from "@/components/form/inputs/LangSelector.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminDictionary",

  components: {
    SearchTextField,
    AudioButton,
    LangSelector,
    TagChips,
  },

  data: function () {
    return {
      selected: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["tags"],
        sortDesc: [false],
      },
      headers: [
        {
          width: 30,
          align: "start",
          sortable: false,
          value: "needAttention",
        },
        {
          text: "Przykład",
          align: "start",
          sortable: true,
          value: "example",
        },
        {
          text: "Odpowiedź",
          align: "start",
          sortable: true,
          value: "answer",
        },
        {
          text: "Tagi",
          align: "center",
          sortable: true,
          value: "tags",
        },
        {
          text: "Przypisane do",
          align: "center",
          sortable: false,
          value: "exercises",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      search: "",
      loading: false,
      exampleLang: LangCode.PL,
      answerLang: LangCode.ENG,
    };
  },

  computed: {
    ...mapGetters({
      words: "word/getSearchResults",
      metadata: "word/getListMetadata",
      queryParams: "word/getLatestQueryParams",
    }),
  },

  watch: {
    search() {
      this.options.page = 1;
    },

    language() {
      this.options.page = 1;
    },
  },

  methods: {
    async onSourceLangChange() {
      await this.$store.dispatch("prestonState/setInterfaceLanguage", this.exampleLang);
      await this.getWords();
    },
    async onTargetLangChange() {
      await this.$store.dispatch("prestonState/setLearnLanguage", this.answerLang);
      await this.getWords();
    },
    onMenuInput(value: boolean) {
      if (!value) {
        this.selected = [];
      }
    },
    async copyWord(wordId: string) {
      this.loading = true;
      await this.$store.dispatch("word/copy", { wordId });
      await this.getWords();
      this.loading = false;
    },
    async removeWord(wordId: string) {
      this.loading = true;
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń słoowo",
        },
      });
      if (type === DialogCloseType.CLOSED) {
        this.loading = false;
        return;
      }

      await this.$store.dispatch("word/delete", { wordId });
      this.loading = false;
    },
    debouncedGetWords: _.debounce(async function (this: any): Promise<void> {
      await this.getWords();
    }, 500),
    async getWords() {
      this.loading = true;
      const filters: string[] = [];
      if (this.search) {
        filters.push(`$search:${this.search}`);
      }
      if (this.exampleLang) {
        filters.push(`exampleLang:${this.exampleLang}`);
      }
      if (this.answerLang) {
        filters.push(`answerLang:${this.answerLang}`);
      }

      const payload = {
        limit: this.options.itemsPerPage,
        page: this.options.page,
        filters: filters.join(";"),
        withExercises: 1,
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy[0]) {
        payload["sort"] = `${sortBy[0]}:${sortDesc[0] ? "desc" : "asc"}`;
      }
      await this.$store.dispatch("word/getList", payload);
      this.loading = false;
    },
    async copyRecordingUrlToClipboard(item: any, type: "example" | "answer") {
      const recording = await this.$store.dispatch("exercise/getRecording", {
        recordingId: item[`${type}Recording`],
      });
      navigator.clipboard.writeText(`${recording.content}`);

      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            title: "",
            type: SnackbarType.SUCCESS,
            message: "Link do nagrania skopiowany do schowka.",
          },
        },
        { root: true }
      );
    },
  },
  async mounted() {
    await this.getWords();
  },
  created() {
    const { filters, limit, page } = this.queryParams || {};
    if (limit) {
      this.options.itemsPerPage = limit;
    }
    if (page) {
      this.options.page = page;
    }
    if (!filters) {
      return;
    }
    const filtersArr = filters.split(";");
    filtersArr.map((filter) => {
      if (filter.includes("answerLang")) {
        this.answerLang = filter.replace("answerLang:", "");
      }
      if (filter.includes("exampleLang")) {
        this.exampleLang = filter.replace("exampleLang:", "");
      }
      if (filter.includes("$search")) {
        this.search = filter.replace("$search:", "");
      }
    });
  },
});


import Vue, { PropType } from "vue";

import BugReportBtn from "@/components/trainer/BugReportBtn.vue";
import FavouriteSentenceBtn from "@/components/trainer/FavouriteSentenceBtn.vue";
import TrainerLabels from "@/components/trainer/TrainerLabels.vue";
import TrainerLangSwitcher from "@/components/trainer/TrainerLangSwitcher.vue";
import AddNoteBtn from "@/components/trainer/AddNoteBtn.vue";

import { AttemptResult, Exercise, LearningMode, Lesson, UserNote } from "@prestonly/preston-common";

export default Vue.extend({
  name: "TrainerHeaderButtons",
  components: {
    AddNoteBtn,
    BugReportBtn,
    FavouriteSentenceBtn,
    TrainerLabels,
    TrainerLangSwitcher,
  },

  data() {
    return {
      answerLang: "",
    };
  },

  props: {
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    mode: {
      type: String as PropType<LearningMode>,
      required: true,
    },
    result: {
      type: Object as PropType<AttemptResult | null>,
      required: false,
    },
    showLangSwitcher: {
      type: Boolean,
    },
    swapLang: {
      type: Function,
      required: false,
    },
  },

  methods: {
    displayListeningAndWritingActivitiesDialog() {
      this.$store.dispatch("dialog/open", {
        componentName: "ListeningAndWritingActivitiesDialog",
        config: {
          title: this.$t("listeningAndWritingActivities.description"),
        },
      });
    },
  },

  computed: {
    userNotesMap(): Record<string, UserNote> {
      return this.$store.getters["userNote/getNoteToExerciseIdMap"];
    },
  },

  watch: {
    currentItem() {
      if (this.answerLang) {
        return;
      }
      if (this.currentItem.answerLang) {
        this.answerLang = this.currentItem.answerLang;
      }
    },
  },
});

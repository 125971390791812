
import Vue from "vue";
import {
  Course,
  CourseProgressMap,
  LearningMode,
  Lesson,
  NextLessonRecommendation,
  RecommendationType,
} from "@prestonly/preston-common";
import TileWrapper from "@/components/base/TileWrapper.vue";
import Btn from "@/components/v2/base/Btn.vue";
import { httpClient } from "@/utils";
import moment from "moment";
import { TranslateResult } from "vue-i18n";

interface RecommendationBannerConfig {
  courseFinished: boolean;
  lessonFinished: boolean;
  buttons: {
    text: TranslateResult;
    action: () => any;
  }[];
}

export default Vue.extend({
  name: "NextLessonRecommendationBaner",
  components: { TileWrapper, Btn },
  props: {
    showCourse: {
      type: Boolean,
      required: false,
    },
    courseId: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      recommendation: { type: RecommendationType.NothingStartedYet } as NextLessonRecommendation,
      course: null as Course | null,
      lesson: null as Lesson | null,
      examplesLeft: 0,
    };
  },
  async mounted() {
    this.recommendation = await this.$store.dispatch(
      "progress/getNextLessonRecommendation",
      this.courseId ? { courseId: this.courseId } : {}
    );
    if (this.recommendation.courseId) {
      const { data } = await httpClient.api.get(`/course/${this.recommendation.courseId}`);
      this.course = data;
    }
    if (this.recommendation.lessonId) {
      const {
        data: {
          data: [lesson],
        },
      } = await httpClient.api.get("/lesson", {
        params: { filters: `_id:${this.recommendation.lessonId}` },
      });
      this.lesson = lesson;
    }
    if (this.recommendation.type === RecommendationType.LessonUnfinished) {
      const progress: CourseProgressMap = await this.$store.dispatch(
        "progress/getCourseProgress",
        this.recommendation.courseId
      );
      if (this.lesson?.items.length) {
        this.examplesLeft = this.lesson?.items.length - progress[this.recommendation.lessonId]?.currentItem + 1;
      }
    }
  },
  computed: {
    config(): RecommendationBannerConfig {
      const { lessonId, courseId, learningMode } = this.recommendation;
      let learningModePath =
        {
          [LearningMode.WRITING]: "writing",
          [LearningMode.LISTENING]: "listening",
          [LearningMode.CONTENT_PREVIEW]: "content-preview",
          [LearningMode.SPEAKING]: "speaking",
        }[learningMode || LearningMode.WRITING] || "writing";
      switch (this.recommendation.type) {
        case RecommendationType.LessonFinished:
          return {
            courseFinished: false,
            lessonFinished: true,
            buttons: [
              {
                text: this.$t("lessonRecommendation.goToRepetition"),
                action: () => this.$router.push({ path: `/repetition/${lessonId}/select-mode` }),
              },
              {
                text: this.$t("lessonRecommendation.goToNextLesson"),
                action: () => {
                  this.$route.name === "courseDetails"
                    ? this.$emit("highlightLesson", lessonId)
                    : this.$router.push({ path: `/course/${courseId}`, hash: lessonId });
                },
              },
            ],
          };
        case RecommendationType.LessonUnfinished:
          return {
            courseFinished: false,
            lessonFinished: false,
            buttons: [
              {
                text: this.$t("lessonRecommendation.continueLearning"),
                action: () => this.$router.push({ path: `/course/${courseId}/lesson/${lessonId}/${learningModePath}` }),
              },
            ],
          };
        case RecommendationType.EverythingFinished:
          return {
            courseFinished: true,
            lessonFinished: true,
            buttons: [
              {
                text: this.$t("lessonRecommendation.goToRepetition"),
                action: () => this.$router.push({ name: "exercises" }),
              },
            ],
          };
        case RecommendationType.NothingStartedYet:
        default:
          return {
            courseFinished: false,
            lessonFinished: false,
            buttons: [],
          };
      }
    },
    isVisible(): boolean {
      return !!(
        this.recommendation.type !== RecommendationType.NothingStartedYet &&
        this.course &&
        this.lesson &&
        (this.showCourse || this.recommendation.type !== RecommendationType.EverythingFinished)
      );
    },
    lastActivity(): string {
      const diff = moment().startOf("day").diff(moment(this.recommendation.lastActivity).startOf("day"), "days");
      if (diff === 0) {
        return this.$t("today").toString();
      }
      return new Intl.RelativeTimeFormat(this.$i18n.locale).format(-diff, "days");
    },
  },
});

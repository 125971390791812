
import Vue from "vue";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

import { AssignedLesson, CEFRLevel, Course, Lesson, LessonItem } from "@prestonly/preston-common";

import BaseCourseForm from "@/components/form/BaseCourseForm.vue";
import TagChips from "@/components/base/TagChips.vue";
import { DialogCloseType } from "@/types/dialog";

export default Vue.extend({
  name: "AdminEditCourse",

  components: {
    BaseCourseForm,
    TagChips,
    draggable,
  },

  data: function () {
    return {
      isDragging: false,
      expandedLesson: 0,
      copy: {
        name: "",
        levels: "",
        description: "",
        active: "",
        explicitAccessRequired: "",
        tags: [],
        targetLang: "",
        sourceLang: "",
      },
      hintContainer: {
        top: "0px",
        left: "0px",
      },
      currentHints: [],
    };
  },

  computed: {
    ...mapGetters({ exercisesMap: "exercise/getMap", exercise: "exercise/getById", hintsMap: "hint/getMap" }),
    CEFRLevels(): string[] {
      return Object.values(CEFRLevel);
    },
    courseId(): string {
      return this.$route.params.courseId;
    },
    course(): Course {
      return this.$store.getters["course/getCourseById"](this.courseId);
    },
    lesson(): (lessonId: string) => Lesson {
      return (lessonId: string) => {
        return this.$store.getters["lesson/getById"](lessonId);
      };
    },
    hint(): (hintId: string) => Lesson {
      return (hintId: string) => {
        return this.$store.getters["hint/getById"](hintId);
      };
    },
  },

  methods: {
    async onHintBulbEnter(evt, lessonItem: LessonItem) {
      const exercise = this.exercisesMap[lessonItem.itemId];
      await Promise.all(exercise.hints.map((hintId) => this.$store.dispatch("hint/getSingle", hintId)));
      this.currentHints = exercise.hints.map((hintId) => this.hintsMap[hintId].content);
      const { top, left, width } = evt.target.getBoundingClientRect();
      this.hintContainer.top = `${top}px`;
      this.hintContainer.left = `${left + width}px`;
    },
    onHintBulbLeave() {
      this.currentHints = [];
    },
    expandLesson(lessonOrder: number) {
      if (this.expandedLesson === lessonOrder + 1) {
        this.expandedLesson = 0;
        return;
      }
      this.expandedLesson = lessonOrder + 1;
    },
    async dragEnd() {
      this.isDragging = false;
      const reorderedLessons: AssignedLesson[] = [];
      this.course.lessons.forEach(({ lessonId }, index) => {
        reorderedLessons.push({
          order: index + 1,
          lessonId,
        });
      });
      await this.saveChanges({ lessons: reorderedLessons });
    },
    dragChoose() {
      this.isDragging = true;
    },
    async createLesson() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "CreateLessonDialog",
        config: {
          title: "Utwórz nową lekcję",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("lesson/create", payload);
      }
    },
    async addLessonsToCourse() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "AttachExistingLessonDialog",
        config: {
          title: "Dodaj lekcję do kursu",
          maxWidth: "1600px",
          minContentHeight: "500px",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        const lessons = this.course.lessons;
        const existingLessonIds = lessons.map((l) => l.lessonId);
        let maxAssignedOrder =
          lessons.length > 0
            ? Math.max.apply(
                null,
                lessons.map((l) => l.order)
              )
            : 0;
        const newAssignedLessons = payload.lessons
          .filter((newLesson) => !existingLessonIds.includes(newLesson._id))
          .map((newLesson) => {
            maxAssignedOrder++;
            return {
              lessonId: newLesson._id,
              order: maxAssignedOrder,
            };
          });
        const mergedLessons = ([] as AssignedLesson[]).concat(lessons, newAssignedLessons);
        await this.$store.dispatch("course/update", {
          courseId: this.courseId,
          course: { lessons: mergedLessons },
        });
        await this.$store.dispatch("exercise/getCourseExercises", this.courseId);
      }
    },
    async removeLessonFromCourse(lessonId: string) {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń lekcję z kursu",
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      const filteredAssignedLessons = this.course.lessons.filter((lesson) => lesson.lessonId !== lessonId);
      await this.saveChanges({ lessons: filteredAssignedLessons });
    },
    async moveLessonOrder(type: "up" | "down", lessonId: string) {
      await this.$store.dispatch("course/changeLessonOrder", {
        type,
        lessonId,
        courseId: this.courseId,
      });
    },
    async getCourse() {
      await Promise.all([
        this.$store.dispatch("course/getSingle", this.courseId),
        this.$store.dispatch("lesson/getCourseLessons", this.courseId),
        this.$store.dispatch("exercise/getCourseExercises", this.courseId),
      ]);
    },
    async saveChanges(course?: Partial<Course>) {
      if (!course) {
        course = this.copy as unknown as Partial<Course>;
      }
      await this.$store.dispatch("course/update", {
        course,
        courseId: this.courseId,
      });
      this.syncCopy();
    },
    syncCopy() {
      for (const [key, value] of Object.entries(this.course)) {
        if (key in this.copy) {
          this.copy[key] = value;
        }
      }
    },
  },
  async created() {
    await this.getCourse();
    if (!this.course) {
      return;
    }
    this.syncCopy();
  },
});


import Vue from "vue";
import MobileFooterButtons, { MobileFooterBtnConfig } from "@/components/base/MobileFooterButtons.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "TrainerContentPreviewFooter",
  components: {
    Btn,
    MobileFooterButtons,
  },

  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttons(): MobileFooterBtnConfig[] {
      return [
        /*{
          icon: "play_arrow",
          text: this.$t("buttons.withoutNo.play"),
          action: this.playRecording,
          btnColor: "secondary",
        },*/
        {
          icon: "navigate_before",
          text: this.$t("buttons.withoutNo.back"),
          action: this.getBack,
          btnColor: "secondary",
        },
        {
          icon: "navigate_next",
          text: this.$t("buttons.withoutNo.proceed"),
          action: this.proceed,
          btnColor: "secondary",
        },
      ];
    },
  },
  methods: {
    playRecording() {
      this.$emit("playRecording");
    },
    proceed() {
      if (this.isLast) {
        return;
      }
      this.$emit("proceed");
    },
    getBack() {
      if (this.isFirst) {
        return;
      }
      this.$emit("getBack");
    },
  },
});

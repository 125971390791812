export function getBrowserLocales(options = {}): string[] {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
  if (!browserLocales) {
    return ["en"];
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}


import Vue from "vue";
import MobileTrainerFooterBtn from "@/components/base/MobileTrainerFooterBtn.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "TrainerFooterMoreMenu",
  components: {
    Btn,
    MobileTrainerFooterBtn,
  },
});

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { authGuard } from "@/auth/authGuard";

import AdminCourses from "@/views/admin/AdminCourses.vue";
import AdminEditCourse from "@/views/admin/AdminEditCourse.vue";
import AdminExercises from "@/views/admin/AdminExercises.vue";
import AdminEditExercise from "@/views/admin/AdminEditExercise.vue";
import AdminAddExercise from "@/views/admin/AdminAddExercise.vue";
import AdminHints from "@/views/admin/AdminHints.vue";
import AdminEditHint from "@/views/admin/AdminEditHint.vue";
import AdminAddHint from "@/views/admin/AdminAddHint.vue";
import AdminUsers from "@/views/admin/AdminUsers.vue";
import AdminStats from "@/views/admin/AdminStats.vue";
import AdminLessons from "@/views/admin/AdminLessons.vue";
import AdminEditLesson from "@/views/admin/AdminEditLesson.vue";
import AdminDictionary from "@/views/admin/AdminDictionary.vue";
import AdminAddWord from "@/views/admin/AdminAddWord.vue";
import AdminEditWord from "@/views/admin/AdminEditWord.vue";
import AdminOffersPopups from "@/views/admin/AdminOffersPopups.vue";
import AdminAddPopup from "@/views/admin/AdminAddPopup.vue";
import AdminEditPopup from "@/views/admin/AdminEditPopup.vue";
import AdminMediaLibrary from "@/views/admin/AdminMediaLibrary.vue";
import AdminGiftCards from "@/views/admin/AdminGiftCards.vue";

import Dashboard from "@/views/app/Dashboard.vue";
import UserOnboarding from "@/views/app/UserOnboarding.vue";
import UserProfile from "@/views/app/UserProfile.vue";
import Courses from "@/views/app/Courses.vue";
import CourseDetails from "@/views/app/CourseDetails.vue";
import LessonModeSelector from "@/views/app/LessonModeSelector.vue";
import RepetitionModeSelector from "@/views/app/RepetitionModeSelector.vue";
import Exercises from "@/views/app/Exercises.vue";
import Notes from "@/views/app/Notes.vue";
import LevelingTest from "@/views/app/LevelingTest.vue";
import SelectLanguage from "@/views/app/SelectLanguage.vue";
import NotFound from "@/views/common/NotFound.vue";
import Login from "@/views/common/Login.vue";
import UnverifiedEmail from "@/views/common/UnverifiedEmail.vue";
import Trainer from "@/views/app/Trainer.vue";
import RepetitionTrainer from "@/views/app/RepetitionTrainer.vue";
import AdminExerciseChecker from "@/views/admin/AdminExerciseChecker.vue";
import Subscribe from "@/views/app/Subscribe.vue";
import SubscribeSummary from "@/views/app/SubscribeSummary.vue";
import SubscribeThankYou from "@/views/app/SubscribeThankYou.vue";
import SubscribeGiftCard from "@/views/app/SubscribeGiftCard.vue";
import SubscribeGiftCardThankYou from "@/views/app/SubscribeGiftCardThankYou.vue";

import CompanyAdminUsers from "@/views/companyAdmin/CompanyAdminUsers.vue";

import { PositionResult } from "vue-router/types/router.js";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "External",
      authenticationRequired: false,
    },
  },
  {
    path: "/unverified-email",
    name: "unverifiedEmail",
    component: UnverifiedEmail,
    meta: {
      layout: "External",
      authenticationRequired: false,
    },
  },
  {
    path: "/leveling-test/:lang",
    name: "levelingTest",
    component: LevelingTest,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/select-language",
    name: "selectLanguage",
    component: SelectLanguage,
    meta: {
      layout: "Raw",
    },
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscribe,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/subscribe/summary",
    name: "subscribeSummary",
    component: SubscribeSummary,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/subscribe/thank-you",
    name: "subscribeThankYou",
    component: SubscribeThankYou,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/subscribe/gift-card",
    name: "subscribeGiftCard",
    component: SubscribeGiftCard,
    meta: {
      layout: "LandingPageLike",
      authenticationRequired: false,
    },
  },
  {
    path: "/subscribe/gift-card-thank-you",
    name: "subscribeGiftCardThankYou",
    component: SubscribeGiftCardThankYou,
    meta: {
      layout: "LandingPageLike",
      authenticationRequired: false,
    },
  },

  // APP
  {
    path: "/",
    name: "root",
    redirect: "/course",
  },
  {
    path: "/me",
    name: "userProfile",
    component: UserProfile,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    redirect: "/course",
    meta: {
      layout: "Default",
    },
  },
  {
    path: "/course",
    name: "courses",
    component: Courses,
    meta: {
      layout: "Default",
      tour: "courses",
    },
  },
  {
    path: "/course/:courseId",
    name: "courseDetails",
    component: CourseDetails,
    meta: {
      layout: "Default",
      tour: "courseDetails",
    },
  },
  {
    path: "/course/:courseId/lesson/:lessonId/select-mode",
    name: "lessonModeSelector",
    component: LessonModeSelector,
    meta: {
      layout: "Default",
      tour: "exerciseModeSelector",
    },
  },
  {
    path: "/course/:courseId/lesson/:lessonId/listening",
    name: "trainerListening",
    component: Trainer,
    meta: {
      layout: "Trainer",
      tour: "example",
    },
  },
  {
    path: "/course/:courseId/lesson/:lessonId/writing",
    name: "trainerWriting",
    component: Trainer,
    meta: {
      layout: "Trainer",
      tour: "example",
    },
  },
  {
    path: "/course/:courseId/lesson/:lessonId/speaking",
    name: "trainerSpeaking",
    component: Trainer,
    meta: {
      layout: "Trainer",
      tour: "example",
    },
  },
  {
    path: "/course/:courseId/lesson/:lessonId/content-preview",
    name: "trainerContentPreview",
    component: Trainer,
    meta: {
      layout: "Trainer",
      tour: "example",
    },
  },
  {
    path: "/exercises",
    name: "exercises",
    component: Exercises,
    meta: {
      layout: "Default",
      tour: "exercises",
    },
  },
  {
    path: "/notes",
    name: "notes",
    component: Notes,
    meta: {
      layout: "Default",
      tour: "notes",
    },
  },
  {
    path: "/repetition/:lessonId/select-mode",
    name: "repetitionModeSelector",
    component: RepetitionModeSelector,
    meta: {
      layout: "Default",
      tour: "repetition",
    },
  },
  {
    path: "/repetition/:lessonId/flash-cards",
    name: "repetitionFlashCards",
    component: RepetitionTrainer,
    meta: {
      layout: "RepetitionTrainer",
    },
  },
  {
    path: "/repetition/:lessonId/listening",
    name: "repetitionListening",
    component: RepetitionTrainer,
    meta: {
      layout: "RepetitionTrainer",
    },
  },
  {
    path: "/repetition/:lessonId/writing",
    name: "repetitionWriting",
    component: RepetitionTrainer,
    meta: {
      layout: "RepetitionTrainer",
    },
  },
  {
    path: "/repetition/:lessonId/speaking",
    name: "repetitionSpeaking",
    component: RepetitionTrainer,
    meta: {
      layout: "RepetitionTrainer",
    },
  },
  {
    path: "/user-onboarding",
    name: "userOnboarding",
    component: UserOnboarding,
    meta: {
      layout: "Raw",
    },
  },

  // COMPANY ADMIN
  {
    path: "/company/users",
    name: "companyAdminUsers",
    component: CompanyAdminUsers,
    meta: {
      isCompanyAdmin: true,
      layout: "CompanyAdmin",
    },
  },

  // ADMIN
  {
    path: "/admin",
    redirect: "/admin/courses",
    name: "rootAdmin",
  },
  {
    path: "/admin/courses",
    name: "adminCourses",
    component: AdminCourses,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/courses/:courseId",
    name: "adminEditCourse",
    component: AdminEditCourse,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/lessons",
    name: "adminLessons",
    component: AdminLessons,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/lessons/:lessonId",
    name: "adminEditLesson",
    component: AdminEditLesson,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/exercises",
    name: "adminExercises",
    component: AdminExercises,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/dictionary",
    name: "adminDictionary",
    component: AdminDictionary,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/dictionary/new",
    name: "adminAddWord",
    component: AdminAddWord,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/dictionary/:wordId",
    name: "adminEditWord",
    component: AdminEditWord,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/exercises/new",
    name: "adminAddExercise",
    component: AdminAddExercise,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/exercises/:exerciseId",
    name: "adminEditExercise",
    component: AdminEditExercise,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/hints",
    name: "adminHints",
    component: AdminHints,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/hints/new",
    name: "adminAddHint",
    component: AdminAddHint,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/hints/:hintId",
    name: "adminEditHint",
    component: AdminEditHint,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/users",
    name: "adminUsers",
    component: AdminUsers,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/stats",
    name: "adminStats",
    component: AdminStats,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/exercise-checker",
    name: "adminExerciseChecker",
    component: AdminExerciseChecker,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/offers/popups",
    name: "adminOffersPopups",
    component: AdminOffersPopups,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/offers/popups/new",
    name: "adminAddPopup",
    component: AdminAddPopup,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/offers/popups/:popupId",
    name: "adminEditPopup",
    component: AdminEditPopup,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/media-library",
    name: "adminMediaLibrary",
    component: AdminMediaLibrary,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/admin/gift-cards",
    name: "adminGiftCards",
    component: AdminGiftCards,
    meta: {
      isAdminPanel: true,
      layout: "Admin",
    },
  },
  {
    path: "/404",
    name: "notFound",
    component: NotFound,
    meta: {
      layout: "Default",
    },
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      layout: "Default",
    },
  },
];

const finalRoutes = routes.map((route) => {
  route.beforeEnter = authGuard;
  return route;
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: finalRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 } as unknown as PositionResult;
  },
});

export default router;

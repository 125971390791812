import PortalVue from "portal-vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueMeta from "vue-meta";

import { Auth0Plugin, useAuth0 } from "./auth";
import { getEnv } from "@/utils";

declare module "vue/types/vue" {
  export interface Vue {
    $auth: ReturnType<typeof useAuth0>;
  }
}

Vue.use(Auth0Plugin, {
  domain: getEnv("VUE_APP_AUTH0_DOMAIN"),
  clientId: getEnv("VUE_APP_AUTH0_CLIENT_ID"),
  audience: getEnv("VUE_APP_AUTH0_AUDIENCE"),
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(PortalVue);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");


import Vue from "vue";
import { upperCase } from "lodash";
import { LearningMode, Course, Lesson, LessonModeSelectedEvent, ProgressEventName } from "@prestonly/preston-common";

import LessonModeSelectorTile from "@/components/base/LessonModeSelectorTile.vue";
import LessonAlert from "@/components/base/LessonAlert.vue";
import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "LessonModeSelector",
  metaInfo() {
    return {
      title: this.$t("metadata.lessonModeSelector.title").toString(),
    };
  },
  components: { Header, LessonModeSelectorTile, LessonAlert },
  computed: {
    course(): Course {
      return this.$store.getters["course/getCourseById"](this.courseId);
    },
    courseId(): string {
      return this.$route.params.courseId;
    },
    lesson(): () => Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
  },
  methods: {
    async selectMode(mode) {
      const event: LessonModeSelectedEvent = {
        lessonId: this.lessonId,
        courseId: this.courseId,
        createdAt: new Date().toISOString(),
        name: ProgressEventName.LESSON_MODE_SELECTED,
        payload: {
          learningMode: LearningMode[upperCase(mode).replace(" ", "_")],
        },
      };
      await this.$store.dispatch("progress/saveProgress", event);
      await this.$router.push({ name: `trainer${mode}` });
    },
    async getCourse(): Promise<void> {
      await this.$store.dispatch("course/getSingleAvailable", this.courseId);
    },
    async getCourseLessons(): Promise<void> {
      await this.$store.dispatch("lesson/getCourseAvailableLessons", this.courseId);
    },
  },
  async created() {
    await Promise.all([this.getCourse(), this.getCourseLessons()]);
    // TODO - redirect if lesson or course inactive of doesnt exist
  },
});


import Vue from "vue";
import { formatDate } from "@/utils/dateFormatter";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AdminOffersPopups",

  components: {},

  data() {
    return {
      loading: true,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      headers: [
        {
          text: "Tytuł",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Aktywny",
          align: "center",
          sortable: false,
          value: "active",
        },
        {
          text: "Aktywny od (początek dnia)",
          align: "start",
          sortable: false,
          value: "startDate",
        },
        {
          text: "Aktywny do (koniec dnia)",
          align: "start",
          sortable: false,
          value: "endDate",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      popups: "popup/getAll",
      metadata: "popup/getListMetadata",
      queryParams: "popup/getLatestQueryParams",
    }),
  },

  methods: {
    formatDate,
    getPopups() {
      return this.$store.dispatch("popup/getList", this.queryParams);
    },
    removePopup(popupId: string) {
      this.$store.dispatch("popup/delete", { popupId });
    },
  },

  async created() {
    this.loading = true;
    await this.getPopups();
    this.loading = false;
  },
});

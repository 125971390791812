
import Vue, { PropType } from "vue";
import { Exercise } from "@prestonly/preston-common";
import _ from "lodash";

interface BuildingBlock {
  text: string;
  inputLength?: number;
}
export default Vue.extend({
  name: "TrainerExampleFillTheGapsDisplayer",
  props: {
    exercise: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    gap: {
      type: Number,
      required: true,
    },
  },

  watch: {
    userAnswer() {
      this.$emit("answerChanged", { answer: this.userAnswer });
    },
    buildingBlocks() {
      this.$nextTick(() => {
        this.calculateBlocksWidth();
      });
    },
  },

  data: function () {
    return {
      inputs: {} as Record<number, string>,
      hiddenBlockWidths: [] as string[],
      lastActiveBlock: -1,
    };
  },

  computed: {
    ranges(): { start: number; end: number }[] {
      if (!this.exercise.gaps) {
        return [];
      }
      return this.exercise.gaps[this.gap].ranges || [];
    },
    buildingBlocks(): BuildingBlock[] {
      const answer = this.exercise?.answer;
      if (!answer) {
        return [];
      }

      const blocks = this.ranges.reduce((blocks, { start, end }, idx, ranges) => {
        const lastRangeEnd = idx === 0 ? 0 : ranges[idx - 1].end;
        return [
          ...blocks,
          { text: answer.slice(lastRangeEnd, start) },
          { text: answer.slice(start, end), inputLength: end - start },
        ];
      }, [] as BuildingBlock[]);

      const blocksFull = [...blocks, { text: answer.slice(_.last(this.ranges)?.end) }];
      return blocksFull.filter((block) => block.inputLength || block.text); // filter out empty blocks
    },
    userAnswer(): string {
      return this.buildingBlocks.reduce((answer, block, idx) => {
        if (!block.inputLength) {
          return answer + block.text;
        }
        let input = this.inputs[idx] || "";
        if (input && input[0].toLowerCase() === block.text[0].toLowerCase()) {
          input = block.text[0] + input.substring(1);
        }
        return answer + input.trim();
      }, "");
    },
  },
  methods: {
    calculateBlocksWidth(): void {
      const elements = document.querySelectorAll(".hidden-block-text");
      if (!elements.length) {
        this.hiddenBlockWidths = [];
      }
      this.hiddenBlockWidths = Array.from(elements).map((elem) => elem.clientWidth + 24 + "px") as string[];
    },
    pushKey(key: string): void {
      this.inputs = { ...this.inputs, [this.lastActiveBlock]: (this.inputs[this.lastActiveBlock] || "") + key };
    },
    setLastActiveBlock(idx: number): void {
      this.lastActiveBlock = idx;
    },
  },
  mounted() {
    this.calculateBlocksWidth();
    this.lastActiveBlock = this.buildingBlocks.findIndex((block) => block.inputLength);
  },
});

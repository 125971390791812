import _ from "lodash";
import { ActionContext } from "vuex";

import { httpClient } from "@/utils";
import { RootState } from "@/store";
import {
  CompanyAccessCodeVerificationStatus,
  CompanyAccessCodeVerificationResponse,
  CompanyUser,
  CompanyAccess,
  Company,
  InviteCompanyUsersResponse,
} from "@prestonly/preston-common";

interface CompanyState {
  company: Company;
  usersMap: Record<string, CompanyUser>;
  companyAccess: CompanyAccess;
}

const state = (): CompanyState => ({
  company: {} as Company,
  usersMap: {},
  companyAccess: {} as CompanyAccess,
});

const mutations = {
  setCompany(state: CompanyState, company: Company): void {
    state.company = company;
  },
  setUsers(state: CompanyState, users: CompanyUser[]): void {
    state.usersMap = _.keyBy(users, "email");
  },
  setCompanyAccess(state: CompanyState, companyAccess: CompanyAccess): void {
    state.companyAccess = companyAccess;
  },
};

const actions = {
  async getCompany(
    { commit }: ActionContext<CompanyState, RootState>,
    { companyId }: { companyId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/company/${companyId}`);
      commit("setCompany", data);
    } catch (err) {
      console.error(err);
    }
  },
  async getCompanyUsers(
    { commit }: ActionContext<CompanyState, RootState>,
    { companyId }: { companyId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/company/${companyId}/users`);
      commit("setUsers", data);
    } catch (err) {
      console.error(err);
    }
  },
  async getCompanyAccess(
    { commit }: ActionContext<CompanyState, RootState>,
    { companyId }: { companyId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/company/${companyId}/accesses`);
      commit("setCompanyAccess", data);
    } catch (err) {
      console.error(err);
    }
  },
  async redeemCompanyAccessCode(
    { dispatch }: ActionContext<CompanyState, RootState>,
    { companyAccessCode, firstname, lastname }: { companyAccessCode: string; firstname: string; lastname: string }
  ): Promise<CompanyAccessCodeVerificationResponse> {
    try {
      const { data } = await httpClient.api.post("/company/redeem-company-access-code", {
        companyAccessCode,
        firstname,
        lastname,
      });
      if (data.status === CompanyAccessCodeVerificationStatus.VERIFIED) {
        dispatch("user/getUser", {}, { root: true });
      }
      return data;
    } catch (err) {
      console.error(err);
      return {
        status: CompanyAccessCodeVerificationStatus.INVALID,
        message: "Nieznany błąd",
      } as CompanyAccessCodeVerificationResponse;
    }
  },
  async detachUserFromCompany(
    { dispatch }: ActionContext<CompanyState, RootState>,
    { companyId, userEmailToDetach }: { companyId: string; userEmailToDetach: string }
  ): Promise<{ detached: boolean }> {
    try {
      await httpClient.api.post(`/company/${companyId}/detach`, {
        userEmailToDetach,
      });
      await Promise.all([dispatch("getCompanyUsers", { companyId }), dispatch("getCompanyAccess", { companyId })]);
      return { detached: true };
    } catch (err) {
      console.error(err);
      return { detached: false };
    }
  },
  async inviteUsersToCompany(
    { dispatch }: ActionContext<CompanyState, RootState>,
    { companyId, emailsList, companyAccessCode }: { companyId: string; emailsList: string[]; companyAccessCode: string }
  ): Promise<{ invited: boolean; result?: InviteCompanyUsersResponse }> {
    try {
      const { data: result } = await httpClient.api.post(`/company/${companyId}/invite-users`, {
        emailsList,
        companyAccessCode,
      });
      await Promise.all([dispatch("getCompanyUsers", { companyId }), dispatch("getCompanyAccess", { companyId })]);
      return { invited: true, result };
    } catch (err) {
      console.error(err);
      return { invited: false };
    }
  },
};

const getters = {
  getCompany: (state: CompanyState): Company => {
    return state.company;
  },
  getCompanyUsers: (state: CompanyState): CompanyUser[] => {
    return Object.values(state.usersMap);
  },
  getCompanyUsersMap: (state: CompanyState): Record<string, CompanyUser> => {
    return state.usersMap;
  },
  getCompanyAccess: (state: CompanyState): CompanyAccess => {
    return state.companyAccess;
  },
};

export const companiesStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import { getInstance } from "./index";
import { NavigationGuardNext, Route } from "vue-router";

export const authGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (to.query.error === "access_denied" && to.query.error_description === "email_not_verified") {
      return next({ name: "unverifiedEmail" });
    }

    if (authService.isAuthenticated) {
      if (to.meta && to.meta.isAdminPanel && !authService.isAdmin()) {
        return next({ name: "notFound" });
      }
      return next();
    }
    if (!authService.isAuthenticated) {
      if (to.meta && to.meta.authenticationRequired === false) {
        return next();
      }
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

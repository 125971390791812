
import Vue from "vue";
import {
  BasketProduct,
  LangCode,
  SubscriptionPlan,
  VerifiedCoupon,
  formatPrice,
  getFinalTotalPrice,
  getTotalPrice,
  getUnitPrice,
  getMonthsFromProductCode,
} from "@prestonly/preston-common";
import Header from "@/components/v2/base/Header.vue";
import Card from "@/components/v2/base/Card.vue";
import SubscriptionPlanCard from "@/components/v2/subscription/SubscriptionPlanCard.vue";
import BasketSummary from "@/components/v2/subscription/BasketSummary.vue";

export default Vue.extend({
  name: "Subscribe",
  metaInfo() {
    return {
      title: this.$t("metadata.subscribe.title").toString(),
    };
  },
  components: {
    Header,
    Card,
    SubscriptionPlanCard,
    BasketSummary,
  },

  data() {
    return {
      plans: [] as SubscriptionPlan[],
      selectedPlan: null as SubscriptionPlan | null,
    };
  },
  computed: {
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
  },
  watch: {
    learnLanguage() {
      this.setDefaultPlan();
    },
  },
  methods: {
    formatPrice,
    getUnitPrice,
    getTotalPrice,
    getFinalTotalPrice,
    getMonthsFromProductCode,
    onPlanSelection({ plan }) {
      this.selectedPlan = plan;
      this.$store.commit("basket/addProduct", { productCode: plan.productCode, quantity: 1 } as BasketProduct);
      this.$nextTick(() => {
        this.$vuetify.goTo("#summary", { offset: 50, duration: 1000 });
      });
    },
    onPlanRemoval() {
      this.selectedPlan = null;
      this.$nextTick(() => {
        this.$vuetify.goTo("#plans", { offset: 50, duration: 1000 });
      });
    },
    async setDefaultPlan() {
      this.plans = await this.$store.dispatch("prestonState/getPlans");
      const featuredPlan = this.plans.find((plan) => plan.featured);
      if (featuredPlan) {
        this.selectedPlan = featuredPlan;
        this.$store.commit("basket/addProduct", {
          productCode: featuredPlan.productCode,
          quantity: 1,
        } as BasketProduct);
      }
    },
  },
  async created() {
    await this.setDefaultPlan();
  },
});


import Vue from "vue";

export default Vue.extend({
  name: "TileHeaderProgress",

  props: {
    progress: {
      type: Number,
      default: 0,
    },
    allLessons: {
      type: Number,
      required: false,
    },
    finishedLessons: {
      type: Number,
      required: false,
    },
  },
});

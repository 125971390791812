import Vue from "vue";
import Vuex from "vuex";

import { exercisesStore } from "@/store/modules/exercises";
import { hintsStore } from "@/store/modules/hints";
import { coursesStore } from "@/store/modules/courses";
import { lessonsStore } from "@/store/modules/lessons";
import { dialogsStore } from "@/store/modules/dialogs";
import { snackbarsStore } from "@/store/modules/snackbars";
import { tagsStore } from "@/store/modules/tags";
import { progressStore } from "@/store/modules/progress";
import { attemptStore } from "@/store/modules/attempt";
import { timerStore } from "@/store/modules/timer";
import { wordsStore } from "@/store/modules/words";
import { usersStore } from "@/store/modules/user";
import { levelingTestStore } from "@/store/modules/levelingTest";
import { prestonStore } from "@/store/modules/prestonState";
import { speechToTextStore } from "@/store/modules/speechToText";
import { basketStore } from "@/store/modules/basket";
import { ordersStore } from "@/store/modules/orders";
import { popupsStore } from "@/store/modules/popups";
import { companiesStore } from "@/store/modules/company";
import { userNotesStore } from "@/store/modules/userNote";
import { mediaStore } from "@/store/modules/media";
import { giftCardsStore } from "@/store/modules/giftCards";

Vue.use(Vuex);

export type RootState = typeof rootState;

const rootState = {};
export default new Vuex.Store({
  state: rootState,
  modules: {
    exercise: { ...exercisesStore },
    hint: { ...hintsStore },
    course: { ...coursesStore },
    lesson: { ...lessonsStore },
    tag: { ...tagsStore },
    dialog: { ...dialogsStore },
    snackbar: { ...snackbarsStore },
    progress: { ...progressStore },
    attempt: { ...attemptStore },
    timer: { ...timerStore },
    word: { ...wordsStore },
    user: { ...usersStore },
    levelingTest: { ...levelingTestStore },
    prestonState: { ...prestonStore },
    speechToText: { ...speechToTextStore },
    basket: { ...basketStore },
    order: { ...ordersStore },
    popup: { ...popupsStore },
    company: { ...companiesStore },
    userNote: { ...userNotesStore },
    media: { ...mediaStore },
    giftCard: { ...giftCardsStore },
  },
});

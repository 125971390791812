
import Vue from "vue";
import _ from "lodash";
import {
  AssignedLesson,
  LessonDetails,
  Course,
  Lesson,
  LessonStartedEvent,
  ProgressEventName,
  CourseProgress,
} from "@prestonly/preston-common";
import { ensure } from "@/utils";

import LessonTile from "@/components/base/LessonTile.vue";
import EndListText from "@/components/base/EndListText.vue";
import ProgressBar from "@/components/base/ProgressBar.vue";
import Header from "@/components/v2/base/Header.vue";
import NextLessonRecommendationBanner from "@/components/base/NextLessonRecommendationBanner.vue";

export default Vue.extend({
  name: "CourseDetails",
  metaInfo() {
    return {
      title: this.$t("metadata.courseDetails.title").toString(),
    };
  },
  components: { Header, LessonTile, EndListText, ProgressBar, NextLessonRecommendationBanner },
  data() {
    return {
      loading: true,
      userLessonsMap: {} as CourseProgress,
      unfinishedLessons: false,
      lessonIdToRef: {} as Record<string, Vue>,
    };
  },
  computed: {
    course(): Course {
      return this.$store.getters["course/getCourseById"](this.courseId);
    },
    startedLessonIds(): string[] {
      return Object.entries(this.userLessonsMap)
        .filter(([...[, value]]) => !value.finishedAt)
        .map(([key]) => key);
    },
    finishedLessonsCount(): number {
      return Object.entries(this.userLessonsMap).filter(([...[, value]]) => value.finishedAt).length;
    },
    lessonLevels(): Array<{ text: string; value: string }> {
      return _.uniq(this.lessonsWithDetails.map((lesson) => ({ text: lesson.level, value: lesson.level })));
    },
    lessonsWithDetails(): LessonDetails[] {
      return this.course.lessons.map((lesson) => {
        return {
          ...lesson,
          ...this.getLessonDetails(lesson.lessonId),
        };
      });
    },
    lessons(): LessonDetails[] {
      if (!this.unfinishedLessons) {
        return this.lessonsWithDetails;
      }
      return this.lessonsWithDetails.filter((lesson) => this.startedLessonIds.includes(lesson._id));
    },
    courseId(): string {
      return this.$route.params.courseId;
    },
    progress(): number {
      return Math.floor((this.finishedLessonsCount * 100) / this.lessonsWithDetails.length) || 0;
    },
    noFilteredResults(): boolean {
      return this.unfinishedLessons && this.lessons.length === 0;
    },
    endListText(): string {
      return this.$t(this.noFilteredResults ? "noFilteredResults" : "endOfList").toString();
    },
  },
  methods: {
    highlightLesson(lessonId: string) {
      const finishedLesson = this.course.lessons.find((lesson) => lesson.lessonId === lessonId);
      if (!finishedLesson) {
        return;
      }
      const getNextLesson = (order): string => {
        const nextLesson = this.course.lessons.find((lesson) => lesson.order === order);
        if (!nextLesson) {
          return "";
        }
        if (this.userLessonsMap[nextLesson.lessonId]?.finishedAt) {
          return getNextLesson(order + 1);
        }
        return nextLesson.lessonId;
      };
      const lessonIdToHighlight = getNextLesson(finishedLesson.order + 1);
      const lessonElement = this.lessonIdToRef[lessonIdToHighlight].$el;
      lessonElement.classList.remove("highlight");
      this.$nextTick(() => {
        lessonElement.scrollIntoView({ behavior: "smooth", block: "center" });
        lessonElement.classList.add("highlight");
      });
    },
    findUnfinishedLessons(id: string): AssignedLesson {
      return ensure(this.course.lessons.find((lesson) => lesson.lessonId === id));
    },
    resetFilters(): void {
      this.unfinishedLessons = false;
    },
    async onBtnClicked({ courseId, lessonId }: { courseId: string; lessonId: string }): Promise<void> {
      const lessonProgress = this.userLessonsMap[lessonId];
      if (!lessonProgress || (lessonProgress && !lessonProgress.startedAt)) {
        const event: LessonStartedEvent = {
          courseId,
          lessonId,
          createdAt: new Date().toISOString(),
          name: ProgressEventName.LESSON_STARTED,
          payload: {},
        };
        await this.$store.dispatch("progress/saveProgress", event);
      }
      if (!lessonProgress || (lessonProgress && !lessonProgress.finishedAt)) {
        await this.$router.push({
          name: "lessonModeSelector",
          params: { courseId, lessonId },
        });
      } else {
        await this.$router.push({
          name: "repetitionModeSelector",
          params: { lessonId },
        });
      }
    },
    async getCourse(): Promise<void> {
      await this.$store.dispatch("course/getSingleAvailable", this.courseId);
    },
    async getCourseProgress(): Promise<void> {
      this.userLessonsMap = await this.$store.dispatch("progress/getCourseProgress", this.courseId);
    },
    async getCourseLessons(): Promise<void> {
      await this.$store.dispatch("lesson/getCourseAvailableLessons", this.courseId);
    },
    getLessonDetails(lessonId: string): Lesson {
      return this.$store.getters["lesson/getById"](lessonId);
    },
  },
  async mounted() {
    this.loading = true;
    await Promise.all([this.getCourse(), this.getCourseLessons()]);
    await this.getCourseProgress();
    this.loading = false;
    if (this.$route.hash) {
      this.$nextTick(() => {
        this.highlightLesson(this.$route.hash.replaceAll("#", ""));
      });
    }
  },
});

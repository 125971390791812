
import { PropType } from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import List from "@ckeditor/ckeditor5-list/src/list";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage";

import { Popup } from "@prestonly/preston-common";

import { validator, VueWithValidator } from "@/utils/validators";
import moment from "moment";
import TextField from "./inputs/TextField.vue";

declare interface PopupData {
  dto: Omit<Popup, "startDate" | "endDate"> & {
    startDate: string;
    endDate: string;
  };
  [key: string]: any;
}

function generateImageResizeOptions() {
  const sizes = [50, 100, 150, 200, 250, 300, 350, 400, 450, 500];
  return sizes.map((size) => {
    return {
      name: `resizeImage:${size}`,
      label: `${size}px`,
      value: size,
    };
  });
}

export default VueWithValidator.extend({
  name: "BasePopupForm",

  components: {
    ckeditor: CKEditor.component,
    TextField,
  },

  data(): PopupData {
    return {
      valid: false,

      startDatePickerVisible: false,
      endDatePickerVisible: false,

      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Paragraph,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          List,
          Table,
          TableToolbar,
          Image,
          ImageResize,
          AutoImage,
        ],
        image: {
          resizeUnit: "px",
          resizeOptions: [
            {
              name: "resizeImage:original",
              label: "Original",
              value: null,
            },
            ...generateImageResizeOptions(),
          ],
        },
        alignment: {
          options: ["left", "center", "right"],
        },
        toolbar: {
          items: [
            "alignment",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "resizeImage",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },

      dto: {
        active: false,
        title: "",
        htmlContent: "",
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        maxDisplayTimes: 0,
      },
    };
  },
  mixins: [validator],

  props: {
    popup: { type: Object as PropType<Partial<Popup>>, required: false },
  },
  watch: {
    popup(popup: Popup) {
      if (popup) {
        this.dto.active = popup.active || false;
        this.dto.title = popup.title || "";
        this.dto.htmlContent = popup.htmlContent || "";
        this.dto.startDate = moment(popup.startDate).format("YYYY-MM-DD");
        this.dto.endDate = moment(popup.endDate).format("YYYY-MM-DD");
        this.dto.maxDisplayTimes = popup.maxDisplayTimes || 0;
      }
    },
  },

  computed: {
    popupId(): string {
      return this.$route.params.popupId || "";
    },
  },
  methods: {
    async saveChanges() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      const popup: Popup = {
        active: this.dto.active,
        title: this.dto.title,
        htmlContent: this.dto.htmlContent,
        startDate: moment(this.dto.startDate, "YYYY-MM-DD").toDate(),
        endDate: moment(this.dto.endDate, "YYYY-MM-DD").toDate(),
        maxDisplayTimes: this.dto.maxDisplayTimes,
      };
      if (this.popupId) {
        await this.$store.dispatch("popup/update", {
          popup,
          popupId: this.popupId,
        });
        return;
      }
      return this.$store.dispatch("popup/create", {
        popup,
      });
    },
  },
});

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isVisible)?_c('tile-wrapper',{attrs:{"hoverEffectDisabled":""}},[_c('div',{staticClass:"font-weight-light prestonly__base-text"},[_c('v-row',{staticClass:"d-flex flex-column flex-sm-row justify-space-between"},[_c('v-col',{staticClass:"d-flex flex-column justify-space-around"},[(_vm.showCourse)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.course &&
              (_vm.config.courseFinished
                ? _vm.$t('lessonRecommendation.courseFinished', { courseName: _vm.course.name })
                : _vm.$t('lessonRecommendation.course', { courseName: _vm.course.name }))
            )}})]):_vm._e(),_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.lesson &&
              (_vm.config.lessonFinished
                ? _vm.$t('lessonRecommendation.lessonFinished', { lessonName: _vm.lesson.name })
                : _vm.$t('lessonRecommendation.lesson', { lessonName: _vm.lesson.name }))
            )}})]),(_vm.lastActivity)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("lessonRecommendation.lastActivity", { timeDelta: _vm.lastActivity }))+" "),(_vm.examplesLeft)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc("lessonRecommendation.examplesLeft", _vm.examplesLeft, { count: _vm.examplesLeft }))+" ")]):_vm._e()]):_vm._e()]),_c('v-col',{staticClass:"d-flex flex-column flex-wrap justify-end justify-md-space-around flex-grow-0"},[(!_vm.lesson.isLocked)?_c('div',_vm._l((_vm.config.buttons),function(button){return _c('Btn',{key:button.text,staticClass:"my-1",attrs:{"block":""},on:{"click":button.action}},[_vm._v(" "+_vm._s(button.text)+" ")])}),1):_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('Btn',_vm._g(_vm._b({attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"to":{ name: 'subscribe' }}},'Btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("lock")]),_vm._v(" "+_vm._s(_vm.$t("buyAccess"))+" ")],1)]}}],null,false,3699892914)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.unlockLesson")))])])],1)])],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
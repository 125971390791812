
import Vue from "vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "SimpleAlert",
  components: {
    Btn,
  },
  props: {
    customClasses: {
      type: String,
      default: "",
    },
  },
});

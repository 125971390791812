
import Vue from "vue";
import TextField from "@/components/form/inputs/TextField.vue";
import { AttemptEventPayload } from "@/types/progress.js";
import TrainerWriting from "@/components/trainer/TrainerWriting.vue";
import { AttemptResult, Exercise, LangCode, LearningExerciseType } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import LangSelector from "@/components/form/inputs/LangSelector.vue";
import SelectField from "@/components/form/inputs/SelectField.vue";

enum ExerciseSearchState {
  Unknown,
  NotFound,
  Found,
}

const emptyExercise = (): Exercise => {
  return {
    needAttention: false,
    exampleLang: LangCode.PL,
    example: "",
    exampleRecording: null,
    answerLang: LangCode.ENG,
    answer: "",
    answerRecording: null,
    alternativeAnswers: [],
    active: true,
    tags: [],
    hints: [],
    words: [],
    gaps: [],
  };
};

export default Vue.extend({
  name: "AdminExerciseChecker",

  components: {
    TextField,
    LangSelector,
    SelectField,
    TrainerWriting,
  },
  data: function () {
    return {
      expectedAnswer: "",
      exerciseId: "",
      result: null as AttemptResult | null,
      currentItem: emptyExercise(),
      isEntryManual: false,
      exerciseSearchState: ExerciseSearchState.Unknown,
      answerLang: LangCode.ENG,
      selectedExerciseType: LearningExerciseType.WRITING_FULL_SENTENCE,
    };
  },
  computed: {
    exerciseIdInputErrors(): string[] {
      return this.exerciseSearchState === ExerciseSearchState.NotFound ? ["Nie znaleziono ćwiczenia"] : [];
    },
    exerciseTypes(): string[] {
      const availableTypes = [LearningExerciseType.WRITING_FULL_SENTENCE];
      if (this.currentItem.answer?.split(" ").length > 1) {
        availableTypes.push(LearningExerciseType.WRITING_FULL_SENTENCE_CHOOSE_BLOCKS);
      }
      if (this.currentItem.gaps.length > 0) {
        availableTypes.push(LearningExerciseType.WRITING_FILL_THE_GAP_CHOOSE_BLOCKS);
        availableTypes.push(LearningExerciseType.WRITING_FILL_THE_GAP);
      }
      return availableTypes;
    },
  },
  methods: {
    async check(userInput: string) {
      const preferredAnswer = this.isEntryManual ? this.expectedAnswer : this.currentItem?.answer;
      if (!preferredAnswer) {
        return;
      }
      const payload: AttemptEventPayload = {
        alternativeAnswers: [],
        check: true,
        userInput,
        preferredAnswer,
        simplifiedCheck: false,
        lang: this.answerLang,
      };
      this.result = await this.$store.dispatch("attempt/check", payload);
    },
    onExpectedAnswerChange() {
      this.$set(this, "currentItem", {
        ...emptyExercise(),
        answerLang: this.answerLang,
        answer: this.expectedAnswer,
        example: this.expectedAnswer,
      });
      this.resetExerciseType();
    },
    resetExerciseType() {
      if (!this.exerciseTypes.includes(this.selectedExerciseType)) {
        this.selectedExerciseType = this.exerciseTypes[0] as LearningExerciseType;
      }
    },
    async onExerciseIdChanged() {
      let exercise: Exercise | null = null;
      try {
        const { data } = await httpClient.api.get<Exercise | null>(`/exercise/${this.exerciseId}`);
        exercise = data;
      } catch (error) {
        exercise = null;
      }
      if (!exercise) {
        this.exerciseSearchState = ExerciseSearchState.NotFound;
        this.currentItem = emptyExercise();
        return;
      }
      this.exerciseSearchState = ExerciseSearchState.Found;
      this.currentItem = exercise;
      this.answerLang = exercise.answerLang;
    },
    onCheck(event) {
      this.check(event.userInput);
    },
    tryAgain() {
      this.result = null;
    },
    onManualEntrySwitchChange() {
      this.exerciseId = "";
      this.expectedAnswer = "";
      this.currentItem = emptyExercise();
      this.resetExerciseType();
    },
  },
});


import Vue, { PropType } from "vue";
import { Exercise } from "@prestonly/preston-common";
import { DEFAULT_DIALOG_CONFIG, DialogCloseType, DialogConfig } from "@/types/dialog";
import { getLearningModeFromRoute } from "@/utils";

export default Vue.extend({
  name: "BugReportBtn",
  props: {
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    userAnswer: {
      type: String,
      required: false,
      default: "",
    },
    correctAnswer: {
      type: String,
      required: false,
    },
  },

  methods: {
    async openReportBugDialog() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "ReportBugDialog",
        config: {
          ...DEFAULT_DIALOG_CONFIG,
          title: this.$t("dialogs.reportBug.title"),
          payload: {
            userAnswer: this.userAnswer || "",
            correctAnswer: this.correctAnswer,
          },
          closeBtnText: this.$t("dialogs.reportBug.cancel"),
          submitBtnText: this.$t("dialogs.reportBug.send"),
        } as DialogConfig,
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      await this.$store.dispatch("exercise/reportBug", {
        exerciseId: this.currentItem._id,
        comment: payload || "-",
        suggestedAnswer: this.userAnswer || "-",
        mode: getLearningModeFromRoute(this.$route),
      });
    },
  },
});

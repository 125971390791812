
import UserNotesList from "@/components/userNote/UserNotesList.vue";
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";

export default Vue.extend({
  name: "UserNotesNavigationDrawer",

  components: {
    Header,
    UserNotesList,
  },

  data() {
    return {
      show: false,
    };
  },
});

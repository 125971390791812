
import Vue from "vue";

export default Vue.extend({
  name: "FinishedModeIcons",

  props: {
    isLocked: {
      type: Boolean,
      default: false,
    },
    finishedModes: {
      type: Array,
      default: () => [],
    },
  },
});

import Vue, { VueConstructor } from "vue";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validator = Vue.extend({
  methods: {
    _required(value: string): boolean | string {
      return !!value || "Pole jest wymagane";
    },
    _minSelected(expectedLength: number): (value: string) => boolean | string {
      return (value: string): boolean | string => {
        return !value || value.length >= expectedLength || `Wybierz przynajmniej jeden element.`;
      };
    },
    _minLength(expectedLength: number): (value: string) => boolean | string {
      return (value: string): boolean | string => {
        return !value || value.length >= expectedLength || `Wymagane co najmniej ${expectedLength} znaki`;
      };
    },
    _maxLength(expectedLength: number): (value: string) => boolean | string {
      return (value: string): boolean | string => {
        return !value || value.length < expectedLength || `Maksymalnie ${expectedLength} znaków.`;
      };
    },
    _email(value: string): boolean | string {
      return !value || EMAIL_REGEX.test(value) || "Wpisz poprawny adres e-mail.";
    },
    _emailsList(separator: string): (value: string) => boolean | string {
      return (value: string): boolean | string => {
        return (
          !value ||
          value.split(separator).every((email) => EMAIL_REGEX.test(email.trim())) ||
          "Wpisz poprawne adresy e-mail."
        );
      };
    },
    _nipNumber(value: string): boolean | string {
      const nipWithoutDashes = value.replace(/-/g, "");
      const regex = /^[0-9]{10}$/;
      if (!regex.test(nipWithoutDashes)) {
        return "Wpisz poprawny numer NIP.";
      } else {
        const digits = ("" + nipWithoutDashes).split("");
        const checksum =
          (6 * parseInt(digits[0]) +
            5 * parseInt(digits[1]) +
            7 * parseInt(digits[2]) +
            2 * parseInt(digits[3]) +
            3 * parseInt(digits[4]) +
            4 * parseInt(digits[5]) +
            5 * parseInt(digits[6]) +
            6 * parseInt(digits[7]) +
            7 * parseInt(digits[8])) %
          11;

        return parseInt(digits[9]) == checksum || "Wpisz poprawny numer NIP.";
      }
      return "Wpisz poprawny numer NIP.";
    },
    _plZipCode(value: string): boolean | string {
      const regex = /^[0-9]{2}-[0-9]{3}$/;
      return !value || regex.test(value) || "Wpisz poprawny kod pocztowy.";
    },
    async validate(): Promise<void> {
      await (this.$refs.form as HTMLFormElement).validate();
    },
    reset(): void {
      (this.$refs.form as HTMLFormElement).reset();
    },
    resetValidation(): void {
      (this.$refs.form as HTMLFormElement).resetValidation();
    },
  },
});

export const VueWithValidator = Vue as VueConstructor<Vue & InstanceType<typeof validator>>;


import Vue from "vue";
import { LearningMode, Lesson, RepetitionProgress, RepetitionProgressMap } from "@prestonly/preston-common";
import { RepetitionCard } from "@/types/repetitionCard";

import RepetitionSelector from "@/components/base/RepetitionSelector.vue";
import LessonAlert from "@/components/base/LessonAlert.vue";
import Header from "@/components/v2/base/Header.vue";
import Alert from "@/components/v2/base/Alert.vue";

export default Vue.extend({
  name: "RepetitionModeSelector",
  metaInfo() {
    return {
      title: this.$t("metadata.repetitionModeSelector.title").toString(),
    };
  },
  components: {
    Alert,
    Header,
    RepetitionSelector,
    LessonAlert,
  },
  data() {
    return {
      repetitionProgress: {} as RepetitionProgressMap,
    };
  },
  computed: {
    totalCompleted(): number {
      return Object.values(this.repetitionProgress).reduce((acc, progress) => {
        if (progress) {
          acc = acc + progress.totalNumberOfRepeatedItems;
        }
        return acc;
      }, 0);
    },
    lesson(): () => Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
    flashCard(): RepetitionCard {
      return {
        progress: this.getProgressForMode(LearningMode.FLASH_CARDS),
        img: {
          alt: "flash cards",
          src: require("@/assets/flash-cards.svg"),
        },
        mode: "FlashCards",
        title: this.$t("flashCards"),
      };
    },
    listeningCard(): RepetitionCard {
      return {
        progress: this.getProgressForMode(LearningMode.LISTENING),
        img: {
          alt: "listening",
          src: require("@/assets/listening.svg"),
        },
        mode: "Listening",
        title: this.$t("listening"),
      };
    },
    writingCard(): RepetitionCard {
      return {
        progress: this.getProgressForMode(LearningMode.WRITING),
        img: {
          alt: "writing",
          src: require("@/assets/writing.svg"),
        },
        mode: "Writing",
        title: this.$t("writing"),
      };
    },
    talkCard(): RepetitionCard {
      return {
        progress: this.getProgressForMode(LearningMode.SPEAKING),
        img: {
          alt: "talk",
          src: require("@/assets/talk.svg"),
        },
        mode: "Speaking",
        title: this.$t("talk"),
      };
    },
  },
  methods: {
    async getLesson(): Promise<void> {
      await this.$store.dispatch("lesson/getSingle", { id: this.lessonId });
    },
    getProgressForMode(mode: LearningMode): RepetitionProgress {
      const modeProgress = this.repetitionProgress[mode];
      if (!modeProgress) {
        return {} as RepetitionProgress;
      }
      return modeProgress;
    },
    async getRepetitionProgress() {
      this.repetitionProgress = await this.$store.dispatch("progress/getRepetitionProgress", {
        lessonId: this.lessonId,
      });
    },
  },
  async created() {
    await Promise.all([this.getLesson(), this.getRepetitionProgress()]);
  },
});

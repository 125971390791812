
import Vue, { PropType } from "vue";
import { CompanyAccess, CompanyAccessDefinition, formatPrice } from "@prestonly/preston-common";

import { getLanguageAttributeByLangCode } from "@/utils/languages";
import { formatDate } from "@/utils/dateFormatter";

import Header from "@/components/v2/base/Header.vue";
import Card from "@/components/v2/base/Card.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "CompanyAdminUsersAccesses",
  components: {
    Card,
    Header,
    Btn,
  },
  props: {
    contractDetails: {
      type: Object as PropType<Pick<CompanyAccess, "contractDetails">["contractDetails"]>,
    },
    accesses: {
      type: Array as PropType<CompanyAccessDefinition[]>,
    },
    companyId: {
      type: String,
      required: true,
    },
    pendingInvitations: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatPrice,
    getLanguageAttributeByLangCode,
    formatDate,
    async inviteUsers(accessEntity: CompanyAccessDefinition) {
      await this.$store.dispatch("dialog/open", {
        componentName: "InviteUsersToCompany",
        config: {
          title: this.$t("dialogs.inviteUsersToCompany.title"),
          payload: {
            remaining: accessEntity.remaining,
            companyId: this.companyId,
            companyAccessCode: accessEntity.code,
          },
        },
      });
    },
  },
});


import Vue from "vue";
import moment from "moment/moment.js";
import TextField from "@/components/form/inputs/TextField.vue";

export default Vue.extend({
  name: "DatePickerDialog",
  components: {
    TextField,
  },
  props: {
    minDate: {
      type: String,
    },
  },
  data() {
    return {
      date: "",
      showCalendar: false,
    };
  },
  computed: {
    computedDateFormatted(): string {
      return this.date ? moment(this.date).format("DD.MM.YYYY") : "";
    },
  },
  methods: {
    submit() {
      this.$emit("newDate", this.date);
      this.showCalendar = false;
    },
    clear() {
      this.date = "";
      this.$emit("newDate", "");
    },
  },
});

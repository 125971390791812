
import Vue, { PropType } from "vue";
import { SubscriptionPlan } from "@prestonly/preston-common";

export default Vue.extend({
  name: "SubscriptionPlanCard",

  props: {
    plan: {
      type: Object as PropType<SubscriptionPlan>,
      required: true,
    },
    formattedUnitPrice: {
      type: String,
      required: true,
    },
    formattedTotalPrice: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "../index";
import { BaseListFilters, Course, Lesson, ListMetadata } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { SnackbarType } from "@/types/snackbar";

interface LessonsState {
  lessonListMetadata: ListMetadata;
  lessonsMap: Record<string, Lesson>;
  searchResults: Record<string, Lesson>;
  latestQueryParams: BaseListFilters;
}

const state = (): LessonsState => ({
  lessonListMetadata: { total: 0 },
  lessonsMap: {},
  searchResults: {},
  latestQueryParams: {},
});

const mutations = {
  setLatestQueryParams(state: LessonsState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: LessonsState, metadata: ListMetadata): void {
    state.lessonListMetadata = metadata;
  },
  setList(state: LessonsState, lessons: Lesson[]): void {
    for (const lesson of lessons) {
      const id = lesson._id as unknown as string;
      state.lessonsMap[id] = lesson;
    }
    state.lessonsMap = { ...state.lessonsMap };
  },
  searchResults(state: LessonsState, lessons: Lesson[]): void {
    state.searchResults = {};
    for (const lesson of lessons) {
      const id = lesson._id as unknown as string;
      state.searchResults[id] = lesson;
    }
    state.searchResults = { ...state.searchResults };
  },
};

const actions = {
  async create(
    { commit, dispatch }: ActionContext<LessonsState, RootState>,
    { lesson }: { lesson: Partial<Lesson> }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post("/lesson", lesson);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Lekcja zostałą poprawnie utworzona.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },
  async getList({ commit }: ActionContext<LessonsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/lesson", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getSingle(
    { commit, state }: ActionContext<LessonsState, RootState>,
    { id, force }: { id: string; force: boolean }
  ): Promise<void> {
    try {
      if (state.lessonsMap[id] && !force) {
        return;
      }
      const { data } = await httpClient.api.get("/lesson", {
        params: { filters: `_id:${id}` },
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async update(
    { commit, dispatch }: ActionContext<LessonsState, RootState>,
    { lesson, lessonId }: { lesson: Partial<Lesson>; lessonId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.put(`/lesson/${lessonId}`, lesson);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane lekcji zostały poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async getCourseLessons({ commit }: ActionContext<LessonsState, RootState>, courseId: string): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/course/${courseId}/lessons`);
      commit("setList", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getCourseAvailableLessons({ commit }: ActionContext<LessonsState, RootState>, courseId: string): Promise<void> {
    try {
      const { data } = await httpClient.api.get(`/course/${courseId}/lessons/available`);
      commit("setList", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  getSearchResults: (state: LessonsState): Lesson[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: LessonsState): Lesson[] => {
    return Object.values(state.lessonsMap);
  },
  getListMetadata: (state: LessonsState): ListMetadata => {
    return state.lessonListMetadata;
  },
  getLatestQueryParams: (state: LessonsState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getById: (state: LessonsState): ((lessonId: string) => Lesson) => {
    return (lessonId: string) => {
      return state.lessonsMap[lessonId];
    };
  },
  getCourseLessons: (state: LessonsState): ((course: Course) => Lesson[]) => {
    return (course: Course) => {
      const courseLessonIds = (course?.lessons || []).map((item) => item.lessonId);
      return Object.values(state.lessonsMap).filter((lesson) => {
        return lesson._id ? courseLessonIds.includes(lesson._id.toString()) : false;
      });
    };
  },
};

export const lessonsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

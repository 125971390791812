
import { isEmpty } from "lodash";
import Vue, { PropType } from "vue";

import { Course, PrestonUser } from "@prestonly/preston-common";
import ReadMore from "@/components/base/ReadMore.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import CourseTileIcons from "@/components/base/CourseTileIcons.vue";
import Header from "@/components/v2/base/Header.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TextBtn from "@/components/v2/base/TextBtn.vue";
import ProgressBar from "@/components/base/ProgressBar.vue";

export default Vue.extend({
  name: "CourseTile",
  data() {
    return {
      isPreselected: false,
    };
  },
  components: {
    ProgressBar,
    CourseTileIcons,
    Header,
    Btn,
    TextBtn,
    ReadMore,
    TileWrapper,
  },
  props: {
    recommended: {
      type: Boolean,
      default: false,
    },
    started: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object as PropType<Course>,
      required: true,
    },
    userCourse: {
      type: Object,
      required: true,
    },
    showProgress: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    courseAction() {
      this.$emit("btnClicked", { courseId: this.course._id });
    },
    preselectCourse() {
      localStorage.setItem("preselectedCourse", this.course._id);
      this.$router.push({ name: "courses" });
    },
  },
  computed: {
    user(): PrestonUser | null {
      const user = this.$store.getters["user/getUser"];
      if (user && !isEmpty(user)) {
        return user;
      }
      return null;
    },
  },
  mounted() {
    const preselectedCourse = localStorage.getItem("preselectedCourse");
    const userLevel = this.user?.userMetadata?.["ENG"]?.level || ""; // TODO - add lang based on current context, when context is provided in another story
    if (preselectedCourse && preselectedCourse === this.course._id && this.course.level >= userLevel) {
      this.isPreselected = true;
    }
    localStorage.removeItem("preselectedCourse");
  },
});


import Vue from "vue";
import { mapGetters } from "vuex";
import { MediaFile } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";

interface TableOptions {
  itemsPerPage: number;
  page: number;
  sortBy: string[];
  sortDesc: boolean[];
}

export default Vue.extend({
  name: "AdminMediaLibrary",

  data: () => ({
    options: {
      itemsPerPage: 100,
      page: 1,
      sortBy: [],
      sortDesc: [],
    } as TableOptions,
    headers: [
      {
        text: "Nazwa",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Rozmiar",
        sortable: true,
        value: "size",
      },
      {
        text: "Link",
        sortable: false,
        value: "url",
      },
      {
        text: "Ostatnia modyfikacja",
        sortable: true,
        value: "date",
      },
      {
        width: 110,
        align: "end",
        sortable: false,
        value: "actions",
      },
    ],
    directory: "",
    loading: false,
  }),

  computed: {
    ...mapGetters({
      files: "media/getList",
      metadata: "media/getListMetadata",
    }),
  },

  methods: {
    async getFiles(): Promise<void> {
      this.loading = true;
      const filters: string[] = [];
      if (this.directory) {
        filters.push(`directory:${this.directory}`);
      }
      const payload = {
        filters: filters.join(";"),
        limit: this.options.itemsPerPage,
        page: this.options.page,
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy[0]) {
        payload["sort"] = `${sortBy[0]}:${sortDesc[0] ? "desc" : "asc"}`;
      }
      await this.$store.dispatch("media/getList", payload);
      this.loading = false;
    },
    handleOptionsChange(options: TableOptions) {
      this.options = options;
      this.getFiles();
    },
    isItemImage(item: MediaFile) {
      const ext = item.url.split(".").pop();
      const allowed = ["jpg", "jpeg", "png", "svg", "webp", "avif", "bmp", "ico"];
      return allowed.includes(ext?.toLowerCase() || "");
    },
    changeDirectory(directory: string) {
      this.options.page = 1;
      if (directory.endsWith("/")) {
        directory = directory.slice(0, -1);
      }
      this.directory = directory;
      this.getFiles();
    },
    moveDirectoryUp() {
      const parts = this.directory.split("/").filter((v) => v);
      parts.pop();
      this.changeDirectory(parts.join("/"));
    },
    convertBytesToReadable(bytes: number) {
      const sizes = ["B", "KB", "MB", "GB"];
      if (bytes === 0) return "0 B";
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
    },
    fileToBase64(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    },
    async addFile() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "MediaAddFileDialog",
        config: {
          title: "Wgraj nowy plik",
          submitBtnText: "Wgraj",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("media/addFile", {
          file: payload.file,
          directory: this.directory,
        });
        await this.getFiles();
      }
    },
    async deleteFile(file: MediaFile) {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usunięcie pliku",
          payload: {
            content: `Czy na pewno chcesz usunąć plik ${file.name}? Operacji tej nie można cofnąć!`,
          },
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("media/deleteFile", {
          key: file.key,
        });
        await this.getFiles();
      }
    },
    async renameFile(file: MediaFile) {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "MediaRenameFileDialog",
        config: {
          title: "Zmień nazwę pliku",
          submitBtnText: "Zmień",
          payload: {
            name: file.name,
          },
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("media/renameFile", {
          newName: payload.name,
          key: file.key,
        });
        await this.getFiles();
      }
    },
  },
});

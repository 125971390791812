
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";

import TagChips from "@/components/base/TagChips.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import SearchTextField from "@/components/form/inputs/SearchTextField.vue";
import LangSelector from "@/components/form/inputs/LangSelector.vue";
import SelectField from "@/components/form/inputs/SelectField.vue";
import { Exercise, LangCode } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminExercises",

  components: {
    SearchTextField,
    AudioButton,
    LangSelector,
    TagChips,
    SelectField,
  },

  data: function () {
    return {
      selected: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ["tags"],
        sortDesc: [false],
      },
      headers: [
        {
          width: 30,
          align: "start",
          sortable: false,
          value: "needAttention",
        },
        {
          width: 30,
          align: "start",
          sortable: false,
          value: "reportedBugs",
        },
        {
          text: "Przykład",
          align: "start",
          sortable: false,
          value: "example",
        },
        {
          text: "Odpowiedź",
          align: "start",
          sortable: false,
          value: "answer",
        },
        {
          text: "Wskazówki",
          align: "center",
          sortable: false,
          value: "hints",
        },
        {
          text: "Alternatywne odpowiedzi",
          align: "center",
          sortable: false,
          value: "alternativeAnswers",
        },
        {
          text: "Słówka",
          align: "center",
          sortable: false,
          value: "words",
        },
        {
          text: "Tagi",
          align: "center",
          sortable: true,
          value: "tags",
        },
        {
          width: 30,
          align: "end",
          sortable: false,
          value: "actions",
        },
      ],
      reportedBugStatusOptions: [
        { text: "-", value: "" },
        { text: "Zgłoszony", value: "REPORTED" },
        { text: "Zaakceptowany", value: "APPROVED" },
      ],
      search: "",
      reportedBugStatus: "",
      loading: false,
      exampleLang: LangCode.PL,
      answerLang: LangCode.ENG,
    };
  },

  computed: {
    ...mapGetters({
      exercises: "exercise/getSearchResults",
      metadata: "exercise/getListMetadata",
      queryParams: "exercise/getLatestQueryParams",
    }),
  },

  watch: {
    search() {
      this.options.page = 1;
    },

    language() {
      this.options.page = 1;
    },
  },

  methods: {
    copyLinks() {
      if (this.options.itemsPerPage > 25) {
        this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.ERROR,
              message: "Można skopiować maksymalnie 25 linków. Zmień liczbę wyświetlanych przykładów na dole listy.",
            },
          },
          { root: true }
        );
        return;
      }

      let content = "";
      for (const exercise of this.exercises) {
        content += `${window.location.protocol}//${window.location.host}/admin/exercises/${exercise._id}\n`;
      }
      navigator.clipboard.writeText(content);
      this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Linki zostały skopiowane do schowka.",
          },
        },
        { root: true }
      );
    },
    async onSourceLangChange() {
      await this.$store.dispatch("prestonState/setInterfaceLanguage", this.exampleLang);
      await this.getExercises();
    },
    async onTargetLangChange() {
      await this.$store.dispatch("prestonState/setLearnLanguage", this.answerLang);
      await this.getExercises();
    },
    onMenuInput(value: boolean) {
      if (!value) {
        this.selected = [];
      }
    },
    async copyExercise(exerciseId: string) {
      this.loading = true;
      await this.$store.dispatch("exercise/copy", { exerciseId });
      await this.getExercises();
      this.loading = false;
    },
    async removeExercise(exerciseId: string) {
      this.loading = true;
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń ćwiczenie",
        },
      });
      if (type === DialogCloseType.CLOSED) {
        this.loading = false;
        return;
      }

      await this.$store.dispatch("exercise/delete", { exerciseId });
      this.loading = false;
    },
    debouncedGetExercise: _.debounce(async function (this: any): Promise<void> {
      await this.getExercises();
    }, 500),
    async getExercises() {
      this.loading = true;
      const filters: string[] = [];
      if (this.search) {
        filters.push(`$search:${this.search}`);
      }
      if (this.exampleLang) {
        filters.push(`exampleLang:${this.exampleLang}`);
      }
      if (this.answerLang) {
        filters.push(`answerLang:${this.answerLang}`);
      }
      if (this.reportedBugStatus) {
        filters.push(`reportedBugs.status:${this.reportedBugStatus}`);
      }

      const payload = {
        limit: this.options.itemsPerPage,
        page: this.options.page,
        filters: filters.join(";"),
        options: ["includeReportedBugs:1"],
      };
      const { sortBy, sortDesc } = this.options;
      if (sortBy[0]) {
        payload["sort"] = `${sortBy[0]}:${sortDesc[0] ? "desc" : "asc"}`;
      }
      await this.$store.dispatch("exercise/getList", payload);
      this.loading = false;
    },
    hasReportedBug(exercise: Exercise) {
      return (
        exercise.reportedBugs &&
        exercise.reportedBugs.length > 0 &&
        exercise.reportedBugs.some((bug) => bug.status === "REPORTED")
      );
    },
    async copyRecordingUrlToClipboard(item: any, type: "example" | "answer") {
      const recording = await this.$store.dispatch("exercise/getRecording", {
        recordingId: item[`${type}Recording`],
      });
      navigator.clipboard.writeText(`${recording.content}`);

      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            title: "",
            type: SnackbarType.SUCCESS,
            message: "Link do nagrania skopiowany do schowka.",
          },
        },
        { root: true }
      );
    },
  },
  async mounted() {
    await this.getExercises();
  },
  created() {
    const { filters, limit, page } = this.queryParams || {};
    if (limit) {
      this.options.itemsPerPage = limit;
    }
    if (page) {
      this.options.page = page;
    }
    if (!filters) {
      return;
    }
    const filtersArr = filters.split(";");
    filtersArr.map((filter) => {
      if (filter.includes("answerLang")) {
        this.answerLang = filter.replace("answerLang:", "");
      }
      if (filter.includes("exampleLang")) {
        this.exampleLang = filter.replace("exampleLang:", "");
      }
      if (filter.includes("$search")) {
        this.search = filter.replace("$search:", "");
      }
    });
  },
});

import { Commit } from "vuex";
import { v4 as uuidv4 } from "uuid";

export interface SnackbarConfig {
  message: string;
  title?: string;
  type: SnackbarType;
  closeTimeout: number;
  deletable: boolean;
}

export enum SnackbarType {
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARN = "WARN",
  ERROR = "ERROR",
}

export const DEFAULT_SNACKBAR_CONFIG: SnackbarConfig = {
  closeTimeout: 5000,
  deletable: true,
  message: "Example content",
  type: SnackbarType.SUCCESS,
};

export interface SnackbarI {
  id: string;
  config: SnackbarConfig;
}

export class Snackbar implements SnackbarI {
  id: string;
  config: SnackbarConfig;

  constructor(config: SnackbarConfig, commit: Commit) {
    this.id = uuidv4();
    this.config = config;
    commit("open", this);
  }
}

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { LangCode, LevelingTestLevelQuestionsMap } from "@prestonly/preston-common";
import { httpClient } from "@/utils";

const actions = {
  async getQuestions(
    _: ActionContext<unknown, RootState>,
    { lang }: { lang: LangCode }
  ): Promise<LevelingTestLevelQuestionsMap | undefined> {
    try {
      const { data } = await httpClient.api.get(`/leveling-test/questions/${lang}`);
      return data;
    } catch (err) {
      console.error(err);
    }
  },
};

export const levelingTestStore = {
  namespaced: true,
  actions,
};

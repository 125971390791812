
import Vue from "vue";
import Alert from "@/components/v2/base/Alert.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "LessonAlert",
  components: { Alert, Btn },
  props: {
    mainText: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      required: true,
    },
    redirectName: {
      type: String,
      required: true,
    },
  },
  methods: {
    stopLearning() {
      this.$router.push({ name: this.redirectName, params: this.$route.params });
    },
  },
});


import Vue from "vue";

import { Exercise } from "@prestonly/preston-common";

import BaseExerciseForm from "@/components/form/BaseExerciseForm.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminEditExercise",

  components: {
    BaseExerciseForm,
  },

  computed: {
    exerciseId(): string {
      return this.$route.params.exerciseId;
    },
    exercise(): Exercise {
      return this.$store.getters["exercise/getById"](this.exerciseId);
    },
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminExercises" });
    },
    async saveChanges() {
      const form = this.$refs.exerciseForm as any;
      await form.saveChanges();
    },
    async getExercise() {
      await this.$store.dispatch("exercise/getSingle", { id: this.exerciseId, force: true });
    },
  },
  async created() {
    await this.getExercise();
    if (!this.exercise) {
      await this.$store.dispatch("snackbar/open", {
        config: {
          type: SnackbarType.ERROR,
          message: "Ćwiczenie nie istnieje.",
        },
      });
      await this.$router.push({ name: "adminExercises" });
    }
  },
});

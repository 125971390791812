
import Vue from "vue";

export default Vue.extend({
  name: "TileWrapper",
  props: {
    background: {
      type: String,
      default: "",
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    hoverEffectDisabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    animateSuccess: {
      type: Boolean,
      default: false,
    },
    animateError: {
      type: Boolean,
      default: false,
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "../index";
import { UserNote } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import router from "@/router";

interface UserNotesState {
  userNotesMap: Record<string, UserNote>;
  lessonsWithNotes: { lessonId: string; name: string }[];
  lessonId: string;
}

const state = (): UserNotesState => ({
  userNotesMap: {},
  lessonsWithNotes: [],
  lessonId: "",
});

const mutations = {
  setLessonId(state: UserNotesState, lessonId: string) {
    state.lessonId = lessonId;
  },
  setLessonsWithNotes(state: UserNotesState, data: { lessonId: string; name: string }[]) {
    state.lessonsWithNotes = data;
  },
  setList(state: UserNotesState, userNotes: UserNote[]): void {
    if (userNotes && Array.isArray(userNotes) && userNotes.length === 0) {
      state.userNotesMap = {};
      return;
    }
    const notes = {};
    for (const userNote of userNotes) {
      const id = userNote._id as unknown as string;
      notes[id] = userNote;
    }
    state.userNotesMap = notes;
  },
};

const actions = {
  async getLessonsWithNotes({ commit }: ActionContext<UserNotesState, RootState>) {
    try {
      const { data } = await httpClient.api.get("/user-note/get-lessons-with-notes");
      commit("setLessonsWithNotes", data);
    } catch (err) {
      console.error(err);
    }
  },
  async getListWithExercises({ commit, state }: ActionContext<UserNotesState, RootState>): Promise<void> {
    try {
      const route = router.currentRoute;
      const params = { options: ["populateExercises:1"] };
      if (state.lessonId || (route && route.params.lessonId)) {
        params["filters"] = `lessonId:${state.lessonId || route.params.lessonId}`;
      }
      const { data } = await httpClient.api.get("/user-note", {
        params,
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async createNote({ dispatch }: ActionContext<UserNotesState, RootState>, payload: Partial<UserNote>): Promise<void> {
    await httpClient.api.post("/user-note", payload);
    await dispatch("getListWithExercises");
  },

  async removeNote({ dispatch }: ActionContext<UserNotesState, RootState>, userNoteId: string): Promise<void> {
    await httpClient.api.delete(`/user-note/${userNoteId}`);
    await dispatch("getListWithExercises");
  },

  async patchNote(
    { dispatch }: ActionContext<UserNotesState, RootState>,
    { payload, userNoteId }: { payload: Partial<UserNote>; userNoteId: string }
  ): Promise<void> {
    await httpClient.api.patch(`/user-note/${userNoteId}`, { ...payload });
    await dispatch("getListWithExercises");
  },
};

const getters = {
  getLessonId: (state: UserNotesState): string => {
    return state.lessonId;
  },
  getLessonsWithNotes: (state: UserNotesState): { lessonId: string; name: string }[] => {
    return state.lessonsWithNotes;
  },
  getUserNotes: (state: UserNotesState): UserNote[] => {
    return Object.values(state.userNotesMap);
  },
  getNoteToExerciseIdMap: (state: UserNotesState): Record<string, UserNote> => {
    return Object.values(state.userNotesMap).reduce((map, note) => {
      map[note.exerciseId || ""] = note;
      return map;
    }, {} as Record<string, UserNote>);
  },
  getFavouriteSentences: (state: UserNotesState): UserNote[] => {
    return Object.values(state.userNotesMap).filter((note) => note.isFavourite);
  },
  getFavouriteSentencesMap: (state: UserNotesState): Record<string, UserNote> => {
    return Object.values(state.userNotesMap)
      .filter((note) => note.isFavourite)
      .reduce((map, note) => {
        map[note.exerciseId || ""] = note;
        return map;
      }, {} as Record<string, UserNote>);
  },
};

export const userNotesStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


import Vue, { PropType } from "vue";
import { CEFRLevel } from "@prestonly/preston-common";

export default Vue.extend({
  name: "CourseTileIcons",

  props: {
    lessonsCount: {
      type: Number,
      required: true,
    },
    cefrLevel: {
      type: Array as PropType<CEFRLevel[]>,
      required: true,
    },
  },
  computed: {
    maxCefrLevel(): string {
      const [maxLevel] = this.cefrLevel.slice(-1);
      return maxLevel;
    },
    minCefrLevel(): string {
      return this.cefrLevel[0];
    },
    cefrLevelTxt(): string {
      if (this.maxCefrLevel === this.minCefrLevel) {
        return this.maxCefrLevel;
      }
      return `${this.minCefrLevel} – ${this.maxCefrLevel}`;
    },
  },
  methods: {
    getLevelIcon() {
      const [level] = this.cefrLevel.slice(-1);
      return `level${"abc".indexOf(level[0].toLowerCase()) + 1}.svg`;
    },
  },
});

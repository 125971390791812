
import { LangAccess, LangCode, PrestonUser } from "@prestonly/preston-common";
import moment from "moment";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import Alert from "@/components/v2/base/Alert.vue";

export default Vue.extend({
  name: "UserProfileAdditionalInfoSubscription",
  components: { Alert },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    langAccessArray(): LangAccess[] {
      return this.user?.appMetadata?.languages || [];
    },
    hasActiveSubscriptions(): boolean {
      return this.langAccessArray.length > 0;
    },
    trialDaysLeft(): number {
      return this.$store.getters["user/getTrialDaysLeft"];
    },
    trialStarted(): boolean {
      return !!this.user?.appMetadata?.trialExpiresAt;
    },
    trialEndedAt(): string {
      return this.user?.appMetadata?.trialExpiresAt || "";
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
  },
  methods: {
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    langListItemContent(access: LangAccess): TranslateResult {
      if (!access.expiresAt) {
        return this.$t("userProfile.additionalInfo.subscription.langCourseActiveForever", {
          lang: this.$t(`languagesMap.${access.targetLang}.genitive`),
        });
      }

      const today = moment().startOf("day");
      const courseExpirationDay = moment(access.expiresAt).startOf("day");

      return today.isSameOrBefore(courseExpirationDay)
        ? this.$t("userProfile.additionalInfo.subscription.langCourseActive", {
            lang: this.$t(`languagesMap.${access.targetLang}.genitive`),
            date: courseExpirationDay.format("DD.MM.YYYY"),
          })
        : this.$t("userProfile.additionalInfo.subscription.langCourseInactive", {
            lang: this.$t(`languagesMap.${access.targetLang}.genitive`),
            date: courseExpirationDay.format("DD.MM.YYYY"),
          });
    },
    async buyAccess(lang?: LangCode) {
      await this.$store.dispatch("prestonState/setLearnLanguage", lang || this.learnLanguage).then(() => {
        this.$router.push({ name: "subscribe" });
      });
    },
  },
});

import axios, { AxiosInstance } from "axios";
import { getEnv } from "@/utils";

declare interface HttpClient {
  api: AxiosInstance;
}

const defaultAxiosInstanceConfig = {
  timeout: 60000, // 60s
};

const api = axios.create({
  ...defaultAxiosInstanceConfig,
  baseURL: getEnv("VUE_APP_API_PREFIX"),
});

export const httpClient: HttpClient = {
  api,
};


import Vue, { PropType } from "vue";
import { AlternativeAnswer } from "@prestonly/preston-common";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import List from "@ckeditor/ckeditor5-list/src/list";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";

export default Vue.extend({
  name: "AlternativeAnswersTable",

  components: {
    TextareaField,
    ckeditor: CKEditor.component,
  },
  props: {
    value: Array as PropType<AlternativeAnswer[]>,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Odpowiedź", align: "start", value: "text" },
        { text: "Zatwierdzona", value: "approved" },
        { text: "Ukryta", value: "hidden" },
        { text: "Komentarz dla użytkownika", value: "comment" },
        { text: "Akcje", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        text: "",
        approved: true,
        hidden: false,
        comment: "",
      } as AlternativeAnswer,
      defaultItem: {
        text: "",
        approved: true,
        hidden: false,
        comment: "",
      } as AlternativeAnswer,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [Essentials, Paragraph, Bold, Italic, Strikethrough, Underline, List, Table, TableToolbar],
        toolbar: {
          items: [
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
      },
    };
  },

  computed: {
    formTitle(): string {
      return this.editedIndex === -1 ? "Nowa odpowiedź" : "Edytuj odpowiedź";
    },
    isFormInvalid(): boolean {
      return this.editedItem.text.length === 0;
    },
  },

  watch: {
    dialog(val): void {
      val || this.close();
    },
    dialogDelete(val): void {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item: AlternativeAnswer) {
      this.editedIndex = this.value.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item: AlternativeAnswer) {
      this.editedIndex = this.value.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.value.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.value[this.editedIndex], this.editedItem);
      } else {
        this.value.push(this.editedItem);
      }
      this.close();
    },
  },
});


import Vue, { PropType } from "vue";
import { Exercise } from "@prestonly/preston-common";
import { AlternativeAnswer } from "@prestonly/preston-common";
import AudioButton from "@/components/base/AudioButton.vue";

export default Vue.extend({
  name: "TrainerAlternativeAnswers",
  components: { AudioButton },
  props: {
    exercise: Object as PropType<Exercise>,
    alternativeAnswers: {
      type: Array as PropType<AlternativeAnswer[]>,
      required: false,
      default: () => [],
    },
  },
  computed: {
    _alternativeAnswers(): AlternativeAnswer[] {
      return (this.alternativeAnswers || this.exercise?.alternativeAnswers || []).filter((answer) => {
        if ("approved" in answer && "hidden" in answer) {
          return answer.approved && !answer.hidden;
        }
        return true;
      });
    },
    hasAlternativeAnswers(): boolean {
      return this._alternativeAnswers.length > 0;
    },
  },
});

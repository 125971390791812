import { ActionContext } from "vuex";
import { RootState } from "../index";
import { BaseListFilters, CEFRLevel, Course, ListMetadata } from "@prestonly/preston-common";
import { httpClient } from "@/utils";
import { SnackbarType } from "@/types/snackbar";

interface CoursesState {
  recommendedMap: Record<string, Course>;
  coursesMap: Record<string, Course>;
  searchResults: Record<string, Course>;
  courseListMetadata: ListMetadata;
  latestQueryParams: BaseListFilters;
}

const state = (): CoursesState => ({
  recommendedMap: {},
  courseListMetadata: { total: 0 },
  coursesMap: {},
  searchResults: {},
  latestQueryParams: {},
});

const mutations = {
  setRecommended(state: CoursesState, courses: Course[]): void {
    if (courses.length === 0) {
      state.recommendedMap = {};
      return;
    }
    for (const course of courses) {
      const id = course._id as unknown as string;
      state.recommendedMap[id] = course;
    }
    state.recommendedMap = { ...state.recommendedMap };
  },
  setLatestQueryParams(state: CoursesState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: CoursesState, metadata: ListMetadata): void {
    state.courseListMetadata = metadata;
  },
  setList(state: CoursesState, courses: Course[]): void {
    const newCourses = {};
    for (const course of courses) {
      const id = course._id as unknown as string;
      newCourses[id] = course;
    }
    state.coursesMap = { ...newCourses };
  },
  searchResults(state: CoursesState, courses: Course[]): void {
    state.searchResults = {};
    for (const course of courses) {
      const id = course._id as unknown as string;
      state.searchResults[id] = course;
    }
    state.searchResults = { ...state.searchResults };
  },
  resetState(state: CoursesState): void {
    state.courseListMetadata = { total: 0 };
    state.coursesMap = {};
    state.searchResults = {};
    state.latestQueryParams = {};
  },
};

const actions = {
  async getRecommended(
    { commit }: ActionContext<CoursesState, RootState>,
    { cefrLevel }: { cefrLevel: CEFRLevel }
  ): Promise<void> {
    try {
      commit("setRecommended", []);
      const { data } = await httpClient.api.get(`/course/recommended/${cefrLevel}`);
      commit("setRecommended", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async getAvailable({ commit }: ActionContext<CoursesState, RootState>): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/course/available");
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async getList({ commit }: ActionContext<CoursesState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/course", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async getSingleAvailable({ commit }: ActionContext<CoursesState, RootState>, courseId: string): Promise<void> {
    try {
      const { data: course } = await httpClient.api.get(`/course/available/${courseId}`);
      commit("setList", [course]);
    } catch (err) {
      console.error(err);
    }
  },

  async getSingle({ commit, state }: ActionContext<CoursesState, RootState>, courseId: string): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/course", {
        params: { filters: `_id:${courseId}` },
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },

  async create(
    { commit, dispatch }: ActionContext<CoursesState, RootState>,
    { course }: { course: Partial<Course> }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post("/course", course);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Kurs zostały poprawnie utworzony.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async update(
    { commit, dispatch }: ActionContext<CoursesState, RootState>,
    { course, courseId }: { course: Partial<Course>; courseId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.put(`/course/${courseId}`, course);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane kursu zostały poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async changeLessonOrder(
    { commit, state }: ActionContext<CoursesState, RootState>,
    { courseId, lessonId, type }
  ): Promise<void> {
    // TODO - probably depreacated
    const { data } = await httpClient.api.put(`/course/${courseId}/change-lesson-order`, {
      lessonId,
      type,
      courseId,
    });
    commit("setList", [data]);
  },
};

const getters = {
  getRecommended: (state: CoursesState): Course[] => {
    return Object.values(state.recommendedMap);
  },
  getRecommendedIds: (state: CoursesState): string[] => {
    return Object.keys(state.recommendedMap);
  },
  getSearchResults: (state: CoursesState): Course[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: CoursesState): Course[] => {
    return Object.values(state.coursesMap);
  },
  getListMetadata: (state: CoursesState): ListMetadata => {
    return state.courseListMetadata;
  },
  getLatestQueryParams: (state: CoursesState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getMap: (state: CoursesState): Record<string, Course> => {
    return state.coursesMap;
  },
  getCourseById: (state: CoursesState): ((courseId: string) => Course) => {
    return (courseId: string) => {
      return state.coursesMap[courseId];
    };
  },
};

export const coursesStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

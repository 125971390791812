
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import UserProfileBaseInfoForm from "@/components/userProfile/UserProfileBaseInfoForm.vue";
import UserProfileAdditionalInfo from "@/components/userProfile/UserProfileAdditionalInfo/UserProfileAdditionalInfo.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import UserProfilePreferences from "@/components/userProfile/UserProfilePreferences.vue";
import UserProfileActions from "@/components/userProfile/UserProfileActions.vue";
import UserProfileAdditionalInfoSubscription from "@/components/userProfile/UserProfileAdditionalInfo/UserProfileAdditionalInfoSubscription.vue";
import UserProfileInvoiceForm from "@/components/userProfile/UserProfleInvoiceForm.vue";
import UserProfilePaymentHistory from "@/components/userProfile/UserProfilePaymentHistory.vue";
import UserProfileRedeemGiftCard from "@/components/userProfile/UserProfileRedeemGiftCard.vue";
import UserProfileCompanyData from "@/components/userProfile/UserProfileCompanyData.vue";

enum Page {
  BasicData,
  Payment,
}

export default Vue.extend({
  name: "UserProfile",
  metaInfo() {
    return {
      title: this.$t("metadata.userProfile.title").toString(),
    };
  },
  components: {
    Header,
    UserProfileBaseInfoForm,
    UserProfileAdditionalInfo,
    UserProfileRedeemGiftCard,
    TileWrapper,
    UserProfilePreferences,
    UserProfileActions,
    UserProfileAdditionalInfoSubscription,
    UserProfileInvoiceForm,
    UserProfilePaymentHistory,
    UserProfileCompanyData,
  },

  watch: {
    page(value) {
      if (value === Page.Payment) {
        this.$nextTick(function () {
          this.invoiceFormRef = this.$refs.invoiceFormRef;
        });
      }
    },
  },

  data: () => {
    return {
      invoiceFormRef: undefined as any,
      page: Page.BasicData,
      Page,
    };
  },

  async created() {
    await this.$store.dispatch("order/getList");
  },

  methods: {
    changePage(newPage: Page) {
      this.page = newPage;
    },
  },
});

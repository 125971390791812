
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "Loader",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    textTop: {
      type: String,
      default: "",
    },
    textBottom: {
      type: String as PropType<TranslateResult>,
      default: "",
    },
  },
  watch: {
    loading: function (loadingState) {
      if (window && !window.ReactNativeWebView?.postMessage) {
        return;
      }
      if (loadingState) {
        window.ReactNativeWebView?.postMessage("loader:start");
      } else {
        window.ReactNativeWebView?.postMessage("loader:stop");
      }
    },
  },
  computed: {
    defaultTextBottom() {
      return this.$t("loading");
    },
  },
});

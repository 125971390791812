
import Vue from "vue";
import { getInstance } from "@/auth";

export default Vue.extend({
  name: "Login",
  methods: {
    login() {
      const authService = getInstance();
      authService.loginWithRedirect({ appState: { targetUrl: "/course" } });
    },
  },
});

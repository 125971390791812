
import Header from "@/components/v2/base/Header.vue";
import { Order, PaymentStatus, PrestonUser } from "@prestonly/preston-common";
import moment from "moment";
import Vue from "vue";
import Btn from "../v2/base/Btn.vue";
import SimpleAlert from "../v2/base/SimpleAlert.vue";
import { SnackbarType } from "@/types/snackbar";
import _ from "lodash";

export default Vue.extend({
  components: { Header, Btn, SimpleAlert },
  name: "UserProfilePaymentHistory",
  props: {
    invoiceFormRef: {
      type: Object,
    },
  },
  data() {
    return {
      openRows: [] as number[],
    };
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    orders(): Order[] {
      return this.$store.getters["order/getList"];
    },
    hasOrders(): boolean {
      return this.orders.length > 0;
    },
    isInvoiceDataComplete(): boolean {
      return this.invoiceFormRef?.valid || false;
    },
  },

  methods: {
    getLabelColor(status: PaymentStatus) {
      switch (status) {
        case PaymentStatus.COMPLETED:
          return "#32A071";
        case PaymentStatus.CANCELED:
          return "#FF3A44";
        default:
          return "secondary";
      }
    },
    toggleRow(rowNumber: number) {
      if (this.openRows.includes(rowNumber)) {
        this.openRows = this.openRows.filter((rowNum) => rowNum !== rowNumber);
        return;
      }
      this.openRows.push(rowNumber);
    },
    formatDate(rawDate: string) {
      return moment(rawDate).format("DD.MM.YYYY");
    },
    formatCost(rawCost: number) {
      return Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: "PLN",
      }).format(rawCost / 100);
    },
    async requestInvoice(orderId: string) {
      if (!this.isInvoiceDataComplete) {
        return;
      }
      if (!_.isEqual(this.invoiceFormRef?.invoice, this.user.userMetadata.invoiceData)) {
        await this.invoiceFormRef?.saveInvoice();
      }
      const result = await this.$store.dispatch("order/requestInvoice", orderId);
      if (result) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("userProfile.payment.historyTable.requestSent"),
            },
          },
          { root: true }
        );
      }
    },
  },
});


import Vue from "vue";
import { UserPreferences } from "@prestonly/preston-common";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "UserProfileExerciseCheckboxes",
  components: { Btn },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: true,
    },
    saveOnUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        fullSentence: true,
        gaps: true,
        blocks: true,
      },
    };
  },
  computed: {
    selectedOptionsLength(): number {
      return Object.values(this.options)
        .map((v) => (v ? 1 : 0))
        .reduce((sum: number, v: number) => {
          return v ? sum + 1 : sum;
        }, 0);
    },
  },
  mounted() {
    if (this.saveOnUser) {
      const userPreferences: UserPreferences = this.$store.getters["user/preferences"];
      this.options = {
        ...userPreferences.listeningAndWritingActivities,
      };
      return;
    }
    this.initFromSessionStorage();
  },
  methods: {
    saveSessionStorage() {
      sessionStorage.setItem("listeningAndWritingActivities", JSON.stringify(this.options));
    },
    initFromSessionStorage() {
      const userPreferences: UserPreferences = this.$store.getters["user/preferences"];
      const sessionActivities = sessionStorage.getItem("listeningAndWritingActivities");

      if (sessionActivities) {
        this.options = { ...JSON.parse(sessionActivities) };
      } else {
        this.options = {
          ...userPreferences.listeningAndWritingActivities,
        };
      }
    },
    optionChanged() {
      if (this.saveOnUser) {
        this.$emit("optionChanged", this.options);
        return;
      }
      this.saveSessionStorage();
    },
    async resetListeningAndWritingActivities() {
      this.options = {
        fullSentence: true,
        gaps: true,
        blocks: true,
      };
      this.optionChanged();
      sessionStorage.removeItem("listeningAndWritingActivities");
    },
  },
});

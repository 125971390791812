
import Vue, { PropType } from "vue";

import Btn from "@/components/v2/base/Btn.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import { CouponVerificationStatus, DiscountConditionType, VerifiedCoupon } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";
import moment from "moment/moment";

export default Vue.extend({
  name: "BasketCouponForm",
  components: {
    Btn,
    TextField,
  },
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    endOfSubscriptionDate: {
      type: String,
      default: "",
    },
    disallowedCouponTypes: {
      type: Array as PropType<DiscountConditionType[]>,
      default: () => [],
    },
    selectedPlan: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      code: "",
    };
  },
  computed: {
    DiscountConditionType() {
      return DiscountConditionType;
    },
    verifiedCouponType(): DiscountConditionType | null {
      return this.verifiedCoupon?.discountCondition?.type || null;
    },
    isCouponAllowedForSelectedPlan(): boolean {
      if (this.verifiedCoupon?.discountCondition?.applyTo) {
        const applyToPlans = this.verifiedCoupon.discountCondition.applyTo.map(({ id }) => id);
        return applyToPlans.includes(this.selectedPlan.productCode);
      }
      return true;
    },
    isCouponAllowed(): boolean {
      if (!this.verifiedCoupon) {
        return true;
      }
      if (this.disallowedCouponTypes.includes(this.verifiedCoupon.discountCondition.type)) {
        return false;
      }
      return true;
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
    finalDate(): string {
      if (this.verifiedCoupon?.discountCondition.type === DiscountConditionType.ADDITIONAL_MONTHS) {
        const start = moment(this.endOfSubscriptionDate, "DD.MM.YYYY");
        return start.add(this.verifiedCoupon.discountCondition.amount, "months").format("DD.MM.YYYY");
      }
      return "";
    },
  },
  methods: {
    async verify(code: string, showSnackbar = true) {
      const verificationStatus = await this.$store.dispatch("basket/verifyCoupon", code);
      this.selectPlanForCoupon();
      if (!this.isCouponAllowed) {
        this.removeVerifiedCoupon();
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.WARN,
              message: this.$t("basketCouponForm.verificationMsg.notAllowed"),
            },
          },
          { root: true }
        );
        return;
      }
      if (verificationStatus.status === CouponVerificationStatus.VERIFIED && showSnackbar) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("basketCouponForm.verificationMsg.valid"),
            },
          },
          { root: true }
        );
        return;
      }
      if (verificationStatus.status === CouponVerificationStatus.INVALID && showSnackbar) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.ERROR,
              message:
                this.$t(`basketCouponForm.verificationMsg.${verificationStatus.message}`) ||
                this.$t("somethingWentWrongPleaseTryAgain"),
            },
          },
          { root: true }
        );
      }
    },
    removeVerifiedCoupon() {
      this.code = "";
      this.$store.commit("basket/removeCoupon");
    },
    selectPlanForCoupon() {
      if (!this.verifiedCoupon) return;
      const plans = this.$store.getters["prestonState/getPlans"];
      const matchedPlan = plans.find((plan) =>
        this.verifiedCoupon?.discountCondition?.applyTo?.some((applyTo) => applyTo.id === plan.productCode)
      );
      if (!matchedPlan) return;
      this.$emit("plan-changed", matchedPlan);
    },
  },
  async mounted() {
    if (this.route.name === "subscribe" && this.$route.query && this.$route.query["code"]) {
      await this.verify(this.$route.query["code"] as string, false);
    }
  },
});


import Vue from "vue";
import CourseTile from "@/components/base/CourseTile.vue";
import EndListText from "@/components/base/EndListText.vue";
import SelectField from "@/components/form/inputs/SelectField.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import { mapGetters } from "vuex";
import { Course, LangCode, LevelingTestStatus, PrestonUser } from "@prestonly/preston-common";
import _ from "lodash";
import Header from "@/components/v2/base/Header.vue";
import CheckboxField from "@/components/form/inputs/CheckboxField.vue";
import NextLessonRecommendationBanner from "@/components/base/NextLessonRecommendationBanner.vue";
export default Vue.extend({
  name: "Courses",
  metaInfo() {
    return {
      title: this.$t("metadata.courses.title").toString(),
    };
  },
  components: {
    CheckboxField,
    SelectField,
    TextField,
    CourseTile,
    EndListText,
    Header,
    NextLessonRecommendationBanner,
  },
  data() {
    return {
      loading: true,
      userCoursesMap: {},
      rememberFilters: false,
      startedCoursesModel: false,
      name: "",
      level: "",
    };
  },
  computed: {
    ...mapGetters({
      courses: "course/getList",
    }),
    startedCoursesIds(): string[] {
      return Object.entries(this.userCoursesMap)
        .filter(([...[, value]]: any) => value.startedAt)
        .map(([key]) => key);
    },
    courseLevels(): Array<{ text: string; value: string }> {
      return _.chain(this.courses)
        .map<{ text: string; value: string }>((course: Course) => ({
          text: course.level,
          value: course.level,
        }))
        .uniq()
        .orderBy("text")
        .value();
    },
    coursesFiltered(): Course[] {
      const filteredWithStartedCourses: Course[] = this.startedCoursesModel
        ? this.startedCoursesIds.map((id) => this.findStartedCourse(id)).filter(Boolean)
        : this.courses;
      const filteredWithName = this.name
        ? filteredWithStartedCourses.filter((course) => course.name.toLowerCase().includes(this.name.toLowerCase()))
        : filteredWithStartedCourses;
      const filteredWithLevel = this.level
        ? filteredWithName.filter((course) => course.levels.includes(this.level))
        : filteredWithName;
      return _.orderBy(filteredWithLevel, "name");
    },
    recommendedCourses(): Course[] {
      return this.coursesFiltered.filter((course) => this.recommendedCourseIds.includes(course._id));
    },
    remainingCourses(): Course[] {
      return this.coursesFiltered.filter((course) => !this.recommendedCourseIds.includes(course._id));
    },
    isFilterApplied(): boolean {
      return !!(this.startedCoursesModel || this.name || this.level);
    },
    noFilteredResults(): boolean {
      return !!(this.isFilterApplied && this.coursesFiltered.length === 0);
    },
    endListText(): string {
      return this.$t(this.noFilteredResults ? "noFilteredResults" : "endOfList").toString();
    },
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },

    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },

    recommendedCourseIds(): string[] {
      return this.$store.getters["course/getRecommendedIds"] || [];
    },
  },
  methods: {
    storeFiltersInLocalStorage() {
      if (this.rememberFilters) {
        const filters = {
          rememberFilters: this.rememberFilters,
          startedCoursesModel: this.startedCoursesModel,
          name: this.name,
          level: this.level,
        };
        localStorage.setItem(`prestonState:${this.learnLanguage}:courseFilters`, JSON.stringify(filters));
      } else {
        localStorage.removeItem(`prestonState:${this.learnLanguage}:courseFilters`);
      }
    },
    getFiltersFromLocalStorage() {
      let filters = localStorage.getItem(`prestonState:${this.learnLanguage}:courseFilters`);
      if (filters) {
        const parsedFilters = JSON.parse(filters);
        if (parsedFilters && parsedFilters.rememberFilters) {
          this.rememberFilters = parsedFilters.rememberFilters;
          this.startedCoursesModel = parsedFilters.startedCoursesModel;
          this.name = parsedFilters.name;
          this.level = parsedFilters.level;
        }
      }
    },
    onRememberFilters() {
      this.rememberFilters = !this.rememberFilters;
      this.storeFiltersInLocalStorage();
    },
    findStartedCourse(id: string): Course {
      return this.courses.find((course) => course._id === id);
    },
    onBtnClicked(params: { courseId: string }): void {
      if (this.userCoursesMap[params.courseId] && this.userCoursesMap[params.courseId].finishedAt) {
        this.$router.push({
          name: "exercises",
        });
        return;
      }
      this.$router.push({
        name: "courseDetails",
        params,
      });
    },
    resetFilters(): void {
      this.name = "";
      this.level = "";
      this.startedCoursesModel = false;
      this.rememberFilters = false;
    },
    async getProgress(): Promise<void> {
      this.userCoursesMap = await this.$store.dispatch("progress/getProgress");
    },
    async getCourses(): Promise<void> {
      await this.$store.dispatch("course/getAvailable", {
        langCode: this.$store.getters["prestonState/getLearnLanguage"],
      });
    },
    async getRecommended(): Promise<void> {
      if (
        !this.learnLanguage ||
        this.user?.userMetadata[this.learnLanguage]?.levelingTestStatus !== LevelingTestStatus.FINISHED
      ) {
        return;
      }
      await this.$store.dispatch("course/getRecommended", {
        cefrLevel: this.user?.userMetadata[this.learnLanguage]?.level,
      });
    },
  },
  async created() {
    this.loading = true;
    await Promise.all([this.getCourses(), this.getProgress(), this.getRecommended()]);
    this.loading = false;
  },
  mounted() {
    this.getFiltersFromLocalStorage();
  },
  watch: {
    learnLanguage() {
      this.resetFilters();
      this.loading = true;
      Promise.all([this.getCourses(), this.getProgress(), this.getRecommended()]).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 800);
      });
      this.getFiltersFromLocalStorage();
    },
    name() {
      this.storeFiltersInLocalStorage();
    },
    level() {
      this.storeFiltersInLocalStorage();
    },
    startedCoursesModel() {
      this.storeFiltersInLocalStorage();
    },
  },
});


import Vue from "vue";

import Header from "@/components/v2/base/Header.vue";
import UserNotesList from "@/components/userNote/UserNotesList.vue";

export default Vue.extend({
  name: "Notes",
  components: { UserNotesList, Header },
});


import Vue from "vue";
import LevelingTestQuiz from "@/components/levelingTest/LevelingTestQuiz.vue";
import LevelingTestIntroduction from "@/components/levelingTest/LevelingTestIntroduction.vue";

export default Vue.extend({
  name: "LevelingTest",
  metaInfo() {
    return {
      title: this.$t("metadata.levelingTest.title").toString(),
    };
  },
  components: {
    LevelingTestQuiz,
    LevelingTestIntroduction,
  },
  data() {
    return {
      started: false,
    };
  },
  computed: {
    language(): string {
      return (this.$route.params.lang || "").toUpperCase();
    },
  },
  async created() {
    const language = this.$store.getters["prestonState/getLearnLanguage"];
    if (language !== this.language) {
      await this.$router.push({ name: "levelingTest", params: { lang: language.toLowerCase() } });
      return;
    }
  },
});


import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import { UserMetadataAction, UserPreferences } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";
import UserProfileExerciseCheckboxes from "@/components/userProfile/UserProfileExerciseCheckboxes.vue";

export default Vue.extend({
  name: "UserProfilePreferences",
  components: { UserProfileExerciseCheckboxes, Header, TextField },
  data() {
    return {
      loading: false,
      preferredLessonTime: 0,
      autoplayAnswer: false,
      lessonSoundsAllowed: false,
      appNotificationsAllowed: false,
      randomizeRepetitionOrder: false,
      listeningAndWritingActivities: {
        fullSentence: true,
        gaps: true,
        blocks: true,
      },
    } as UserPreferences & { loading: boolean };
  },
  mounted() {
    const userPreferences: UserPreferences = this.$store.getters["user/preferences"];
    this.preferredLessonTime = userPreferences.preferredLessonTime;
    this.autoplayAnswer = userPreferences.autoplayAnswer;
    this.lessonSoundsAllowed = userPreferences.lessonSoundsAllowed;
    this.appNotificationsAllowed = userPreferences.appNotificationsAllowed;
    this.randomizeRepetitionOrder = userPreferences.randomizeRepetitionOrder;
  },
  methods: {
    async onExerciseOptionChange(options: any) {
      this.listeningAndWritingActivities = { ...options };
      await this.updatePreferences();
    },
    async updatePreferences() {
      this.loading = true;
      const result = await this.$store.dispatch("user/patchUser", {
        action: UserMetadataAction.PREFERENCES,
        payload: {
          preferences: {
            preferredLessonTime: Number(this.preferredLessonTime),
            autoplayAnswer: this.autoplayAnswer,
            lessonSoundsAllowed: this.lessonSoundsAllowed,
            appNotificationsAllowed: this.appNotificationsAllowed,
            randomizeRepetitionOrder: this.randomizeRepetitionOrder,
            listeningAndWritingActivities: {
              ...this.listeningAndWritingActivities,
            },
          } as UserPreferences,
        },
      });
      this.loading = false;
      if (result) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("userProfile.snackbar.success"),
            },
          },
          { root: true }
        );
        return;
      }
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: this.$t("userProfile.snackbar.error"),
          },
        },
        { root: true }
      );

      sessionStorage.removeItem("listeningAndWritingActivities");
    },
    async updateLessonLength() {
      await this.updatePreferences();
      await this.$store.commit("timer/resetState");
    },
  },
});

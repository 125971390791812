
import { httpClient } from "@/utils";
import { LangCode } from "@prestonly/preston-common";
import _ from "lodash";
import Vue from "vue";

interface Stats {
  usersCount: number;
  newUsers: Count[];
  newSubscriptions: Count[];
  activeSubscriptions: Count[];
  revenue: Count[];
  activeTrials: Count[];
  trialsWithoutSubscription: Count[];
  usersWithExpiredSubscription: Count[];
  allFinishedExercises: Count[];
  recentlyFinishedExercises: Count[];
}

interface Count {
  _id?: string;
  total: number;
}

export default Vue.extend({
  name: "AdminStats",

  data() {
    return {
      loading: true,
      stats: {
        usersCount: 0,
        newUsers: [],
        newSubscriptions: [],
        activeSubscriptions: [],
        revenue: [],
        activeTrials: [],
        trialsWithoutSubscription: [],
        usersWithExpiredSubscription: [],
        allFinishedExercises: [],
        recentlyFinishedExercises: [],
      } as Stats,
    };
  },

  components: {},

  async mounted() {
    this.stats = (await httpClient.api.get("/admin/stats")).data;
    this.loading = false;
  },

  computed: {
    revenue(): string {
      const formatter = new Intl.NumberFormat("pl-PL", {
        style: "currency",
        currency: "PLN",
      });
      return formatter.format(this.stats.revenue[0]?.total / 100) || "-";
    },
    activeSubscriptions(): [string, { sourceLang: string; targetLang: string; duration: string; total: number }[]][] {
      const SORT_ORDER = {
        [LangCode.ENG]: 0,
        [LangCode.DE]: 1,
        [LangCode.ES]: 2,
      };
      const parsedData = this.stats.activeSubscriptions.map((item) => {
        const [, duration, sourceLang, targetLang] = (item._id || "").split(":");
        return {
          sourceLang,
          targetLang,
          duration,
          total: item.total,
        };
      });
      const groupedData = _.groupBy(parsedData, (item) => item.targetLang);
      return Object.entries(groupedData).sort((a, b) => SORT_ORDER[a[0]] - SORT_ORDER[b[0]]);
    },
    newUsers(): Count[] {
      return _.orderBy(
        this.stats.newUsers.map((item) => ({ ...item, _id: item._id?.split("T")[0] })),
        (item) => item._id,
        "asc"
      );
    },
    newSubscriptions(): Count[] {
      return _.orderBy(this.stats.newSubscriptions, (item) => item._id, "asc");
    },
  },
});

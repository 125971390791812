
import { isEmpty } from "lodash";

import { validator, VueWithValidator } from "@/utils/validators";
import { PrestonUser } from "@prestonly/preston-common";

import Btn from "@/components/v2/base/Btn.vue";
import Header from "@/components/v2/base/Header.vue";
import Card from "@/components/v2/base/Card.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import CheckboxField from "@/components/form/inputs/CheckboxField.vue";
import { InvoiceBaseData, PersonalData } from "@prestonly/preston-common";
import InvoiceForm from "./InvoiceForm.vue";

interface BaseFormData {
  valid: boolean;
  baseData: Partial<PersonalData>;
  invoice: InvoiceBaseData;
}

export default VueWithValidator.extend({
  name: "BasketForm",

  components: {
    CheckboxField,
    TextField,
    Header,
    Card,
    Btn,
    InvoiceForm,
  },

  mixins: [validator],

  props: {
    emailRequired: {
      type: Boolean,
      default: false,
    },
    payBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data(): BaseFormData {
    return {
      valid: false,
      baseData: {
        firstname: "",
        lastname: "",
        email: "",
        country: this.$t("countries.poland") as string,
        consumerAgreement: false,
      },
      invoice: {
        issueInvoice: false,
        companyName: "",
        nip: "",
        firstname: "",
        lastname: "",
        street: "",
        zip: "",
        city: "",
        country: this.$t("countries.poland") as string,
      },
    };
  },

  computed: {
    email(): string {
      if (!this.user || isEmpty(this.user)) {
        return "";
      }
      return this.user.email;
    },
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
  },

  methods: {
    async saveChanges(): Promise<void> {
      await this.validate();
      if (!this.valid) {
        return;
      }
      if (!this.emailRequired) {
        this.baseData.email = this.email;
      }
      this.$emit("formSubmitted", {
        buyer: this.baseData,
        invoice: this.invoice,
      });
    },
  },
  mounted(): void {
    const { invoiceData, personalData } = this.user?.userMetadata || {};
    if (invoiceData) {
      this.invoice.companyName = invoiceData.companyName || "";
      this.invoice.nip = invoiceData.nip || "";
      this.invoice.firstname = invoiceData.firstname || "";
      this.invoice.lastname = invoiceData.lastname || "";
      this.invoice.street = invoiceData.street || "";
      this.invoice.zip = invoiceData.zip || "";
      this.invoice.city = invoiceData.city || "";
      this.invoice.country = invoiceData.country || (this.$t("countries.poland") as string);
    }
    if (personalData) {
      this.baseData.firstname = personalData.firstname || "";
      this.baseData.lastname = personalData.lastname || "";
      this.baseData.country = personalData.country || (this.$t("countries.poland") as string);
    }
  },
});


import Vue, { PropType } from "vue";
import { Exercise, Note, UserNote } from "@prestonly/preston-common";
import { DEFAULT_DIALOG_CONFIG, DialogCloseType, DialogConfig } from "@/types/dialog";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AddNoteBtn",

  props: {
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    userNotes(): UserNote[] {
      return this.$store.getters["userNote/getUserNotes"];
    },
    existingNote(): UserNote | null {
      return this.$store.getters["userNote/getNoteToExerciseIdMap"][this.currentItem._id];
    },
    hasTextNote(): boolean {
      return !!this.existingNote && !!this.existingNote?.note;
    },
  },

  methods: {
    async addNote() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "AddNote",
        config: {
          ...DEFAULT_DIALOG_CONFIG,
          title: this.$t(`dialogs.note.${this.hasTextNote ? "editNoteTitle" : "addNoteTitle"}`),
          payload: {
            exercise: this.currentItem,
            existingNote: this.existingNote,
          },
          closeBtnText: this.$t("dialogs.note.cancel"),
          submitBtnText: this.$t("dialogs.note.send"),
        } as DialogConfig,
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      const note: Note = {
        rawNote: payload.note,
        htmlNote: payload.note,
      };

      if (this.existingNote) {
        await this.$store.dispatch("userNote/patchNote", {
          userNoteId: this.existingNote._id,
          payload: { note },
        });
      } else {
        await this.$store.dispatch("userNote/createNote", {
          targetLang: this.currentItem.answerLang,
          exerciseId: this.currentItem._id,
          isFavourite: false,
          note: note,
        });
      }
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            closeTimeout: 3000,
            type: SnackbarType.SUCCESS,
            title: this.$t("notesPage.noteSavedCorrectly"),
            message: "",
          },
        },
        { root: true }
      );
      this.$emit("noteEdited");
    },
  },
});

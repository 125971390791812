
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";
import Btn from "@/components/v2/base/Btn.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import {
  LangCode,
  OnboardingStatus,
  OnboardingSurveyResult,
  PrestonUser,
  UserMetadataAction,
} from "@prestonly/preston-common";

export default Vue.extend({
  name: "UserOnboarding",
  metaInfo() {
    return {
      title: this.$t("metadata.userOnboarding.title").toString(),
    };
  },
  components: {
    Header,
    Btn,
    TileWrapper,
    TextareaField,
  },
  data() {
    return {
      questionNo: 0,
      currentAnswers: [] as string[],
      currentFreeTextAnswer: "",
      surveyResult: {},
      surveyStatus: OnboardingStatus.PENDING,
      survey: [
        {
          question: this.$t("userOnboarding.survey.question0.question"),
          answers: [
            {
              text: this.$t("userOnboarding.survey.question0.answer0"),
              value: "ilike",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer1"),
              value: "exam",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer2"),
              value: "work",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer3"),
              value: "talkToFriends",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer4"),
              value: "tourism",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer5"),
              value: "filmsAndBooks",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question0.answer6"),
              value: "other",
              type: "checkboxWithFreeText",
            },
          ],
        },
        {
          question: this.$t("userOnboarding.survey.question1.question"),
          answers: [
            {
              text: this.$t("userOnboarding.survey.question1.answer0"),
              value: "grammar",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question1.answer1"),
              value: "vocabulary",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question1.answer2"),
              value: "situation",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question1.answer3"),
              value: "business",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question1.answer4"),
              value: "all",
              type: "checkbox",
            },
          ],
        },
        {
          question: this.$t("userOnboarding.survey.question2.question"),
          answers: [
            {
              text: this.$t("userOnboarding.survey.question2.answer0"),
              value: "correctPronunciation",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question2.answer1"),
              value: "auralComprehension",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question2.answer2"),
              value: "writing",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question2.answer3"),
              value: "reading",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question2.answer4"),
              value: "speaking",
              type: "checkbox",
            },
          ],
        },
        {
          question: this.$t("userOnboarding.survey.question3.question"),
          answers: [
            {
              text: this.$t("userOnboarding.survey.question3.answer0"),
              value: "teacher",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer1"),
              value: "junior",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer2"),
              value: "student",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer3"),
              value: "worker",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer4"),
              value: "manager",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer5"),
              value: "freelancer",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer6"),
              value: "pensionerOrLeisured",
              type: "checkbox",
            },
            {
              text: this.$t("userOnboarding.survey.question3.answer7"),
              value: "other",
              type: "checkboxWithFreeText",
            },
          ],
        },
      ],
    };
  },
  computed: {
    isUserOnboarded(): boolean {
      return [OnboardingStatus.SKIPPED, OnboardingStatus.COMPLETED].includes(
        this.user?.userMetadata?.onboardingStatus || OnboardingStatus.PENDING
      );
    },
    user(): PrestonUser | null {
      return this.$store.getters["user/getUser"];
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
  },
  methods: {
    async saveOnboardingStatus() {
      await this.$store.dispatch("user/patchUser", {
        action: UserMetadataAction.ONBOARDING_STATUS,
        payload: { onboardingStatus: this.surveyStatus },
      });
    },
    async patchOnboardingSurvey() {
      const payload: Partial<OnboardingSurveyResult> = {
        langCode: this.learnLanguage,
        surveyResult: this.surveyResult,
        surveyStatus: this.surveyStatus,
      };
      await this.$store.dispatch("user/patchOnboardingSurvey", payload);
    },
    async skipLevelingTest() {
      await this.completeOnboarding();
      await this.$router.push({ name: "root" });
    },
    async goToLevelingTest() {
      await this.completeOnboarding();
      await this.$router.push({ name: "levelingTest" });
    },
    async completeOnboarding() {
      this.surveyStatus = OnboardingStatus.COMPLETED;
      await this.patchOnboardingSurvey();
      await this.saveOnboardingStatus();
    },
    async skipOnboarding() {
      this.surveyStatus = OnboardingStatus.SKIPPED;
      this.questionNo = this.survey.length;
      await this.patchOnboardingSurvey();
      await this.saveOnboardingStatus();
    },
    async nextQuestion() {
      if (this.questionNo < this.survey.length) {
        this.surveyResult[this.questionNo + 1] = { answers: this.currentAnswers, freeText: this.currentFreeTextAnswer };
        this.currentAnswers = [];
        this.currentFreeTextAnswer = "";
        await this.patchOnboardingSurvey();
      }
      this.questionNo++;
    },
    onAnswerPressed(value) {
      if (!this.currentAnswers.includes(value)) {
        this.currentAnswers.push(value);
      } else {
        this.currentAnswers.splice(this.currentAnswers.indexOf(value), 1);
      }
    },
  },
});

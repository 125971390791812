import { LangCode, SubscriptionPlan } from "@prestonly/preston-common";
import Vue from "vue";
import { ActionContext } from "vuex";
import { RootState } from "..";
import { httpClient } from "@/utils";

declare interface PrestonState {
  interfaceLanguage: LangCode;
  learnLanguage: LangCode | null;
  plans: SubscriptionPlan[];
}

const state = (): PrestonState => ({
  interfaceLanguage: LangCode.PL,
  learnLanguage: null,
  plans: [],
});

const mutations = {
  setLearnLanguage(state: PrestonState, learnLanguage: LangCode): void {
    httpClient.api.defaults.params = {
      ...httpClient.api.defaults.params,
      targetLang: learnLanguage,
    };
    localStorage.setItem("prestonState:learnLanguage", learnLanguage);
    Vue.set(state, "learnLanguage", learnLanguage);
  },
  setInterfaceLanguage(state: PrestonState, interfaceLanguage: LangCode): void {
    httpClient.api.defaults.params = {
      ...httpClient.api.defaults.params,
      sourceLang: interfaceLanguage,
    };
    localStorage.setItem("prestonState:interfaceLanguage", interfaceLanguage);
    Vue.set(state, "interfaceLanguage", interfaceLanguage);
  },
  setPlans(state: PrestonState, plans: SubscriptionPlan[]): void {
    Vue.set(state, "plans", plans);
  },
};

const actions = {
  async getPlans({ state, commit }: ActionContext<PrestonState, RootState>): Promise<SubscriptionPlan[]> {
    const interfaceLang = state.interfaceLanguage || "PL";
    const learnLanguage = state.learnLanguage || "ENG";
    const { data: plans } = await httpClient.api.get("/product");
    const filteredPlans = plans.filter((plan) => {
      return plan.sourceLang === interfaceLang && plan.targetLang === learnLanguage;
    });
    commit("setPlans", filteredPlans);
    return filteredPlans;
  },
  async setDefaults({ commit, getters }: ActionContext<PrestonState, RootState>): Promise<void> {
    try {
      const getItem = (key: string) => {
        const item = localStorage.getItem(`prestonState:${key}`);
        return item === "null" ? null : item;
      };
      const learnLanguage = getItem("learnLanguage") || getters["getLearnLanguage"];
      const interfaceLanguage = getItem("interfaceLanguage") || getters["getInterfaceLanguage"];
      commit("setLearnLanguage", learnLanguage);
      commit("setInterfaceLanguage", interfaceLanguage);
    } catch (err) {
      console.error(err);
    }
  },
  async setLearnLanguage(
    { commit }: ActionContext<PrestonState, RootState>,
    learnLanguage: PrestonState["learnLanguage"]
  ): Promise<PrestonState["learnLanguage"]> {
    try {
      commit("setLearnLanguage", learnLanguage);
    } catch (err) {
      console.error(err);
    }
    return learnLanguage;
  },
  async setInterfaceLanguage(
    { commit }: ActionContext<PrestonState, RootState>,
    interfaceLanguage: PrestonState["interfaceLanguage"]
  ): Promise<PrestonState["interfaceLanguage"]> {
    try {
      commit("setInterfaceLanguage", interfaceLanguage);
    } catch (err) {
      console.error(err);
    }
    return interfaceLanguage;
  },
};

const getters = {
  getLearnLanguage: (state: PrestonState): LangCode | null => {
    return state.learnLanguage;
  },
  getInterfaceLanguage: (state: PrestonState): LangCode | null => {
    return state.interfaceLanguage;
  },
  getPlans: (state: PrestonState): SubscriptionPlan[] => {
    return state.plans;
  },
};

export const prestonStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

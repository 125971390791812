
import Vue from "vue";

import { Word } from "@prestonly/preston-common";

import TagChips from "@/components/base/TagChips.vue";
import BaseWordForm from "@/components/form/BaseWordForm.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "AdminEditWord",

  components: {
    BaseWordForm,
    TagChips,
  },

  computed: {
    wordId(): string {
      return this.$route.params.wordId;
    },
    word(): Word {
      return this.$store.getters["word/getById"](this.wordId);
    },
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminDictionary" });
    },
    async saveChanges() {
      const form = this.$refs.wordForm as any;
      await form.saveChanges();
    },
    async getWord() {
      await this.$store.dispatch("word/getSingle", { id: this.wordId, force: true, withExercises: 1 });
    },
  },
  async created() {
    await this.getWord();
    if (!this.word) {
      await this.$store.dispatch("snackbar/open", {
        config: {
          type: SnackbarType.ERROR,
          message: "Słowo nie istnieje.",
        },
      });
      await this.$router.push({ name: "adminWords" });
    }
  },
});

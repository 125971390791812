
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import AudioRecorder from "audio-recorder-polyfill";

import { audioRecorder } from "@/utils/audioRecorder";
import AudioRecordingButton from "@/components/base/AudioRecordingButton.vue";
import AudioPlayer from "@/components/base/AudioPlayer.vue";

export enum RecordingState {
  NOT_STARTED = "NOT_STARTED",
  RECORDING = "RECORDING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  LOADING = "LOADING",
}

export default Vue.extend({
  name: "AudioRecording",
  props: {
    content: {
      type: String,
    },
  },
  components: {
    AudioRecordingButton,
    AudioPlayer,
  },
  computed: {
    command(): string | TranslateResult {
      switch (this.recordingState) {
        case RecordingState.NOT_STARTED:
          return this.$t("recordingStateText.notStarted");
        case RecordingState.RECORDING:
          return this.$t("recordingStateText.recording");
        case RecordingState.PAUSED:
          return this.$t("recordingStateText.paused");
        case RecordingState.FINISHED:
          return this.$t("recordingStateText.finished");
        default:
          return "";
      }
    },
  },
  data() {
    return {
      audioRecordStartTime: {} as Date,
      audioElementSource: "" as string | ArrayBuffer | null,
      audioElementType: "" as string | null,
      isMicrophoneVisible: true,
      isRecordingButtonsContainerVisible: false,
      recording: {} as File | null,
      recordingState: RecordingState.NOT_STARTED as RecordingState,
      RecordingState,
    };
  },
  methods: {
    startAudioRecording() {
      audioRecorder
        .start()
        .then(() => {
          this.recordingState = RecordingState.RECORDING;
          this.audioRecordStartTime = new Date();
        })
        .catch((error) => {
          if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
            console.log("To record audio, use browsers like Chrome and Firefox.");
            // displayBrowserNotSupportedOverlay();  // TODO - add
          }

          switch (error.name) {
            case "AbortError": //error from navigator.mediaDevices.getUserMedia
              console.log("An AbortError has occured.");
              break;
            case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotAllowedError has occured. User might have denied permission.");
              break;
            case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotFoundError has occured.");
              break;
            case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotReadableError has occured.");
              break;
            case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
              console.log("A SecurityError has occured.");
              break;
            case "TypeError": //error from navigator.mediaDevices.getUserMedia
              console.log("A TypeError has occured.");
              break;
            case "InvalidStateError": //error from the MediaRecorder.start
              console.log("An InvalidStateError has occured.");
              break;
            case "UnknownError": //error from the MediaRecorder.start
              console.log("An UnknownError has occured.");
              break;
            default:
              console.log("An error occured with the error name " + error.name);
          }
        });
    },
    async stopAudioRecording(): Promise<void> {
      return new Promise((resolve, reject) => {
        audioRecorder
          .stop()
          .then(async (audioAsblob) => {
            this.recordingState = RecordingState.LOADING;
            await this.setAudioSrc(audioAsblob);
            this.recordingState = RecordingState.FINISHED;
            this.$emit("recordingFinished");
            resolve();
          })
          .catch((error) => {
            this.recordingState = RecordingState.FINISHED;
            switch (error.name) {
              case "InvalidStateError": //error from the MediaRecorder.stop
                console.log("An InvalidStateError has occured.");
                break;
              default:
                console.log("An error occured with the error name " + error.name);
            }
            reject();
          });
      });
    },
    cancelAudioRecording() {
      audioRecorder.cancel();
      this.recordingState = RecordingState.NOT_STARTED;
    },
    async getAudio() {
      console.log(this.recordingState);
      if (this.recordingState === RecordingState.RECORDING) {
        await this.stopAudioRecording();
      }

      return {
        source: this.audioElementSource,
        type: this.audioElementType,
      };
    },
    async setAudioSrc(recorderAudioAsBlob: Blob): Promise<void> {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (!e.target) {
            return;
          }
          const base64URL = e.target.result;
          this.audioElementSource = base64URL;
          this.audioElementType = recorderAudioAsBlob.type.includes(";")
            ? recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(";"))
            : recorderAudioAsBlob.type;
          resolve();
        };
        reader.readAsDataURL(recorderAudioAsBlob);
      });
    },
  },
  mounted() {
    window.MediaRecorder = AudioRecorder;
  },
  beforeDestroy() {
    audioRecorder.cancel();
  },
});


import Vue from "vue";
import { mapGetters } from "vuex";
import { BaseGiftCard } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";

interface TableOptions {
  itemsPerPage: number;
  page: number;
}

export default Vue.extend({
  name: "AdminGiftCards",

  data: () => ({
    options: {
      itemsPerPage: 10,
      page: 1,
    } as TableOptions,
    headers: [
      {
        text: "Kod",
        value: "pattern",
        sortable: false,
      },
      {
        text: "E-mail",
        value: "sendToEmailAddress",
        sortable: false,
      },
      {
        text: "Data utworzenia",
        value: "createdAt",
        sortable: false,
      },
      {
        text: "Data wysyłki",
        value: "sendAt",
        sortable: false,
      },
      {
        text: "Data wygaśnięcia",
        value: "expireAt",
        sortable: false,
      },
      {
        text: "Data wykorzystania",
        value: "redeemedAt",
        sortable: false,
      },
      {
        align: "end",
        sortable: false,
        value: "actions",
      },
    ],
    directory: "",
    loading: false,
  }),

  computed: {
    ...mapGetters({
      giftCards: "giftCard/getSearchResults",
      metadata: "giftCard/getListMetadata",
    }),
  },

  methods: {
    async getGiftCards(): Promise<void> {
      this.loading = true;
      const filters: string[] = [];
      if (this.directory) {
        filters.push(`directory:${this.directory}`);
      }
      const payload = {
        filters: filters.join(";"),
        limit: this.options.itemsPerPage,
        page: this.options.page,
      };
      await this.$store.dispatch("giftCard/getList", payload);
      this.loading = false;
    },
    handleOptionsChange(options: TableOptions) {
      this.options = options;
      this.getGiftCards();
    },
    async addGiftCard() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "GiftCardAddDialog",
        config: {
          title: "Wygeneruj nową kartę podarunkową",
          submitBtnText: "Wygeneruj",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        await this.$store.dispatch("giftCard/create", {
          giftCard: payload as BaseGiftCard,
        });
        await this.getGiftCards();
      }
    },
  },
});


import Vue, { PropType } from "vue";
import { Exercise, LearningExerciseType, Lesson, UserPreferences } from "@prestonly/preston-common";
import { DialogCloseType } from "@/types/dialog";

import TileWrapper from "@/components/base/TileWrapper.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import TrainerContentPreviewFooter from "@/components/trainer/TrainerContentPreviewFooter.vue";
import TrainerAlternativeAnswers from "@/components/trainer/TrainerAlternativeAnswers.vue";

export default Vue.extend({
  name: "TrainerContentPreview",
  components: {
    AudioButton,
    TileWrapper,
    TrainerContentPreviewFooter,
    TrainerAlternativeAnswers,
  },

  props: {
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.FLASH_CARD,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    lessonId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
  },
  data() {
    return {
      availableExercises: [] as Exercise[],
      dialog: false,
      lastItemReached: false,
      currentIndex: 0,
      showAnswerFlag: false,
      shiftedTimes: 0,
    };
  },

  watch: {
    currentIndex(val) {
      // 0 is a valid index
      if (val === undefined || val === null) {
        return;
      }
      localStorage.setItem(`${this.courseId}:${this.lessonId}:contentPreviewIndex`, val);
      this.getCurrentItem();
    },
    availableExercisesMap() {
      this.getCurrentItem();
    },
  },

  computed: {
    orderedExercisesMap(): Record<number, string> {
      const availableExercisesIds = Object.keys(this.availableExercisesMap);
      let order = 1;
      return this.lesson.items.reduce((map, assignedItem) => {
        if (availableExercisesIds.includes(assignedItem.itemId)) {
          map[order] = assignedItem.itemId;
          ++order;
        }
        return map;
      }, {});
    },
    availableExercisesMap(): Record<string, Exercise> {
      return this.availableExercises.reduce((map, exercise) => {
        map[exercise._id] = exercise;
        return map;
      }, {});
    },
    autoplayRecording(): boolean {
      return (this.$store.getters["user/preferences"] as UserPreferences).autoplayAnswer;
    },
  },

  methods: {
    shiftFlashCard() {
      this.shiftedTimes++;
      this.showAnswerFlag = !this.showAnswerFlag;
      if (this.showAnswerFlag) {
        if (!this.autoplayRecording) {
          return;
        }
        this.$nextTick(() => this.playRecording());
      }
    },
    getCurrentItem() {
      const exercise = this.availableExercisesMap[this.orderedExercisesMap[this.currentIndex + 1]];
      if (exercise) {
        this.$emit("setCurrentItem", exercise);
      }
    },
    showAnswer() {
      this.showAnswerFlag = true;
    },
    showExample() {
      this.showAnswerFlag = false;
    },
    playRecording(playbackRate: number) {
      if (this.showAnswerFlag) {
        (this.$refs.answerAudioButton as any).playRecording(playbackRate);
        return;
      }
      (this.$refs.exampleAudioButton as any).playRecording(playbackRate);
    },
    getBack() {
      this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex - 1;
      this.$emit("setCurrentIndex", this.currentIndex + 1);
      this.shiftedTimes = 0;
      this.showExample();
    },
    async proceed() {
      const noOfItems = this.availableExercises.length - 1;
      if (this.currentIndex === noOfItems && !this.dialog) {
        this.dialog = true;
        const {
          type,
          payload: { action },
        } = await this.$store.dispatch("dialog/open", {
          componentName: "LessonPreviewFinishedDialog",
          config: {
            title: this.$t("dialogs.lessonPreviewFinished.title"),
            payload: { content: this.$t("dialogs.lessonPreviewFinished.content") },
          },
        });
        if (type === DialogCloseType.CLOSED) {
          this.dialog = false;
          return;
        }
        if (action === "reset") {
          this.currentIndex = 0;
          this.$emit("setCurrentIndex", this.currentIndex + 1);
          this.shiftedTimes = 0;
          this.showExample();
          this.dialog = false;
          return;
        }
        if (action === "backToLesson") {
          await this.$router.push({
            name: "lessonModeSelector",
            params: { courseId: this.courseId, lessonId: this.lessonId },
          });
        }
        this.dialog = false;
        return;
      }
      this.currentIndex = this.currentIndex >= noOfItems ? noOfItems : this.currentIndex + 1;
      this.$emit("setCurrentIndex", this.currentIndex + 1);
      this.shiftedTimes = 0;
      this.showExample();
    },
  },
  mounted() {
    const previousIndex = localStorage.getItem(`${this.courseId}:${this.lessonId}:contentPreviewIndex`);
    if (previousIndex) {
      this.currentIndex = parseInt(previousIndex);
    }
  },
  async created() {
    this.currentIndex = 0;
    this.$emit("setCurrentIndex", this.currentIndex + 1);
    this.availableExercises = await this.$store.dispatch("exercise/getLessonExercises", {
      lessonId: this.lessonId,
      activeOnly: 1,
    });
  },
});


import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { LearningMode } from "@prestonly/preston-common";

export default Vue.extend({
  name: "PageTitle",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      default: "",
    },
    subtitle: {
      type: String as PropType<TranslateResult>,
      default: "",
    },
    mode: {
      type: String,
      default: "",
    },
  },
  computed: {
    modeIcon() {
      if (this.mode === LearningMode.CONTENT_PREVIEW) {
        return require("@/assets/flash-cards.svg");
      }
      if (this.mode === LearningMode.FLASH_CARDS) {
        return require("@/assets/flash-cards.svg");
      }
      if (this.mode === LearningMode.LISTENING) {
        return require("@/assets/listening.svg");
      }
      if (this.mode === LearningMode.SPEAKING) {
        return require("@/assets/talk.svg");
      }
      if (this.mode === LearningMode.WRITING) {
        return require("@/assets/writing.svg");
      }
      return "";
    },
  },
});


import Vue from "vue";
import { LangCode } from "@prestonly/preston-common";
import { getAllLanguages } from "@/utils/languages";

import SelectField from "@/components/form/inputs/SelectField.vue";

export default Vue.extend({
  name: "LangSelector",

  props: {
    value: String,
    label: {
      type: String,
      default: "Język",
    },
    showLangCode: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SelectField,
  },

  computed: {
    items(): { text: string; value: LangCode }[] {
      return getAllLanguages().map((lang) => {
        return {
          text: this.$t(this.showLangCode ? lang.shortcutTK : lang.fullNameTK).toString(),
          value: lang.langCode,
        };
      });
    },
  },
});


import Vue, { PropType } from "vue";
import { CEFRLevel, Course } from "@prestonly/preston-common";

import TagsField from "@/components/form/inputs/TagsField.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import SelectField from "@/components/form/inputs/SelectField.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";
import SwitchField from "@/components/form/inputs/SwitchField.vue";
import LangSelector from "@/components/form/inputs/LangSelector.vue";

export default Vue.extend({
  name: "BaseCourseForm",
  components: {
    SwitchField,
    TextareaField,
    SelectField,
    TagsField,
    TextField,
    LangSelector,
  },
  props: {
    course: { type: Object as PropType<Partial<Course>>, required: true },
  },
  computed: {
    CEFRLevels(): string[] {
      return Object.values(CEFRLevel);
    },
  },
  methods: {
    fieldChanged(value, fieldKey) {
      this.$emit("change", {
        ...this.course,
        [fieldKey]: value,
      });
    },
  },
});

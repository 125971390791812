
import Vue from "vue";

import BasePopupForm from "@/components/form/BasePopupForm.vue";

export default Vue.extend({
  name: "AdminAddPopup",

  components: {
    BasePopupForm,
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminOffersPopups" });
    },
    async saveChanges() {
      const form = this.$refs.popupForm as InstanceType<typeof BasePopupForm>;
      const newPopup = await form.saveChanges();
      if (newPopup) {
        await this.$router.push({ name: "adminEditPopup", params: { popupId: newPopup._id } });
      }
    },
  },
});

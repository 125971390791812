
import { SnackbarType } from "@/types/snackbar";
import { httpClient } from "@/utils";
import { validator } from "@/utils/validators";
import axios from "axios";
import Vue from "vue";
import TextField from "../form/inputs/TextField.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "UserProfileInviteFriend",

  mixins: [validator],

  data() {
    return {
      email: "",
      valid: false,
    };
  },
  components: { TextField, Btn },
  methods: {
    async send() {
      try {
        await httpClient.api.post(`/invitation/`, {
          inviteeEmail: this.email,
        });
        this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: this.$t("userProfile.actions.inviteFriend.success"),
            },
          },
          { root: true }
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.data?.name === "DuplicateInvitationError") {
          this.$store.dispatch(
            "snackbar/open",
            {
              config: {
                type: SnackbarType.WARN,
                message: this.$t("userProfile.actions.inviteFriend.invitationAlreadySent"),
              },
            },
            { root: true }
          );
        } else {
          this.$store.dispatch(
            "snackbar/open",
            {
              config: {
                type: SnackbarType.ERROR,
                message: this.$t("userProfile.actions.inviteFriend.error"),
              },
            },
            { root: true }
          );
        }
      }
      // Reset the form
      this.reset();
    },
  },
});


import Vue, { PropType } from "vue";
import { ReportedBug, LearningMode } from "@prestonly/preston-common";
import { formatDate } from "@/utils/dateFormatter";

export default Vue.extend({
  name: "AlternativeAnswersTable",

  components: {},
  props: {
    value: Array as PropType<ReportedBug[]>,
  },
  data() {
    return {
      headers: [
        { text: "Sugestia użytkownika", align: "start", value: "suggestedAnswer" },
        { text: "Tryb", align: "start", value: "mode" },
        { text: "Komentarz użytkownika", align: "start", value: "comment" },
        { text: "Data utworzenia", value: "createdAt" },
        { text: "Status", value: "status" },
        { text: "Akcje", value: "actions", sortable: false },
      ],
      modes: {
        CONTENT_PREVIEW: "Podgląd",
        FLASH_CARDS: "Fiszki",
        WRITING: "Pisanie",
        SPEAKING: "Mówienie",
        LISTENING: "Słuchanie",
      } as Record<LearningMode, string>,
    };
  },

  filters: {
    formatDate,
  },

  methods: {
    approveBug(bug: ReportedBug) {
      this.$emit("approve", bug);
    },
    deleteBug(bug: ReportedBug) {
      this.$emit("delete", bug);
    },
  },
});


import Vue, { PropType } from "vue";
import { Hint } from "@prestonly/preston-common";
import SwitchField from "./form/inputs/SwitchField.vue";

export default Vue.extend({
  name: "HintCard",
  components: {
    SwitchField,
  },
  props: {
    hint: Object as PropType<Hint>,
    deletable: {
      type: Boolean,
      default: false,
    },
    showFullscreenToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFullScreen: false,
    };
  },
  mounted() {
    this.isFullScreen = this.hint.isFullScreen;
  },
  methods: {
    async toggleFullscreen() {
      await this.$store.dispatch("hint/update", {
        hint: {
          isFullScreen: !this.isFullScreen,
        },
        hintId: this.hint._id,
      });
      this.isFullScreen = !this.isFullScreen;
    },
  },
});


import Vue from "vue";
import moment from "moment";

import { getAvailableLanguages } from "@/utils/languages";
import { LangCode, LangAccess, PrestonUser, OnboardingStatus } from "@prestonly/preston-common";
import SelectLanguageButton from "@/components/base/SelectLanguageButton.vue";
import Header from "@/components/v2/base/Header.vue";
import TileWrapper from "@/components/base/TileWrapper.vue";

export default Vue.extend({
  name: "SelectLanguage",
  metaInfo() {
    return {
      title: this.$t("metadata.selectLanguage.title").toString(),
    };
  },
  components: { Header, SelectLanguageButton, TileWrapper },
  data: function () {
    return {
      LangCode,
    };
  },
  computed: {
    isUserOnboarded(): boolean {
      return [OnboardingStatus.SKIPPED, OnboardingStatus.COMPLETED].includes(
        this.user?.userMetadata?.onboardingStatus || OnboardingStatus.PENDING
      );
    },
    user(): PrestonUser | null {
      return this.$store.getters["user/getUser"];
    },
    availableLanguages() {
      return getAvailableLanguages();
    },
    languagesWithAccess(): LangAccess[] {
      return this.user?.availableLanguages || [];
    },
    languagesWithAccessMap(): Record<LangCode, { text: string; count: number }> {
      return this.languagesWithAccess.reduce((acc, lang) => {
        acc[lang.targetLang] = this.getSubscriptionDetails(lang);
        return acc;
      }, {} as Record<LangCode, { text: string; count: number }>);
    },
  },
  methods: {
    async setLang(langCode: LangCode) {
      await this.$store.dispatch("prestonState/setLearnLanguage", langCode);
      if (!this.isUserOnboarded) {
        return this.$router.push({ name: "userOnboarding" });
      }
      await this.$router.push({ name: "root" });
    },
    getSubscriptionDetails(access: LangAccess): { text: string; count: number } {
      const today = moment().startOf("day");
      const accessEndDay = moment(access.expiresAt).startOf("day");

      const daysLeft = accessEndDay.diff(today, "days");
      if (daysLeft > 30) {
        return { text: "selectLanguage.monthsLeft", count: accessEndDay.diff(today, "months") };
      }
      if (daysLeft > 0) {
        return { text: "selectLanguage.daysLeft", count: daysLeft };
      }
      return { text: "selectLanguage.lastDay", count: daysLeft };
    },
  },
});


import Vue from "vue";
import draggable from "vuedraggable";

import { CEFRLevel, Exercise, Lesson, getBookTag, LessonItem, LessonItemType } from "@prestonly/preston-common";

import BaseLessonForm from "@/components/form/BaseLessonForm.vue";
import TagChips from "@/components/base/TagChips.vue";
import { DialogCloseType } from "@/types/dialog";

export default Vue.extend({
  name: "AdminEditLesson",

  components: {
    BaseLessonForm,
    TagChips,
    draggable,
  },

  data: function () {
    return {
      isDragging: false,
      copy: {
        name: "",
        level: "",
        description: "",
        active: "",
        tags: [],
        sourceLang: "",
        targetLang: "",
      },
    };
  },

  computed: {
    CEFRLevels(): string[] {
      return Object.values(CEFRLevel);
    },
    lessonId(): string {
      return this.$route.params.lessonId;
    },
    lesson(): Lesson {
      return this.$store.getters["lesson/getById"](this.lessonId);
    },
    lessonExercisesMap(): Record<string, Exercise> {
      const exercises = this.$store.getters["exercise/getLessonExercises"](this.lesson);
      return exercises.reduce((acc, exercise) => {
        acc[exercise._id] = exercise;
        return acc;
      }, {});
    },
  },

  methods: {
    getBookTag,
    async dragEnd() {
      this.isDragging = false;
      const reorderedItems: LessonItem[] = [];
      this.lesson.items.forEach(({ itemId, type }, index) => {
        reorderedItems.push({
          order: index + 1,
          itemId,
          type,
        });
      });
      await this.saveChanges({ items: reorderedItems });
    },
    async addExercisesToLesson() {
      const { type, payload } = await this.$store.dispatch("dialog/open", {
        componentName: "AttachExistingExerciseDialog",
        config: {
          title: "Dodaj ćwiczenia do lekcji",
          maxWidth: "1600px",
          minContentHeight: "500px",
        },
      });
      if (type === DialogCloseType.SUBMITTED) {
        const exercises = this.lesson.items;
        const existingExerciseIds = exercises.map((l) => l.itemId);
        let maxAssignedOrder =
          exercises.length > 0
            ? Math.max.apply(
                null,
                exercises.map((l) => l.order)
              )
            : 0;
        const newAssignedExercises = payload.exercises
          .filter((newExercise) => !existingExerciseIds.includes(newExercise._id))
          .map((newExercise) => {
            maxAssignedOrder++;
            return {
              itemId: newExercise._id,
              order: maxAssignedOrder,
              type: LessonItemType.EXERCISE,
            };
          });
        const mergedLessons = ([] as LessonItem[]).concat(exercises, newAssignedExercises);
        await this.saveChanges({ items: mergedLessons });
        await this.$store.dispatch("exercise/getLessonExercises", { lessonId: this.lessonId, activeOnly: 0 });
      }
    },
    async removeExerciseFromLesson(lessonId: string) {
      const { type } = await this.$store.dispatch("dialog/open", {
        componentName: "DeleteConfirmationDialog",
        config: {
          title: "Usuń ćwiczenie z lekcji",
        },
      });
      if (type === DialogCloseType.CLOSED) {
        return;
      }
      const filteredItems = this.lesson.items.filter(({ itemId }) => itemId !== lessonId);
      await this.saveChanges({ items: filteredItems });
    },
    // async moveLessonOrder(type: "up" | "down", lessonId: string) {
    //   await this.$store.dispatch("course/changeLessonOrder", {
    //     type,
    //     lessonId,
    //     courseId: this.courseId,
    //   });
    // },
    async getLesson() {
      await Promise.all([
        this.$store.dispatch("lesson/getSingle", { id: this.lessonId, force: true }),
        this.$store.dispatch("exercise/getLessonExercises", { lessonId: this.lessonId, activeOnly: 0 }),
      ]);
    },
    async saveChanges(lesson?: Partial<Lesson>) {
      if (!lesson) {
        lesson = this.copy as unknown as Partial<Lesson>;
      }
      await this.$store.dispatch("lesson/update", {
        lesson,
        lessonId: this.lessonId,
      });
      this.syncCopy();
    },
    syncCopy() {
      for (const [key, value] of Object.entries(this.lesson)) {
        if (key in this.copy) {
          this.copy[key] = value;
        }
      }
    },
  },
  async created() {
    await this.getLesson();
    if (!this.lesson) {
      return;
    }
    this.syncCopy();
  },
});

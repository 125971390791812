
import Vue from "vue";
import { getAvailableLanguagesMap } from "@/utils/languages";

export default Vue.extend({
  name: "BasketGiftCardSummaryCard",
  props: {
    product: {
      type: Object,
      required: true,
    },
    formattedTotalProductPrice: {
      type: String,
      default: "",
    },
    formattedFinalTotalPrice: {
      type: String,
      default: "",
    },
    showRemoveBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    availableLanguagesMap() {
      return getAvailableLanguagesMap();
    },
  },
});

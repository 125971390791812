var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',[_c('Header',{attrs:{"title":_vm.$t('subscriptionPage.summary'),"sub":"","headerType":"h2","margin":"mb-0"}}),_c('div',[_c('div',{staticClass:"pt-2"},[_c('SimpleAlert',{staticClass:"mb-0",attrs:{"dense":"","customClasses":"prestonly__bg--darker","elevation":"0"}},[(_vm.currentSubscriptionDaysLeft < 0 && _vm.currentSubscriptionFinishAt)?_c('span',{staticClass:"prestonly__base-text",domProps:{"innerHTML":_vm._s(
            _vm.$t('subscriptionPage.recentPlanExpiredAt', {
              lang: _vm.$t(`languagesMap.${_vm.langCode}.genitive`),
              date: _vm.currentSubscriptionFinishAt,
            })
          )}}):_vm._e(),(_vm.currentSubscriptionDaysLeft > 0)?_c('span',{staticClass:"prestonly__base-text",domProps:{"innerHTML":_vm._s(
            _vm.$t('subscriptionPage.currentPlanFinishAt', {
              lang: _vm.$t(`languagesMap.${_vm.langCode}.genitive`),
              date: _vm.currentSubscriptionFinishAt,
            })
          )}}):_vm._e(),_c('span',{staticClass:"prestonly__base-text",domProps:{"innerHTML":_vm._s(
            _vm.$t('subscriptionPage.afterPaymentPlanFinishAt', {
              date: _vm.endOfSubscriptionAfterPayment,
            })
          )}})])],1),_c('div',{staticClass:"row my-1"},[_c('div',{staticClass:"col-12 col-md-6 py-1"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('subscriptionPage.planFor', { lang: _vm.$t(`languagesMap.${_vm.langCode}.genitive`) }))}}),_c('v-avatar',{staticClass:"cursor-pointer ml-2",attrs:{"tile":"","size":"24"},on:{"click":function($event){return _vm.selectLanguage(_vm.langCode)}}},[_c('img',{attrs:{"src":require(`@/assets/${_vm.getFlagPath(_vm.langCode)}`)}})]),(_vm.changeLanguage)?_c('span',_vm._l((_vm.remainingLanguages),function(langConfig){return _c('v-avatar',{key:langConfig.langCode,staticClass:"cursor-pointer ml-2",attrs:{"tile":"","size":"24"},on:{"click":function($event){return _vm.selectLanguage(langConfig.langCode)}}},[_c('img',{attrs:{"src":require(`@/assets/${langConfig.flagIcon}`)}})])}),1):_vm._e(),(!_vm.locked)?_c('TextBtn',{attrs:{"x-small":"","underlined":""},on:{"click":function($event){_vm.changeLanguage = !_vm.changeLanguage}}},[_vm._v(" "+_vm._s(_vm.$t("change"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-6 py-1"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.$t(
              _vm.formattedTotalPrice === _vm.formattedFinalTotalPrice
                ? 'subscriptionPage.selectedPlan'
                : 'subscriptionPage.discountedSelectedPlan',
              {
                name: _vm.planName,
                totalPrice: _vm.formattedTotalPrice,
                finalTotalPrice: _vm.formattedFinalTotalPrice,
              }
            )
          )}}),(!_vm.locked)?_c('TextBtn',{attrs:{"x-small":"","underlined":""},on:{"click":function($event){return _vm.$emit('planRemoved')}}},[_vm._v(" "+_vm._s(_vm.$t("change"))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('BasketCouponForm',{attrs:{"locked":_vm.locked,"endOfSubscriptionDate":_vm.endOfSubscriptionAfterPayment,"selectedPlan":_vm.selectedPlan},on:{"plan-changed":function($event){return _vm.$emit('plan-changed', $event)}}})],1)])]),(!_vm.locked)?_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-end"},[(_vm.isTrialAvailable)?_c('Btn',{staticClass:"mb-2 mb-sm-0 mr-sm-2",attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"id":"trialButton"},on:{"click":_vm.startTrial}},[_vm._v(" "+_vm._s(_vm.$t("startTrialPeriod"))+" ")]):_vm._e(),_c('Btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"to":{ name: 'subscribeSummary' }}},[_vm._v(_vm._s(_vm.$t("moveForward")))])],1):_vm._e(),(_vm.trialTextContent && !_vm.locked)?_c('span',{staticClass:"text-caption d-flex justify-end"},[_vm._v(" "+_vm._s(_vm.$tc(_vm.trialTextContent, _vm.trialDaysLeft, { lang: _vm.$t(`languagesMap.${_vm.langCode}.instrumental`), days: _vm.trialDaysLeft, }))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
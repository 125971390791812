
import Vue from "vue";
import { isEmpty } from "lodash";

import {
  CompanyAccessCodeVerificationStatus,
  LangCode,
  OnboardingStatus,
  PrestonUser,
  UserMetadataAction,
} from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";
import { removeQueryParams } from "@/utils";

import Layout from "@/layouts/Layout.vue";

export default Vue.extend({
  name: "App",
  data() {
    return {
      appLoading: false,
    };
  },
  metaInfo() {
    return {
      title: this.$t("metadata.default.title").toString(),
      titleTemplate: "%s | Prestonly",
    };
  },
  components: { Layout },
  watch: {
    user() {
      this.redeemCompanyAccessCode();
      this.getPopups();
    },
    $route() {
      this.$store.dispatch("popup/displayPopups");
      this.navigateBasedOnLearnLanguage();
      this.$nextTick(() => {
        removeQueryParams("screenHint", this.$router);
        removeQueryParams("loginHint", this.$router);
      });
    },
  },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    isUserOnboarded(): boolean {
      return [OnboardingStatus.SKIPPED, OnboardingStatus.COMPLETED].includes(
        this.user?.userMetadata?.onboardingStatus || OnboardingStatus.PENDING
      );
    },
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    routeAuthenticationRequired(): boolean {
      return this.$route && this.$route.meta.authenticationRequired !== false;
    },
    companyAccessCode(): string {
      return localStorage.getItem("prestonState:cac") || (this.$route.query && this.$route.query["cac"]) || "";
    },
  },
  methods: {
    async getPopups(): Promise<void> {
      await this.$store.dispatch("popup/getAvailable");
      await this.$store.dispatch("popup/getPopupSeenList");
      await this.$store.dispatch("popup/displayPopups");
    },
    async navigateBasedOnLearnLanguage(): Promise<void> {
      if (!this.user || isEmpty(this.user)) {
        return;
      }

      if (!this.routeAuthenticationRequired) {
        return;
      }

      if (this.isUserOnboarded && this.$route.name === "userOnboarding") {
        await this.$router.push({ name: "courses" });
        return;
      }

      if (!this.isUserOnboarded && !["userOnboarding", "selectLanguage"].includes(this.$route.name)) {
        let loginMethod = this.user.isSocialAccount ? "google" : "email_password";
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "sign_up",
          params: {
            method: loginMethod,
          },
        });
        await this.$router.push({ name: "selectLanguage" });
        return;
      }
      if (!this.learnLanguage) {
        if (this.$route.name === "selectLanguage") {
          return;
        }
        await this.$router.push({ name: "selectLanguage" });
        return;
      }
    },
    async saveLevelingTestResult() {
      if (!localStorage.getItem("levelingTestResult")) {
        return;
      }
      const payloadString = localStorage.getItem("levelingTestResult") as string;
      try {
        const payload = JSON.parse(payloadString);
        await this.$store.dispatch("user/patchUser", {
          action: UserMetadataAction.LEVELING_TEST_RESULT,
          payload,
        });
      } catch (e) {
        console.error(e);
      } finally {
        localStorage.removeItem("levelingTestResult");
      }
    },
    overwriteUserbackPluginStyles() {
      const overwrites = document.createElement("style");
      overwrites.type = "text/css";
      overwrites.innerText = `
      #userback_button_container .userback-button {
        height: 20px;
        line-height: 19px;
        font-size: 10px;
        font-family: 'Lato', sans-serif !important;
      }

      #userback_button_container .userback-button[wstyle=text] svg {
        margin: 3px;
        width: 14px;
        height: 14px;
      }

      @media all and (min-width: 960px) {
        #userback_button_container .userback-button {
          height: 30px;
          line-height: 30px;
          font-size: 12px;
        }

        #userback_button_container .userback-button[wstyle=text] svg {
          margin: 5px;
          width: 20px;
          height: 20px;
        }
      }
    `;
      setTimeout(() => {
        document.head.appendChild(overwrites);
      }, 1500);
    },
    clearCompanyAccessCode() {
      localStorage.removeItem("prestonState:cac");
      this.$nextTick(() => removeQueryParams("cac", this.$router));
    },
    async redeemCompanyAccessCode() {
      if (this.companyAccessCode && !this.user.isCompanyAccount) {
        try {
          let firstname, lastname;
          if (!this.user.familyName) {
            const { payload } = await this.$store.dispatch("dialog/open", {
              componentName: "AddFirstAndLastNameToCompanyUser",
              config: {
                persistent: true,
                title: this.$t("dialogs.addFirstAndLastNameToCompanyUser.title"),
                submitBtnText: this.$t("dialogs.addFirstAndLastNameToCompanyUser.submitBtnText"),
              },
            });
            firstname = payload.firstname;
            lastname = payload.lastname;
          }

          this.appLoading = true;
          const result = await this.$store.dispatch("company/redeemCompanyAccessCode", {
            companyAccessCode: this.companyAccessCode,
            firstname,
            lastname,
          });
          if (result.status === CompanyAccessCodeVerificationStatus.VERIFIED) {
            await this.$store.dispatch(
              "snackbar/open",
              {
                config: {
                  closeTimeout: 10000,
                  type: SnackbarType.SUCCESS,
                  title: this.$t("company.redeemAccessCode.title"),
                  message: this.$t("company.redeemAccessCode.valid"),
                },
              },
              { root: true }
            );
          } else {
            if (!this.$t(`company.redeemAccessCode.${result.message}`)) {
              return;
            }
            await this.$store.dispatch(
              "snackbar/open",
              {
                config: {
                  closeTimeout: 10000,
                  type: SnackbarType.ERROR,
                  title: this.$t("company.redeemAccessCode.title"),
                  message: this.$t(`company.redeemAccessCode.${result.message}`),
                },
              },
              { root: true }
            );
          }
        } finally {
          this.appLoading = false;
        }
      }
      this.clearCompanyAccessCode();
    },
  },
  async created() {
    await this.saveLevelingTestResult();
    await this.$store.dispatch("prestonState/setDefaults");
  },
  mounted() {
    this.overwriteUserbackPluginStyles();
  },
});


import Vue, { PropType } from "vue";
import HintCard from "@/components/HintCard.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "HintsAlert",
  components: {
    HintCard,
    Btn,
  },

  props: {
    hints: {
      type: Array as PropType<string[]>,
    },
    lessonId: {
      type: String,
      required: true,
    },
    isFullscreenHintVisible: {
      type: Boolean,
      default: false,
    },
    showHints: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getHintById(id: string) {
      return this.$store.getters["hint/getHintById"](id);
    },
    displayHintsList() {
      this.$store.dispatch("dialog/open", {
        componentName: "DisplayHintsListDialog",
        config: {
          title: this.$t("dialogs.displayHintsList.title"),
          payload: {
            lessonId: this.lessonId,
          },
        },
      });
    },
  },
});


import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import MobileTrainerFooterBtn from "@/components/base/MobileTrainerFooterBtn.vue";
import TrainerFooterMoreMenu from "@/components/trainer/TrainerFooterMoreMenu.vue";

export declare interface MobileFooterBtnConfig {
  icon: string;
  text: string | TranslateResult;
  action?: () => void;
  disabled?: boolean;
  btnColor?: string;
  disabledTooltipContent?: string;
}

export default Vue.extend({
  name: "MobileFooterButtons",
  components: {
    TrainerFooterMoreMenu,
    MobileTrainerFooterBtn,
  },
  props: {
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array as PropType<MobileFooterBtnConfig[]>,
      default: () => [],
    },
  },
});

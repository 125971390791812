import { ActionContext } from "vuex";
import { RootState } from "../index";
import { httpClient } from "@/utils";
import { BaseListFilters, Word, ListMetadata, Recording, RecordingType } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";

interface WordsState {
  wordListMetadata: ListMetadata;
  wordsMap: Record<string, Word>;
  lessonWordsMap: Record<string, Word[]>;
  searchResults: Record<string, Word>;
  latestQueryParams: BaseListFilters;
  recordingsMap: Record<string, Recording>;
}

const state = (): WordsState => ({
  wordListMetadata: { total: 0 },
  wordsMap: {},
  lessonWordsMap: {},
  searchResults: {},
  recordingsMap: {},
  latestQueryParams: {},
});

const mutations = {
  delete(state: WordsState, wordId: string): void {
    delete state.wordsMap[wordId];
    delete state.searchResults[wordId];
    state.wordsMap = { ...state.wordsMap };
    state.searchResults = { ...state.searchResults };
  },
  unsetRecordingList(state: WordsState, recordingId: string) {
    delete state.recordingsMap[recordingId];
    state.recordingsMap = { ...state.recordingsMap };
  },
  setRecordingList(state: WordsState, recordings: Recording[]): void {
    for (const recording of recordings) {
      const id = recording._id as unknown as string;
      state.recordingsMap[id] = recording;
    }
    state.recordingsMap = { ...state.recordingsMap };
  },
  setList(state: WordsState, words: Word[]): void {
    for (const word of words) {
      const id = word._id as unknown as string;
      state.wordsMap[id] = word;
    }
    state.wordsMap = { ...state.wordsMap };
  },
  searchResults(state: WordsState, words: Word[]): void {
    state.searchResults = {};
    for (const word of words) {
      const id = word._id as unknown as string;
      state.searchResults[id] = word;
    }
    state.searchResults = { ...state.searchResults };
  },
  setWordsForLesson(state: WordsState, { lessonId, words }: { lessonId: string; words: Word[] }): void {
    state.lessonWordsMap[lessonId] = words;
    state.lessonWordsMap = { ...state.lessonWordsMap };
  },
  setLatestQueryParams(state: WordsState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: WordsState, metadata: ListMetadata): void {
    state.wordListMetadata = metadata;
  },
};

const actions = {
  async getList({ commit }: ActionContext<WordsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/word", {
        params,
      });
      commit("setList", data.data);
      commit("searchResults", data.data);
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },

  async update(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { word, wordId }: { word: Partial<Word>; wordId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.put(`/word/${wordId}`, word);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane ćwiczenia zostały poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async create(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { word }: { word: Partial<Word> }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/word`, word);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie utworzone.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  },

  async delete(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { wordId }: { wordId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/word/${wordId}`);
      commit("delete", wordId);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie usunięte.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd usuwania ćwiczenia.",
          },
        },
        { root: true }
      );
    }
  },

  async copy(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { wordId }: { wordId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/word/${wordId}/copy`);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Ćwiczenie zostało poprawnie skopiowane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async removeRecording(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { recordingId, wordId }: { recordingId: string; wordId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/word/${wordId}/recording/${recordingId}`);
      commit("unsetRecordingList", recordingId);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Nagranie zostało poprawnie usunięte.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async saveRecording(
    { commit, dispatch }: ActionContext<WordsState, RootState>,
    { recording, wordId, type }: { recording: Recording; wordId: string; type: RecordingType }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.post(`/word/${wordId}/recording/${type}`, recording);
      commit("setList", [].concat(data));

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Nagranie zostało poprawnie zapisane.",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async getRecording(
    { commit, state }: ActionContext<WordsState, RootState>,
    { recordingId }: { recordingId: string }
  ): Promise<void> {
    if (state.recordingsMap[recordingId]) {
      return;
    }
    try {
      const { data } = await httpClient.api.get(`/recording/${recordingId}`);
      commit("setRecordingList", [].concat(data));
    } catch (err) {
      console.error(err);
    }
  },

  async getWordsForLesson(
    { commit, state }: ActionContext<WordsState, RootState>,
    { lessonId }: { lessonId: string }
  ): Promise<void> {
    if (state.lessonWordsMap[lessonId]) {
      return;
    }
    try {
      const {
        data: { data: words },
      } = await httpClient.api.get(`/lesson/${lessonId}/words`);
      commit("setWordsForLesson", { lessonId, words });
    } catch (err) {
      console.error(err);
    }
  },

  async getSingle(
    { commit, state }: ActionContext<WordsState, RootState>,
    { id, force, withExercises }: { id: string; force: boolean; withExercises: number }
  ): Promise<void> {
    try {
      if (state.wordsMap[id] && !force) {
        return;
      }
      const { data } = await httpClient.api.get("/word", {
        params: { filters: `_id:${id}`, withExercises },
      });
      commit("setList", data.data);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  getSearchResults: (state: WordsState): Word[] => {
    return Object.values(state.searchResults);
  },
  getList: (state: WordsState): Word[] => {
    return Object.values(state.wordsMap);
  },
  getListMetadata: (state: WordsState): ListMetadata => {
    return state.wordListMetadata;
  },
  getLatestQueryParams: (state: WordsState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getMap: (state: WordsState): Record<string, Word> => {
    return state.wordsMap;
  },
  getById: (state: WordsState): ((wordId: string) => Word) => {
    return (wordId: string) => {
      return state.wordsMap[wordId];
    };
  },
  getWordsForLesson: (state: WordsState): ((lessonId: string) => Word[]) => {
    return (lessonId: string) => {
      return state.lessonWordsMap[lessonId];
    };
  },
};

export const wordsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


import Vue from "vue";
import {
  BasketProduct,
  SubscriptionPlan,
  BuyerExtended,
  InvoiceBaseData,
  Basket,
  VerifiedCoupon,
  formatPrice,
  getTotalPrice,
  getFinalTotalPrice,
  getMonthsFromProductCode,
} from "@prestonly/preston-common";
import Header from "@/components/v2/base/Header.vue";
import BasketSummary from "@/components/v2/subscription/BasketSummary.vue";
import BasketForm from "@/components/v2/subscription/BasketForm.vue";
import { SnackbarType } from "@/types/snackbar";

export default Vue.extend({
  name: "SubscribeSummary",
  metaInfo() {
    return {
      title: this.$t("metadata.subscribeSummary.title").toString(),
    };
  },
  components: {
    Header,
    BasketSummary,
    BasketForm,
  },

  data() {
    return {
      selectedPlan: {} as SubscriptionPlan,
      payBtnDisabled: false,
    };
  },
  computed: {
    products(): BasketProduct[] {
      return this.$store.getters["basket/getProducts"];
    },
    verifiedCoupon(): VerifiedCoupon | null {
      return this.$store.getters["basket/getVerifiedCoupon"] || null;
    },
    isCouponAllowedForSelectedPlan(): boolean {
      if (this.verifiedCoupon?.discountCondition?.applyTo) {
        const applyToPlans = this.verifiedCoupon.discountCondition.applyTo.map(({ id }) => id);
        return applyToPlans.includes(this.selectedPlan.productCode);
      }
      return true;
    },
  },
  methods: {
    formatPrice,
    getTotalPrice,
    getFinalTotalPrice,
    getMonthsFromProductCode,
    async onFormSubmission({ buyer, invoice }: { buyer: BuyerExtended; invoice: InvoiceBaseData }) {
      if (this.payBtnDisabled) {
        return;
      }
      this.payBtnDisabled = true;
      const basket: Basket = {
        products: this.products,
        coupon: this.isCouponAllowedForSelectedPlan ? this.verifiedCoupon : null,
        buyer,
        invoice,
      };
      const orderCreateResponse = await this.$store.dispatch("basket/submitOrder", { basket });
      if (orderCreateResponse.status.statusCode === "SUCCESS") {
        window.location.href = orderCreateResponse.redirectUri;
        return;
      }
      this.payBtnDisabled = false;
      await this.$store.dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: this.$t("basketForm.genericOrderCreateError"),
          },
        },
        { root: true }
      );
    },
  },
  async created() {
    const plans = (await this.$store.dispatch("prestonState/getPlans")) as SubscriptionPlan[];
    const plan = this.products.find((product) => product.productCode.includes("MONTHLY_SUBSCRIPTION"));
    if (!plan) {
      await this.$router.push({ name: "subscribe" });
      return;
    }
    this.selectedPlan =
      plans.find((planDefinition) => planDefinition.productCode === plan.productCode) || ({} as SubscriptionPlan);
  },
});


import Vue, { PropType } from "vue";
import { Word } from "@prestonly/preston-common";

export default Vue.extend({
  name: "WordsAlert",

  data() {
    return {
      show: false,
    };
  },
  props: {
    words: {
      type: Array as PropType<Word[]>,
    },
  },
  watch: {
    words() {
      this.show = false;
    },
  },
});

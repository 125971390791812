
import Vue, { PropType } from "vue";

import TileWrapper from "@/components/base/TileWrapper.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import TrainerSelfevaluationFooter from "@/components/trainer/TrainerSelfevaluationFooter.vue";
import TrainerAlternativeAnswers from "@/components/trainer/TrainerAlternativeAnswers.vue";
import { Exercise, LearningExerciseType, Lesson, UserPreferences } from "@prestonly/preston-common";
import { AttemptEventPayload } from "@/types/progress";

export default Vue.extend({
  name: "TrainerRepetitionFlashCards",
  components: {
    AudioButton,
    TileWrapper,
    TrainerSelfevaluationFooter,
    TrainerAlternativeAnswers,
  },

  props: {
    exerciseType: {
      type: String as PropType<LearningExerciseType>,
      default: LearningExerciseType.FLASH_CARD,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    lessonId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAnswerFlag: false,
      shiftedTimes: 0,
    };
  },

  computed: {
    autoplayRecording(): boolean {
      return (this.$store.getters["user/preferences"] as UserPreferences).autoplayAnswer;
    },
  },

  watch: {
    async currentItem() {
      this.shiftedTimes = 0;
      this.showExample();
    },
  },

  methods: {
    shiftFlashCard() {
      if (this.shiftedTimes === 0) {
        this.$emit("checkAttempt", {
          check: false,
          userInput: "",
          preferredAnswer: "",
          alternativeAnswers: [],
          lang: this.currentItem.answerLang,
        } as AttemptEventPayload);
      }
      this.shiftedTimes++;
      this.showAnswerFlag = !this.showAnswerFlag;
      if (this.showAnswerFlag) {
        if (!this.autoplayRecording) {
          return;
        }
        this.$nextTick(() => this.playRecording());
      }
    },
    showAnswer() {
      this.showAnswerFlag = true;
    },
    showExample() {
      this.showAnswerFlag = false;
    },
    playRecording(playbackRate?: number) {
      if (this.showAnswerFlag) {
        (this.$refs.answerAudioButton as InstanceType<typeof AudioButton>)?.playRecording(playbackRate);
        return;
      }
      (this.$refs.exampleAudioButton as InstanceType<typeof AudioButton>)?.playRecording(playbackRate);
    },
    iDontKnow() {
      this.$emit("proceed", { forceSuccess: true });
    },
    iKnow() {
      this.$emit("proceed");
    },
  },
});


import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import Btn from "@/components/v2/base/Btn.vue";
import { OrderStatus, PaymentStatus } from "@prestonly/preston-common";
import { httpClient, sleep } from "@/utils";
import { ORDER_STATUS_CHECK_INTERVAL, ORDER_STATUS_CHECK_LIMIT } from "@/constants/thankyouPage";
import Card from "@/components/v2/base/Card.vue";

export default Vue.extend({
  name: "SubscribeGiftCardThankYou",
  metaInfo() {
    return {
      title: this.$t("metadata.subscribeThankYou.title").toString(),
    };
  },
  components: {
    Card,
    Header,
    Btn,
  },

  data() {
    return {
      paymentStatus: PaymentStatus.PENDING,
      attemptsMade: 0,
    };
  },

  computed: {
    extOrderId(): string {
      return (this.$route.query.extOrderId as string) || "";
    },
    showPaymentStatus(): boolean {
      return this.paymentStatus !== PaymentStatus.COMPLETED;
    },
    statusUnknown(): boolean {
      return this.attemptsMade > ORDER_STATUS_CHECK_LIMIT && this.paymentStatus === PaymentStatus.PENDING;
    },
    showSpinner(): boolean {
      return this.paymentStatus === PaymentStatus.PENDING && !this.statusUnknown;
    },
    showNextSteps(): boolean {
      return [PaymentStatus.CANCELED, PaymentStatus.COMPLETED].includes(this.paymentStatus);
    },
  },

  methods: {
    async fetchOrderStatusRecursively() {
      if (this.paymentStatus !== PaymentStatus.PENDING || this.statusUnknown) {
        return;
      }
      const orderStatus: OrderStatus = await this.$store.dispatch("basket/getOrderStatus", {
        extOrderId: this.extOrderId,
      });
      if (orderStatus.status !== PaymentStatus.PENDING) {
        this.paymentStatus = orderStatus.status;

        if (orderStatus.status !== PaymentStatus.COMPLETED) {
          return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: orderStatus.transactionId,
            value: Intl.NumberFormat("en-EN", { minimumFractionDigits: 2 }).format(orderStatus.total / 100),
            tax: 0,
            shipping: 0,
            currency: orderStatus.currency,
            coupon: orderStatus.coupon,
            items: orderStatus.items.map((item, index) => {
              return {
                item_id: item.itemId,
                index,
                price: Intl.NumberFormat("en-EN", { minimumFractionDigits: 2 }).format(item.price / 100),
                quantity: 1,
              };
            }),
          },
        });
        return;
      }

      this.attemptsMade++;
      await sleep(ORDER_STATUS_CHECK_INTERVAL);
      await this.fetchOrderStatusRecursively();
    },
  },

  async mounted() {
    if (!this.extOrderId) {
      this.paymentStatus = PaymentStatus.COMPLETED;
      return;
    }
    this.fetchOrderStatusRecursively().then(async () => {
      if ([PaymentStatus.CANCELED, PaymentStatus.COMPLETED].includes(this.paymentStatus)) {
        const token = await this.$auth.getTokenSilently({ ignoreCache: true });
        httpClient.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        await this.$store.dispatch("user/getUser");
      }
    });
  },
});


import Vue from "vue";

import BaseExerciseForm from "@/components/form/BaseExerciseForm.vue";

export default Vue.extend({
  name: "AdminAddExercise",

  components: {
    BaseExerciseForm,
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminExercises" });
    },
    async saveChanges() {
      const form = this.$refs.exerciseForm as any;
      const newExercise = await form.saveChanges();
      if (newExercise) {
        await this.$router.push({ name: "adminEditExercise", params: { exerciseId: newExercise._id } });
      }
    },
  },
});


import Vue from "vue";
import { PrestonUser } from "@prestonly/preston-common";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "LevelingTestSummary",

  components: {
    Btn,
  },

  props: {
    level: {
      required: true,
      type: String,
    },

    description: {
      required: true,
      type: String,
    },
  },

  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
  },
});

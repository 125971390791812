import { httpClient } from "@/utils/httpClient";
import { SnackbarType } from "@/types/snackbar";
import Vue from "vue";

export const startTrialMixin = Vue.extend({
  methods: {
    async startTrial() {
      try {
        this.$auth.loading = true;
        await this.$store.dispatch("user/startTrial");
        const token = await this.$auth.getTokenSilently({ ignoreCache: true });
        httpClient.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.SUCCESS,
              message: "Okres próbny rozpoczęty. Powodzenia w nauce!",
            },
          },
          { root: true }
        );
        if (this.$route.name !== "courses") {
          this.$router.push({ name: "courses" });
        }
      } catch (e) {
        await this.$store.dispatch(
          "snackbar/open",
          {
            config: {
              type: SnackbarType.ERROR,
              message: "Nie udało się rozpocząć okresu próbnego. Spróbuj jeszcze raz lub skontaktuj się z nami.",
            },
          },
          { root: true }
        );
      } finally {
        this.$auth.loading = false;
      }
    },
  },
});

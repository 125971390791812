import { LangCode } from "@prestonly/preston-common";

export declare interface LangConfig {
  fullNameTK: string;
  shortcutTK: string;
  langCode: LangCode;
  flagIcon: string;
  hasAccess?: boolean;
}

const AVAILABLE_LANGUAGES = [LangCode.ENG, LangCode.DE, LangCode.ES, LangCode.FR, LangCode.IT];

export const getAllLanguages = (): LangConfig[] => {
  return allLanguages;
};

export const getAvailableLanguages = (): LangConfig[] => {
  return allLanguages.filter((lang) => AVAILABLE_LANGUAGES.includes(lang.langCode));
};

export const getAvailableLanguagesMap = (): Record<string | LangCode, LangConfig> => {
  return getAvailableLanguages().reduce((map, lang) => {
    map[lang.langCode] = lang;
    return map;
  }, {});
};

export const getLanguageAttributeByLangCode = (langCode: LangCode, attr: string): string => {
  return getAvailableLanguagesMap()[langCode][attr];
};

export const allLanguages = [
  {
    shortcutTK: "languages.english.code",
    fullNameTK: "languages.english.full",
    langCode: LangCode.ENG,
    flagIcon: "united-kingdom.png",
  },
  {
    shortcutTK: "languages.german.code",
    fullNameTK: "languages.german.full",
    langCode: LangCode.DE,
    flagIcon: "germany.png",
  },
  {
    shortcutTK: "languages.spanish.code",
    fullNameTK: "languages.spanish.full",
    langCode: LangCode.ES,
    flagIcon: "spain.png",
  },
  {
    shortcutTK: "languages.french.code",
    fullNameTK: "languages.french.full",
    langCode: LangCode.FR,
    flagIcon: "france.png",
  },
  {
    shortcutTK: "languages.italian.code",
    fullNameTK: "languages.italian.full",
    langCode: LangCode.IT,
    flagIcon: "italy.png",
  },
  {
    shortcutTK: "languages.polish.code",
    fullNameTK: "languages.polish.full",
    langCode: LangCode.PL,
    flagIcon: "poland.png",
  },
];


import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import UserProfileAdditionalInfoTest from "./UserProfileAdditionalInfoTest.vue";
import UserProfileAdditionalInfoSubscription from "./UserProfileAdditionalInfoSubscription.vue";

export default Vue.extend({
  name: "UserProfileAdditionalInfo",
  components: {
    Header,
    UserProfileAdditionalInfoTest,
    UserProfileAdditionalInfoSubscription,
  },
});

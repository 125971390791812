
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import AudioRecorder from "audio-recorder-polyfill";

import { audioRecorder } from "@/utils/audioRecorder";
import AudioRecordingButton from "@/components/base/AudioRecordingButton.vue";
import AudioPlayer from "@/components/base/AudioPlayer.vue";
import { Exercise, LangCode, RecognizePayload } from "@prestonly/preston-common";

export enum RecordingState {
  NOT_STARTED = "NOT_STARTED",
  RECORDING = "RECORDING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  LOADING = "LOADING",
}

export default Vue.extend({
  name: "AudioRecording",
  props: {
    content: {
      type: String,
    },
    currentItem: {
      type: Object as PropType<Exercise>,
      required: true,
    },
  },
  components: {
    AudioRecordingButton,
    AudioPlayer,
  },
  computed: {
    learnLanguage(): LangCode {
      return this.$store.getters["prestonState/getLearnLanguage"];
    },
    command(): string | TranslateResult {
      switch (this.recordingState) {
        case RecordingState.NOT_STARTED:
          return this.$t("recordingStateTextV2.notStarted");
        case RecordingState.RECORDING:
          return this.$t("recordingStateTextV2.recording");
        case RecordingState.LOADING:
          if (this.loadingTime < 3500) {
            return this.$t("recordingStateTextV2.loading");
          }
          if (this.loadingTime < 8000) {
            return this.$t("recordingStateTextV2.loading2");
          }
          if (this.loadingTime < 25000) {
            return this.$t("recordingStateTextV2.loading3");
          }
          return this.$t("recordingStateTextV2.loading4");
        case RecordingState.PAUSED:
          return this.$t("recordingStateTextV2.paused");
        case RecordingState.FINISHED:
          return this.$t("recordingStateTextV2.finished");
        default:
          return "";
      }
    },
  },
  data() {
    return {
      loadingInterval: 0,
      loadingTime: 0,
      audioRecordStartTime: {} as Date,
      audioElementSource: "" as string | ArrayBuffer | null,
      audioElementType: "" as string | null,
      isMicrophoneVisible: true,
      isRecordingButtonsContainerVisible: false,
      recording: {} as File | null,
      recordingState: RecordingState.NOT_STARTED as RecordingState,
      RecordingState,
    };
  },
  methods: {
    estimateNativeRecordingLengthSec(): number {
      // English: Approximately 150 words per minute
      // German: Approximately 160-180 words per minute
      // Spanish: Approximately 160-200 words per minute
      const averageWordsPerMinute = 160;
      const words = this.currentItem.answer.split(" ").length;
      return (words / averageWordsPerMinute) * 60;
    },
    calculateUserRecordingLengthSec(): number {
      const now = new Date();
      return (now.getTime() - this.audioRecordStartTime.getTime()) / 1000;
    },
    isUserRecordingTooShort(): boolean {
      return this.calculateUserRecordingLengthSec() < this.estimateNativeRecordingLengthSec() * 0.7;
    },
    startAudioRecording() {
      audioRecorder
        .start()
        .then(() => {
          this.audioRecordStartTime = new Date();
          this.recordingState = RecordingState.RECORDING;
        })
        .catch((error) => {
          if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
            console.log("To record audio, use browsers like Chrome and Firefox.");
            this.$emit("recordingFinished", { status: 0, text: "", reason: "browserDoesNotSupportRecording" });
            return;
            // displayBrowserNotSupportedOverlay();  // TODO - add
          }

          switch (error.name) {
            case "AbortError": //error from navigator.mediaDevices.getUserMedia
              console.log("An AbortError has occured.");
              break;
            case "NotAllowedError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotAllowedError has occured. User might have denied permission.");
              break;
            case "NotFoundError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotFoundError has occured.");
              break;
            case "NotReadableError": //error from navigator.mediaDevices.getUserMedia
              console.log("A NotReadableError has occured.");
              break;
            case "SecurityError": //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
              console.log("A SecurityError has occured.");
              break;
            case "TypeError": //error from navigator.mediaDevices.getUserMedia
              console.log("A TypeError has occured.");
              break;
            case "InvalidStateError": //error from the MediaRecorder.start
              console.log("An InvalidStateError has occured.");
              break;
            case "UnknownError": //error from the MediaRecorder.start
              console.log("An UnknownError has occured.");
              break;
            default:
              console.log("An error occured with the error name " + error.name);
          }
          this.$emit("recordingFinished", { status: 0, text: "", reason: "anotherBrowserOrSystemRelatedError" });
        });
    },
    async stopAudioRecording(): Promise<void> {
      return new Promise((resolve, reject) => {
        audioRecorder
          .stop()
          .then(async (audioAsblob) => {
            if (this.calculateUserRecordingLengthSec() < 1) {
              this.cancelAudioRecording();
              this.$emit("recordingFinished", { status: 0, text: "", reason: "recordingNotStarted" });
              resolve();
              return;
            }

            if (this.isUserRecordingTooShort()) {
              this.cancelAudioRecording();
              this.$emit("recordingFinished", { status: 0, text: "", reason: "recordingTooShort" });
              resolve();
              return;
            }

            this.recordingState = RecordingState.LOADING;
            this.loadingInterval = setInterval(() => {
              this.loadingTime += 500;
              if (this.loadingTime > 35000) {
                this.stopAudioRecording();
              }
            }, 500);
            const result = await this.$store.dispatch("speechToText/recognizeV2", {
              source: audioAsblob,
              type: audioAsblob.type,
              langCode: this.learnLanguage,
              phraseSetsPhrases: {},
              prompt: this.currentItem.answer,
            } as RecognizePayload & { source: any; prompt: string });
            await this.setAudioSrc(audioAsblob);
            this.recordingState = RecordingState.FINISHED;
            this.$emit("recordingFinished", { status: 1, text: result.text });
            resolve();
          })
          .catch((error) => {
            this.recordingState = RecordingState.FINISHED;
            switch (error.name) {
              case "InvalidStateError": //error from the MediaRecorder.stop
                console.log("An InvalidStateError has occured.");
                break;
              default:
                console.log("An error occured with the error name " + error.name);
            }
            reject();
          })
          .finally(() => {
            clearInterval(this.loadingInterval);
            this.loadingTime = 0;
          });
      });
    },
    cancelAudioRecording(emit = false) {
      audioRecorder.cancel();
      this.recordingState = RecordingState.NOT_STARTED;
      if (emit) {
        this.$emit("cancelAudioRecording");
      }
    },
    async getAudio() {
      if (this.recordingState === RecordingState.RECORDING) {
        await this.stopAudioRecording();
      }

      return {
        source: this.audioElementSource,
        type: this.audioElementType,
      };
    },
    async setAudioSrc(recorderAudioAsBlob: Blob): Promise<void> {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          if (!e.target) {
            return;
          }
          const base64URL = e.target.result;
          this.audioElementSource = base64URL;
          this.audioElementType = recorderAudioAsBlob.type.includes(";")
            ? recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(";"))
            : recorderAudioAsBlob.type;
          resolve();
        };
        reader.readAsDataURL(recorderAudioAsBlob);
      });
    },
    stopRecordingListener() {
      if (this.recordingState !== RecordingState.RECORDING) {
        return;
      }
      this.stopAudioRecording();
    },
  },
  mounted() {
    window.MediaRecorder = AudioRecorder;
    document.addEventListener("mouseup", this.stopRecordingListener);
  },
  beforeDestroy() {
    audioRecorder.cancel();
    document.removeEventListener("mouseup", this.stopRecordingListener);
  },
});


import Vue from "vue";

import BaseHintForm from "@/components/form/BaseHintForm.vue";

export default Vue.extend({
  name: "AdminAddHint",

  components: {
    BaseHintForm,
  },

  methods: {
    backToList() {
      this.$router.push({ name: "adminHints" });
    },
    async saveChanges() {
      const form = this.$refs.hintForm as any;
      const newHint = await form.saveChanges();
      if (newHint) {
        await this.$router.push({ name: "adminEditHint", params: { hintId: newHint._id } });
      }
    },
  },
});

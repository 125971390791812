
import Vue from "vue";
import Header from "@/components/v2/base/Header.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import { PrestonUser, UserMetadataAction } from "@prestonly/preston-common";

export default Vue.extend({
  name: "UserProfileBaseInfoForm",
  components: { Header, TextField },
  data: () => {
    return {
      isAvatarSelectorVisible: false,
      isAvatarLoading: false,
      firstname: "",
      lastname: "",
      email: "",
      avatarFileName: "avatar0.svg",
      saveBtnLoading: false,
      passwordResetSent: false,
    };
  },
  created() {
    this.firstname = this.user.givenName || "";
    this.lastname = this.user.familyName || "";
    this.email = this.user.email;
    this.avatarFileName = this.user.userMetadata.avatarFileName || "avatar0.svg";
  },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
    avatars(): { fileName: string; imgSrc: string }[] {
      const context = require.context("@/assets/avatars/", false, /\.svg$/);
      return context
        .keys()
        .sort((a: string, b: string) => {
          return a.localeCompare(b, "en", { numeric: true });
        })
        .map((key: string) => ({
          fileName: key.replace("./", ""),
          imgSrc: context(key),
        }));
    },
    avatarBtnText(): string {
      return this.$t(
        this.isAvatarSelectorVisible
          ? "userProfile.baseInfoForm.closeAvatarPicker"
          : "userProfile.baseInfoForm.changeAvatar"
      ).toString();
    },
    saveBtnDisabled(): boolean {
      return (
        this.saveBtnLoading ||
        (this.firstname === this.user.givenName &&
          this.lastname === this.user.familyName &&
          this.email === this.user.email)
      );
    },
    resetPasswordEnabled(): boolean {
      return !this.user.isSocialAccount;
    },
    passwordResetBtnText(): string {
      return this.$t(
        this.passwordResetSent
          ? "userProfile.baseInfoForm.resetPasswordSuccess"
          : "userProfile.baseInfoForm.resetPassword"
      ).toString();
    },
  },
  watch: {
    user() {
      this.saveBtnLoading = false;
    },
    "user.userMetadata.avatarFileName"() {
      this.avatarFileName = this.user.userMetadata.avatarFileName || "avatar0.svg";
      this.isAvatarLoading = false;
    },
  },
  methods: {
    toggleAvatarSelector() {
      this.isAvatarSelectorVisible = !this.isAvatarSelectorVisible;
    },
    async selectAvatar(fileName: string) {
      this.isAvatarSelectorVisible = false;
      if (fileName === this.avatarFileName) {
        return;
      }
      this.isAvatarLoading = true;
      await this.$store.dispatch("user/patchUser", {
        action: UserMetadataAction.AVATAR,
        payload: {
          avatarFileName: fileName,
        },
      });
    },
    async updateName() {
      this.saveBtnLoading = true;
      const payload: Partial<PrestonUser> = {
        givenName: this.firstname,
        familyName: this.lastname,
        email: this.email,
      };
      await this.$store.dispatch("user/patchUser", {
        action: UserMetadataAction.PROFILE_DETAILS,
        payload,
      });
    },
    async resetPassword() {
      this.passwordResetSent = true;
      await this.$store.dispatch("user/resetPassword");
    },
  },
});


import Vue from "vue";

export default Vue.extend({
  name: "AudioRecordingButton",
  props: {
    size: {
      type: Number,
      default: 40,
    },
    icon: {
      type: String,
      required: true,
    },
    flashCondition: {
      type: Boolean,
      default: false,
    },
    showCondition: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height(): string {
      return `${this.size}px`;
    },
    width(): string {
      return `${this.size}px`;
    },
    btnSize(): string {
      return `${this.size + 12}px`;
    },
    iconStyle(): Record<string, string> {
      return { height: `${this.size}px`, width: `${this.size}px` };
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { Attempt, Exercise, Hint } from "@prestonly/preston-common";
import { httpClient } from "@/utils";

const actions = {
  async check(_: ActionContext<unknown, RootState>, attempt: Attempt): Promise<Exercise | Hint | undefined> {
    try {
      const { data } = await httpClient.api.post(`/attempt/check`, attempt);
      return data;
    } catch (err) {
      console.error(err);
    }
  },
};

export const attemptStore = {
  namespaced: true,
  actions,
};

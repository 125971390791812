
import _ from "lodash";
import Vue, { PropType } from "vue";
import { Hint, LangCode, parseTags, Recording, RecordingType, Word } from "@prestonly/preston-common";

import TagsField from "@/components/form/inputs/TagsField.vue";
import SwitchField from "@/components/form/inputs/SwitchField.vue";
import AudioButton from "@/components/base/AudioButton.vue";
import LangSelector from "@/components/form/inputs/LangSelector.vue";
import TextareaField from "@/components/form/inputs/TextareaField.vue";

import { validator } from "@/utils/validators";
import { stripHtml } from "@/utils/stripHtml";
import { DialogCloseType } from "@/types/dialog";

declare interface WordData {
  dto: Word;
  [key: string]: any;
}

export default Vue.extend({
  name: "BaseExerciseForm",

  components: {
    TagsField,
    SwitchField,
    TextareaField,
    AudioButton,
    LangSelector,
  },

  data(): WordData {
    return {
      valid: false,
      dialog: false,
      dto: {
        tags: [],
        needAttention: false,
        answerLang: LangCode.ENG,
        answer: "",
        answerRecording: null,
        active: true,
        exampleLang: LangCode.PL,
        example: "",
        exampleRecording: null,
      },
    };
  },
  mixins: [validator],

  props: {
    word: { type: Object as PropType<Partial<Word>>, required: false },
  },

  watch: {
    word(word: Word) {
      if (word) {
        this.dto.tags = word.tags || [];
        this.dto.needAttention = word.needAttention;
        this.dto.answerLang = word.answerLang;
        this.dto.answer = word.answer;
        this.dto.answerRecording = word.answerRecording;
        this.dto.active = "active" in word ? word.active : false;
        this.dto.exampleLang = word.exampleLang;
        this.dto.example = word.example;
        this.dto.exampleRecording = word.exampleRecording;
      }
    },
  },

  computed: {
    wordId(): string {
      return this.$route.params.wordId || "";
    },
  },

  methods: {
    getBookTag(tags: string[]) {
      const bookTags = parseTags(tags);
      return bookTags.tag;
    },
    tagsChanged(tags: string[]) {
      this.dto.tags = tags;
    },
    getItemText(item: Hint) {
      const bookContent = parseTags(item.tags);
      const content = stripHtml(item.content);
      return `
      ${bookContent.tag.toUpperCase()} -
      ${content.substring(0, 25)}...`;
    },
    fileToBase64(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    },
    async removeRecording(recordingType: string) {
      if (this.wordId) {
        const { type } = await this.$store.dispatch("dialog/open", {
          componentName: "DeleteConfirmationDialog",
          config: {
            title: "Usuń nagranie",
          },
        });
        if (type === DialogCloseType.CLOSED) {
          return;
        }

        await this.saveChanges();
        await this.$store.dispatch("word/removeRecording", {
          wordId: this.wordId,
          recordingId: this.dto[`${recordingType}Recording`],
        });
        this.dto[`${recordingType}Recording`] = null;
      }
    },
    async addRecording(addedFile: File, type: RecordingType) {
      if (this.wordId) {
        const [tagWithBookorder] =
          this.dto.tags.filter((tag: string) => {
            return tag.includes("bookorder");
          }) || [];
        const lang = this.dto[`${type}Lang`];
        const name = [addedFile.name, lang, tagWithBookorder].filter(Boolean).join("_");
        const recording: Recording = {
          lang,
          content: "",
          name,
        };
        recording.content = await this.fileToBase64(addedFile);
        await this.saveChanges();
        await this.$store.dispatch("word/saveRecording", {
          wordId: this.wordId,
          recording,
          type,
        });
      }
    },
    getCurrentState() {
      return Object.entries(this.dto).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    },
    async saveChanges() {
      await this.validate();
      if (!this.valid) {
        return;
      }
      const word = this.getCurrentState();
      if (this.wordId) {
        await this.$store.dispatch("word/update", {
          word,
          wordId: this.wordId,
        });
        return;
      }
      return this.$store.dispatch("word/create", {
        word,
      });
    },
  },
});

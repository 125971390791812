
import { validator, VueWithValidator } from "@/utils/validators";
import TextField from "@/components/form/inputs/TextField.vue";
import { Invoice } from "@prestonly/preston-common";
import { PropType } from "vue";

export default VueWithValidator.extend({
  name: "InvoiceForm",

  components: {
    TextField,
  },

  props: {
    value: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },

  mixins: [validator],

  methods: {
    onInput() {
      this.$emit("input", this.value);
    },
  },
});

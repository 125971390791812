
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "MobileTrainerFooterBtn",
  components: {
    Btn,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    action: {
      type: Function,
      default: () => {
        // do nothing
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnColor: {
      type: String,
      default: "primary",
    },
    disabledTooltipContent: {
      type: String,
      default: "",
    },
  },
});


import Vue from "vue";

import Header from "@/components/v2/base/Header.vue";
import TextField from "@/components/form/inputs/TextField.vue";
import Btn from "@/components/v2/base/Btn.vue";

import { CouponVerificationStatus, PrestonUser } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";
import { httpClient } from "@/utils";

export default Vue.extend({
  name: "UserProfileRedeemGiftCard",
  components: { Header, TextField, Btn },
  data: () => {
    return {
      giftCardCode: "",
      loading: false,
    };
  },
  computed: {
    user(): PrestonUser {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    async redeem() {
      this.loading = true;
      try {
        const verificationStatus = await this.$store.dispatch("user/redeemGiftCard", this.giftCardCode);
        if (verificationStatus.status === CouponVerificationStatus.VERIFIED) {
          await this.$store.dispatch(
            "snackbar/open",
            {
              config: {
                type: SnackbarType.SUCCESS,
                message: this.$t("userProfile.redeemGiftCard.valid"),
              },
            },
            { root: true }
          );
          this.giftCardCode = "";
          const token = await this.$auth.getTokenSilently({ ignoreCache: true });
          httpClient.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          await this.$store.dispatch("user/getUser");
          return;
        }
        if (verificationStatus.status === CouponVerificationStatus.INVALID) {
          await this.$store.dispatch(
            "snackbar/open",
            {
              config: {
                type: SnackbarType.ERROR,
                message:
                  this.$t(`userProfile.redeemGiftCard.${verificationStatus.message}`) ||
                  this.$t("somethingWentWrongPleaseTryAgain"),
              },
            },
            { root: true }
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
});


import Vue from "vue";

export default Vue.extend({
  name: "TooltipInForm",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    contentTK: {
      type: String,
      required: true,
    },
  },
});

import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { httpClient } from "@/utils";
import { BaseListFilters, ListMetadata, OnboardingStatus, Popup, PopupSeen } from "@prestonly/preston-common";
import { SnackbarType } from "@/types/snackbar";
import moment from "moment";

interface PopupsState {
  popups: Popup[];
  popupsSeen: PopupSeen[];
  popupsSeenMap: Record<string, { seenTimes: number; latest: string }>;

  popupListMetadata: ListMetadata;
  latestQueryParams: BaseListFilters;
  allPopups: Popup[];
  allPopupsMap: Record<string, Popup>;
}

const state = (): PopupsState => ({
  popups: [],
  popupsSeen: [],
  popupsSeenMap: {},
  popupListMetadata: {
    total: 0,
  },
  latestQueryParams: {},
  allPopups: [],
  allPopupsMap: {},
});

const mutations = {
  setList(state: PopupsState, { key, data }): void {
    state[key] = data;
    if (key === "allPopups") {
      state.allPopupsMap = (data as Popup[]).reduce((map, popup) => {
        map[popup._id] = popup;
        return map;
      }, {} as Record<string, Popup>);
    }
  },
  setPopupsSeenMap(state: PopupsState, popupsSeen: PopupSeen[]) {
    state.popupsSeenMap = popupsSeen.reduce((map, popupSeen) => {
      const hasEntry = map[popupSeen.popupId];
      if (!hasEntry) {
        map[popupSeen.popupId] = { seenTimes: 1, latest: moment(popupSeen.createdAt).toISOString() };
        return map;
      }
      map[popupSeen.popupId].seenTimes += 1;
      map[popupSeen.popupId].latest = moment
        .max(moment(map[popupSeen.popupId].latest), moment(popupSeen.createdAt))
        .toISOString();
      return map;
    }, {} as PopupsState["popupsSeenMap"]);
  },
  setLatestQueryParams(state: PopupsState, queryParams: BaseListFilters): void {
    state.latestQueryParams = queryParams;
  },
  setListMetadata(state: PopupsState, metadata: ListMetadata): void {
    state.popupListMetadata = metadata;
  },
  delete(state: PopupsState, popupId: string): void {
    state.allPopups = state.allPopups.filter((popup) => popup._id !== popupId);
  },
};

const actions = {
  async displayPopups({ rootGetters, dispatch, state }: ActionContext<PopupsState, RootState>): Promise<void> {
    const user = rootGetters["user/getUser"];
    const today = moment();
    for (const popup of state.popups) {
      if (state.popupsSeenMap[popup._id]) {
        const { seenTimes, latest } = state.popupsSeenMap[popup._id];
        if (!user || (user && (user.userMetadata || {}).onboardingStatus !== OnboardingStatus.COMPLETED)) {
          console.log("Lack of user or onboarding not finished.");
          continue;
        }
        if (moment(latest).isSameOrAfter(today, "day")) {
          console.log("Popup has been already seen today.");
          continue;
        }
        if (seenTimes > 0 && seenTimes >= popup.maxDisplayTimes) {
          console.log("Popup has been already seen.");
          continue;
        }
      }
      await dispatch("markPopupAsSeen", popup._id);
      await dispatch(
        "dialog/open",
        {
          componentName: "CustomPopup",
          config: {
            title: popup.title,
            payload: {
              htmlContent: popup.htmlContent,
            },
          },
        },
        { root: true }
      );
    }
  },
  async getAvailable({ commit }: ActionContext<PopupsState, RootState>): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/popup/available");
      commit("setList", { key: "popups", data });
    } catch (err) {
      console.error(err);
    }
  },
  async getPopupSeenList({ commit }: ActionContext<PopupsState, RootState>): Promise<void> {
    try {
      const { data } = await httpClient.api.get("/popup/popupsSeen");
      commit("setList", { key: "popupsSeen", data });
      commit("setPopupsSeenMap", data);
    } catch (err) {
      console.error(err);
    }
  },
  async markPopupAsSeen({ dispatch }: ActionContext<PopupsState, RootState>, popupId: string): Promise<void> {
    try {
      await httpClient.api.post(`/popup/popupsSeen/${popupId}`);
      await dispatch("getPopupSeenList");
    } catch (err) {
      console.error(err);
    }
  },

  // admin actions

  async getList({ commit }: ActionContext<PopupsState, RootState>, params: Record<string, any> = {}): Promise<void> {
    try {
      commit("setLatestQueryParams", params);
      const { data } = await httpClient.api.get("/popup/", {
        params,
      });
      commit("setList", { key: "allPopups", data: data.data });
      commit("setListMetadata", data.metadata[0]);
    } catch (err) {
      console.error(err);
    }
  },
  async update(
    { dispatch }: ActionContext<PopupsState, RootState>,
    { popup, popupId }: { popup: Partial<Popup>; popupId: string }
  ): Promise<void> {
    try {
      await httpClient.api.put(`/popup/${popupId}`, popup);

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Dane popup-a zostały zaktualizowane",
          },
        },
        { root: true }
      );
    } catch (err) {
      console.error(err);
    }
  },

  async create(
    { commit, dispatch, state }: ActionContext<PopupsState, RootState>,
    { popup }: { popup: Partial<Popup> }
  ): Promise<void> {
    try {
      const { data: newPopup } = await httpClient.api.post(`/popup/`, popup);
      commit("setList", { key: "allPopups", data: [newPopup, ...state.allPopups] });

      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Popup został poprawnie utworzony.",
          },
        },
        { root: true }
      );
      return newPopup;
    } catch (err) {
      console.error(err);
    }
  },

  async delete(
    { commit, dispatch }: ActionContext<PopupsState, RootState>,
    { popupId }: { popupId: string }
  ): Promise<void> {
    try {
      const { data } = await httpClient.api.delete(`/popup/${popupId}`);
      commit("delete", popupId);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.SUCCESS,
            message: "Popup został pomyślnie usunięty.",
          },
        },
        { root: true }
      );
      return data;
    } catch (err) {
      console.error(err);
      await dispatch(
        "snackbar/open",
        {
          config: {
            type: SnackbarType.ERROR,
            message: "Błąd usuwania popup-a.",
          },
        },
        { root: true }
      );
    }
  },

  async getSingle(
    { commit, state }: ActionContext<PopupsState, RootState>,
    { id, force }: { id: string; force: boolean }
  ): Promise<void> {
    try {
      if (state.allPopupsMap[id] && !force) {
        return;
      }
      const { data } = await httpClient.api.get("/popup/", {
        params: { filters: `_id:${id}` },
      });
      commit("setList", { key: "allPopups", data: data.data });
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  getAvailable: (state: PopupsState): Popup[] => {
    return state.popups;
  },
  getPopupSeenList: (state: PopupsState): PopupSeen[] => {
    return state.popupsSeen;
  },
  getAll: (state: PopupsState): Popup[] => {
    return state.allPopups;
  },
  getListMetadata: (state: PopupsState): ListMetadata => {
    return state.popupListMetadata;
  },
  getLatestQueryParams: (state: PopupsState): BaseListFilters => {
    return state.latestQueryParams;
  },
  getPopupById: (state: PopupsState): ((popupId: string) => Popup) => {
    return (popupId: string) => {
      return state.allPopupsMap[popupId];
    };
  },
};

export const popupsStore = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

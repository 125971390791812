
import Vue from "vue";
import TrainerFooterMoreMenu from "@/components/trainer/TrainerFooterMoreMenu.vue";
import MobileFooterButtons, { MobileFooterBtnConfig } from "@/components/base/MobileFooterButtons.vue";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "TrainerSelfevaluationFooter",
  components: {
    Btn,
    TrainerFooterMoreMenu,
    MobileFooterButtons,
  },

  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    proceedPossible: {
      type: Boolean,
      default: false,
      required: true,
    },
    moreMenuVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      clickCount: 0,
      clickTimer: null as any,
      delay: 350,
    };
  },

  computed: {
    buttons(): MobileFooterBtnConfig[] {
      return [
        {
          icon: "play_arrow",
          text: this.$t("buttons.withoutNo.play"),
          action: this.clickHandler,
          btnColor: "secondary",
        },
        {
          icon: "close",
          text: this.$t("buttons.withoutNo.iDontKnow"),
          action: this.iDontKnow,
          disabled: !this.proceedPossible,
          btnColor: "#FF3A44",
        },
        {
          icon: "check",
          text: this.$t("buttons.withoutNo.iKnow"),
          action: this.iKnow,
          disabled: !this.proceedPossible,
          btnColor: "#32A071",
        },
      ];
    },
  },

  methods: {
    clickHandler() {
      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
          this.clickTimer = 0;
          this.playRecording();
        }, this.delay);
      } else if (this.clickCount === 2) {
        clearTimeout(this.clickTimer);
        this.clickCount = 0;
        this.clickTimer = 0;
        this.playRecording(0.79);
      }
    },
    playRecording(playbackRate = 1) {
      this.$emit("playRecording", playbackRate);
    },
    iDontKnow() {
      if (!this.proceedPossible) {
        return;
      }
      this.$emit("iDontKnow");
    },
    iKnow() {
      if (!this.proceedPossible) {
        return;
      }
      this.$emit("iKnow");
    },
  },
});
